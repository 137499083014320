<template>
  <div class="w-100 d-flex flex-column" id="app">
    <router-view class="w-100 flex-grow-1"></router-view>
    <error-alert-modal-component></error-alert-modal-component>
    <success-alert-modal-component></success-alert-modal-component>
    <footer-component></footer-component>
    <VersionControl/>
  </div>
</template>

<script>
import SuccessAlertModalComponent from "./components/SuccessAlertModalComponent.vue";
import ErrorAlertModalComponent from "./components/ErrorAlertModalComponent.vue";
import FooterComponent from './components/FooterComponent.vue';
import VersionControl from "./components/VersionControlComponent.vue";

export default {
  name: "App",
  components:{
    SuccessAlertModalComponent,
    ErrorAlertModalComponent,
    FooterComponent,
    VersionControl
  },
};
</script>

<style>
[title] {
  cursor: help;
}
.table th,
.table tbody tr:first-of-type td {
  border-top: 0 !important;
}
.table td {
  vertical-align: middle !important;
}
.table th {
  font-weight: normal;
  font-size: .8rem;
  color: #6c757d;
}
.b-popover.popover{
    max-width: 100%;
}
</style>

