<template>
  <b-card header-tag="header" id="news-component" no-body>
    <template #header>
      <h6 class="mb-0">
        <fa-icon :icon="['fa-regular', 'fa-newspaper']" class="mr-2 text-primary"></fa-icon>{{ $t("news") }}
      </h6>
    </template>
    <b-card-body class="with-content" v-if="$store.state.notifications.length > 0 && $store.state.resellerData.hiddenNotifications.length !== $store.state.notifications.length">
      <div
        class="news-item"
        v-for="(item, index) in $store.state.notifications"
        :key="`item-${index}`"
        v-show="!$store.state.resellerData.hiddenNotifications.includes(item.docId)"
      >
        <button v-on:click="hideNotification(item.docId)">
          <svg xmlns="http://www.w3.org/2000/svg" width="11" height="14" viewBox="0 0 11 14" fill="none">
            <path
              d="M9.9336 3.74612C10.1906 3.48909 10.1906 3.07346 9.9336 2.81917C9.67656 2.56487 9.26094 2.56213 9.00664 2.81917L5.75274 6.07307L2.4961 2.81643C2.23906 2.5594 1.82344 2.5594 1.56914 2.81643C1.31485 3.07346 1.31211 3.48909 1.56914 3.74338L4.82305 6.99729L1.56641 10.2539C1.30938 10.511 1.30938 10.9266 1.56641 11.1809C1.82344 11.4352 2.23906 11.4379 2.49336 11.1809L5.74727 7.92698L9.00391 11.1836C9.26094 11.4406 9.67656 11.4406 9.93086 11.1836C10.1852 10.9266 10.1879 10.511 9.93086 10.2567L6.67695 7.00276L9.9336 3.74612Z"
              fill="black"
            />
          </svg>
        </button>
        <div class="font-weight-bold">{{ item.docData[$i18n.locale].title }}</div>
        <div class="text-muted" v-html="item.docData[$i18n.locale].body"></div>
      </div>
    </b-card-body>
    
    <b-card-body v-else class="d-flex flex-column justify-content-center text-center" v-html="$t('noNewsAtTheMoment')"></b-card-body>
  </b-card>
</template>

<script>
import { firestore, auth } from "../plugins/firebase";
import { doc, updateDoc, arrayUnion } from "firebase/firestore";

export default {
  name: "NewsComponent",
  methods: {
    hideNotification(notificationId) {
      const docRef = doc(firestore, "resellers", auth.currentUser.uid);
      updateDoc(docRef, {
        hiddenNotifications: arrayUnion(notificationId),
      });
    },
  },
};
</script>

<style>
#news-component .card-body {
  height: 240px;
}
#news-component .card-body.with-content {
  overflow-y: scroll;
}
.news-item {
  position: relative;
  border: 1px solid #fff;
  margin-bottom: 10px;
}
.news-item:hover {
  border-radius: 4px;
  border: 1px dashed var(--card-stroke, rgba(0, 0, 0, 0.12));
  background: #f8fcff;
}
.news-item button {
  display: none;
  position: absolute;
  right: 6px;
  top: 0;
  background: none;
  border: 0;
  padding: 0;
}
.news-item:hover button {
  display: block;
}
</style>
