<template>
  <div>
    <b-container v-if="!customer?.linkedMembership?.type">
      <p class="text-muted" >{{$t("linkedMemberShipDesciption")}}</p>
      <b-form>
        <b-form-group :label="$t('type')" class="mb-3">
          <b-form-select v-model="selected" :options="selectOptions" class="col-3"></b-form-select>
        </b-form-group>

        <b-form-group :label="$t('linkedMembershipName')" class="mb-3">
          <b-form-input v-model="name" type="text"></b-form-input>
        </b-form-group>

        <SubmitButtonComponent
          v-on:click="setLinkedMembership()"
          :state="submitButtonState"
          :label="$t('save')"
          :block="false"
          :variant="'primary'"
        />

      </b-form>
    </b-container>
    <b-container v-else>
      <b-card footer-class="border-0 bg-light">
        <b-row>
          <b-col class="d-flex flex-column justify-content-center">
            <label class="mb-0">{{$t("id")}}</label>
          </b-col>
          <b-col>
            <b>{{ customer?.linkedMembership.id }}</b>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col class="d-flex flex-column justify-content-center">
            <label class="mb-0">{{$t("linkedMembershipName")}}</label>
          </b-col>
          <b-col>
            <b>{{ customer?.linkedMembership.name }}</b>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col class="d-flex flex-column justify-content-center">
            <label class="mb-0">{{$t("type")}}</label>
          </b-col>
          <b-col>
            <b>{{ customer?.linkedMembership.type }}</b>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col class="d-flex flex-column justify-content-center">
            <label class="mb-0">{{$t("linkedMembership")}}</label>
          </b-col>
          <b-col>
            <b>{{ customer?.linkedMembership.linkedMembershipType }}</b>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col class="d-flex flex-column justify-content-center">
            <label class="mb-0">{{$t("creationDate")}}</label>
          </b-col>
          <b-col>
            <b>{{ returnDate(customer?.linkedMembership.creationDate) }}</b>
          </b-col>
        </b-row>

        <hr />

        <b-row>
          <b-col class="d-flex flex-column justify-content-center">
            <b>{{this.customers.length}} {{$t("knownCustomers")}}</b>
            <ul class="memberList">
              <li v-for="(cust, index) in this.customers" :key="`option-${index}`">
                <span @click="goToCustomer({ customerId: cust.customerId })">
                  {{ cust.companyProfile.companyName }}
                </span>
                <small class="text-muted" v-if="cust.linkedMembership.linkedMembershipType == 'OWNER'">{{ cust.linkedMembership.linkedMembershipType }} </small>
              </li>
            </ul>
          </b-col>
        </b-row>

      </b-card>
    </b-container>

  </div>
</template>

<script>
import SubmitButtonComponent from '../components/SubmitButtonComponent.vue';
import { httpsCallable } from "firebase/functions";
import { firestore, functions } from "../plugins/firebase";
import { collection, where, query, getDocs } from "firebase/firestore";

export default {
  name: "CustomerLinkedMembership",
  components: {
    SubmitButtonComponent,
  },
  props: {
    customer: Object
  },
  data() {
    return {
      selectOptions: [
          { value: "STANDARD", text: this.$t("standard"), active: true },
          { value: "CONSORTIUM", text: this.$t("consortium")}
        ],
      selected: "STANDARD",
      name: null,
      isWorking: false,
      customers: []
    };
  },
  mounted() {
    this.findCustomersWithSameLinkedMembershipId()
  },
  methods: {
    returnDate(date) {
      let dateToReturn = new Date(date)
      return dateToReturn.toLocaleDateString('de-DE')
    },
    async setLinkedMembership() {
      this.isWorking = true
      const customer = this.customer;
      const linkedMembership = {
        type: this.selected,
        name: this.name
      };
      const updateCustomerAccount = httpsCallable(functions, "updateCustomerAccount");
      await updateCustomerAccount(
        {
        customerId: customer.customerId,
        profile: {
          linkedMembership: linkedMembership,
          companyProfile: customer.companyProfile
        }
      }
      )
      .then((result) => {
        this.isWorking = false
        this.customer = result.data
        this.findCustomersWithSameLinkedMembershipId()
      })
      .catch((error) => {
        this.$store.dispatch("alertError", {
          message: error.message,
          show: true,
          noFooter: false,
        })
      });
    },
    async findCustomersWithSameLinkedMembershipId(){
      if(!this.customer?.linkedMembership?.id) return;

      const colRef = query(
        collection(firestore, "customers"),
        where("resellerId", "==", this.$store.state.resellerData.resellerId),
        where("linkedMembership.id", "==", this.customer.linkedMembership.id),
      );

      const querySnapshot = await getDocs(colRef);
      this.customers = [];

      querySnapshot.forEach((doc) => {
        this.customers.push(doc.data())
      })
      this.customers.sort((a) => {return a.linkedMembership.type === "OWNER" ? 1 : -1});
    },
    goToCustomer(params){
      this.$router.push({
        name: "CustomerSubscriptionsView",
        params: params,
      }).then(()=>{
        this.$router.go()
      });
    }
  },
  computed: {
    submitButtonState() {
      if (this.isWorking === true) {
        return "working";
      }
      return "";
    },
  },
};
</script>
<style scoped>
  .memberList {
    list-style: none;
    color: #4089c9;
  }
  .memberList span {
    font-size: 16px;
    cursor: pointer;
  }
</style>