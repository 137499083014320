<template>
  <b-container class="pt-4 d-flex flex-column align-items-center justify-content-center">
    <div class="icon rounded-circle d-flex justify-content-center align-items-center alert-danger text-danger mb-3 flex-shrink-0">
      <fa-icon :icon="['fa-regular', 'fa-times']" size="2x"></fa-icon>
    </div>
    404
    <router-link to="/" class="btn btn-primary mt-3">{{ $t("backToHomepage") }}</router-link>
  </b-container>
</template>

<script>
export default {
  name: "NotFoundView",
}
</script>

<style scoped>
.icon {
  width: 64px;
  height: 64px;
}
</style>
