<template>
  <div>
    <p class="text-muted">{{ $t("customerOrdersText") }}</p>
    <p class="mb-4 text-muted" v-html="$t('customerOrdersHint')"></p>

    <b-card no-body>
      <b-card-header class="p-3">
        <b-input-group>
          <template #prepend>
            <b-input-group-text class="bg-white pr-0"
              ><fa-icon :icon="['fa-regular', 'fa-magnifying-glass']"></fa-icon
            ></b-input-group-text>
          </template>
          <b-input
            :placeholder="$t('searchByOrderId')"
            class="border-left-0"
            v-model="filter"
          ></b-input>
        </b-input-group>
      </b-card-header>
      <b-table
        ref="orders"
        :items="getOrders"
        :fields="fields"
        :filter="filter"
        class="mb-0"
        show-empty
        :empty-filtered-text="$t('emptyFilteredText')"
        :empty-text="$t('noOrdersText')"
        sort-icon-left
      >
        <template #empty="scope">
          <div class="text-center font-italic">{{ scope.emptyText }}</div>
        </template>

        <template #emptyfiltered="scope">
          <div class="text-center font-italic">
            {{ scope.emptyFilteredText }}
          </div>
        </template>

        <template #cell(orderInfo)="data">
          <b-link v-on:click="selectOrder(data.item)">
            {{ data.item.orderId }} </b-link
          ><br />
          <small
            v-if="data.item.referenceOrderId"
            class="text-muted"
            v-b-tooltip.hover
            :title="$t('orderNumber')"
            v-html="data.item.reference"
          ></small><br />
          <small
            v-if="data.item.orderType === 'RETURN' && data.item.referenceOrderId"
            class="text-muted"
            v-b-tooltip.hover
            :title="'Reference Order-ID'"
            v-html="'Reference Order-ID: ' + data.item.referenceOrderId"
          ></small>
        </template>

        <template #cell(status)="data">
          <div :class="statusClass(data.item.status)">
            <fa-icon
              :icon="['fa-regular', statusIcon(data.item.status)]"
              class="mr-1"
            ></fa-icon
            >{{ data.item.status | orderStatus }}
          </div>
        </template>

        <template #cell(creationTime)="data">
          <fa-icon :icon="['fa-regular','fa-clock']" class="mr-1"></fa-icon>{{ data.item.creationDate | date }}
        </template>

        <template #cell(price)="data">
          <span v-if="data.item.orderType === 'RETURN'">-</span>{{ data.item.price | currency }}
        </template>

        <template #cell(type)="data">
          <span v-if="data.item.orderType === 'TRANSFER'">{{ $t("transfer") }}</span>
          <span v-if="data.item.orderType === 'RENEWAL'">{{ $t("renewal") }}</span>
          <span v-if="data.item.orderType === 'NEW'">Order</span>
          <span v-if="data.item.orderType === 'RETURN'">Return</span>
        </template>

        <template #cell(actions)="data">
          <div
            v-if="
              data.item.status === '1000' &&
              data.item.orderType !== 'TRANSFER' &&
              data.item.orderType !== 'RETURN' &&
              !isOlderThanFourteenDays(data.item.creationDate)
            "
            class="d-flex justify-content-end"
          >
            <b-button
              size="sm"
              variant="outline-danger"
              v-on:click="showCancelConfirm(data.item)"
            >
              {{ $t("cancel") }}
            </b-button>
          </div>
        </template>
      </b-table>
    </b-card>

    <b-modal
      ref="order-modal"
      :content-class="contentClass"
      :footer-class="footerClass"
      :body-class="bodyClass"
      hide-footer
      :header-class="headerClass"
      size="xl"
    >
      <template #modal-title>
        <span v-b-tooltip.hover.bottom="$t('orderId')">{{
          selectedOrder.orderId
        }}</span
        ><br />
        <small
          v-if="selectedOrder?.reference"
          class="text-muted"
          v-b-tooltip.hover
          :title="$t('orderNumber')"
          v-html="selectedOrder.reference"
        ></small>
      </template>
      <table class="table mb-0">
        <thead>
          <tr>
            <th>{{ $t("product") }}</th>
            <th>{{ $t("status") }}</th>
            <th>{{ $t("quantity") }}</th>
            <th>{{ $t("price") }}</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(lineItem, lineItemIndex) in selectedOrder.lineItems"
            :key="`item-${lineItemIndex}`"
          >
            <td class="d-flex align-items-center">
              <div class="border rounded p-1 mr-3" v-if="lineItem.imageUrl">
                <b-img-lazy
                  :src="lineItem.imageUrl"
                  :alt="lineItem.productName"
                ></b-img-lazy>
              </div>
              <div>
                {{ lineItem.productName | productNameWithoutLevel }}<br />
                <small
                  class="text-muted"
                  v-b-tooltip.hover
                  :title="$t('manufacturerNumber')"
                  >{{ lineItem.offerId }}</small
                >
              </div>
            </td>
            <td>
              <div :class="statusClass(lineItem.status)">
                <fa-icon
                  :icon="['fa-regular', statusIcon(lineItem.status)]"
                  class="mr-1"
                ></fa-icon
                >{{ lineItem.status | orderStatus }}
              </div>
            </td>
            <td>{{ lineItem.quantity }}</td>
            <td>
              <span v-if="selectedOrder.orderType === 'RETURN'">-</span>{{ lineItem.price | currency }}
            </td>
            <td class="text-right">
                <b-button
                  size="sm"
                  variant="outline-danger"
                  v-on:click="showLineItemCancelConfirm(lineItemIndex, selectedOrder)"
                  v-if="
                    lineItem.status !== '1008' &&
                    selectedOrder.orderType !== 'TRANSFER' &&
                    selectedOrder.orderType !== 'RETURN' &&
                    !isOlderThanFourteenDays(selectedOrder.creationDate)
                  "
                >
                  {{ $t("cancel") }}
                </b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </b-modal>

    <b-modal
      ref="cancel-modal"
      hide-header
      :content-class="contentClass"
      :footer-class="footerClass"
      :body-class="bodyClass"
      no-close-on-esc
      no-close-on-backdrop
      v-on:cancel="clearCancelData()"
      v-on:hidden="clearCancelData()"
    >
      <div
        v-if="orderToCancel !== null"
        v-html="
          $t('doYouWantTocancelThisOrder', { cancelOrderId: orderToCancel.orderId })
        "
      ></div>
      <template #modal-footer="{ cancel }">
        <b-button variant="outline-secondary" v-on:click="cancel()">
          {{ $t("abort") }}
        </b-button>

        <submit-button-component
          v-on:click="cancelOrder()"
          :variant="'danger'"
          :state="submitButtonState"
          :label="$t('cancel')"
          :block="false"
        ></submit-button-component>
      </template>
    </b-modal>

    <b-modal
      ref="line-item-cancel-modal"
      hide-header
      :content-class="contentClass"
      :footer-class="footerClass"
      :body-class="bodyClass"
      no-close-on-esc
      no-close-on-backdrop
      v-on:cancel="clearCancelData()"
      v-on:hidden="clearCancelData()"
    >
      <div
        v-if="orderToCancel !== null"
        v-html="
          $t('doYouWantTocancelThisLineItem', { cancelOrderId: orderToCancel.orderId, lineItemIndex: lineItemToCancel })
        "
      ></div>

      <template #modal-footer="{ cancel }">
        <b-button variant="outline-secondary" v-on:click="cancel()">
          {{ $t("abort") }}
        </b-button>

        <submit-button-component
          v-on:click="cancelOrder()"
          :variant="'danger'"
          :state="submitButtonState"
          :label="$t('cancelOrder')"
          :block="false"
        ></submit-button-component>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { orderBy } from 'lodash-es';
import moment from "moment-timezone";
import SubmitButtonComponent from "../components/SubmitButtonComponent.vue";
import { functions, firestore } from "../plugins/firebase"
import { httpsCallable } from "firebase/functions";
import { query, collection, getDocs, where } from "firebase/firestore";
import { getProductsFromServer } from "../plugins/customModules/getProducts.js";

export default {
  components: { SubmitButtonComponent },
  name: "customerOrdersView",
  props: {
    customer: {
      type: Object,
      default: () => {
        return null;
      },
    },
    orders: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      filter: "",
      isCancelling: false,
      fields: [
        {
          key: "orderInfo",
          label: this.$t("order"),
          sortable: false,
        },
        {
          key: "status",
          label: this.$t("status"),
          sortable: false,
        },
        {
          key: "creationTime",
          label: this.$t("creationTime"),
          sortable: false,
        },
        {
          key: "price",
          label: this.$t("price"),
          sortable: false,
        },
        {
          key: "type",
          label: this.$t("type"),
          sortable: false,
        },
        {
          key: "actions",
          label: "",
          sortable: false,
        },
      ],
      selectedOrder: {
        orderId: "",
        lineItems: [],
      },
      orderToCancel: null,
      lineItemToCancel: null,
      contentClass: ["border-0", "shadow-lg"],
      footerClass: ["border-0 bg-light p-4"],
      bodyClass: ["p-4"],
      headerClass: ["border-0 bg-light px-4"],
    };
  },
  computed: {
    submitButtonState() {
      if (this.isCancelling === true) {
        return "working";
      }

      if (this.cancelOrderReason === "") {
        return "missingFields";
      }

      return "";
    },
  },
  watch: {
    orders() {
      this.$refs.orders.refresh();
    },
  },
  methods: {
    async getOrders(ctx) {
      let products = await getProductsFromServer(this.$store.state.resellerData.resellerId);

      const customerOrdersColRef = query(collection(firestore, "orders"), where("customer.customerId", "==", this.$route.params.customerId), where("order.orderId", "!=", ""));
      let customerOrdersFromCollection = getDocs(customerOrdersColRef);

      const resellerPricesColRef = collection(firestore, "resellers", this.$store.state.resellerData.firestoreId, "prices");
      let resellerPrices = getDocs(resellerPricesColRef);

      const promises = [
        products,
        customerOrdersFromCollection,
        resellerPrices
      ];

      return Promise.all(promises)
        .then((responses) => {


          customerOrdersFromCollection = [];
          if (!responses[1].empty) {
            responses[1].forEach((doc) => {
              customerOrdersFromCollection.push(doc.data());
            });
          }

          resellerPrices = [];
          if (!responses[2].empty) {
            responses[2].forEach((doc) => {
              resellerPrices.push(doc.data());
            });
          }
          let mergeOrderHistory = customerOrdersFromCollection;
          this.orders.forEach(order=>{
            if ( !mergeOrderHistory.find(x=>x.order.orderId === order.orderId) ){
              mergeOrderHistory.push({
                creationDate: order.creationDate,
                customer: {},
                deluxeOrder: {},
                finished: false,
                order: order,
                partnerData: {}
              })
            }
          })
          let orderHistory = [];

          if (mergeOrderHistory.length > 0) {
            mergeOrderHistory.forEach((orderFromCollection) => {
              const order = orderFromCollection.order;
              order["price"] = 0;
              order["reference"] = "&nbsp;";

              // Fügt Lineitems die passende Produktbezeichnung und das passende Produktbild hinzu
              order.lineItems.forEach((lineItem) => {
                const matchingProduct = products.filter(
                  (product) =>
                    product.manufacturerItemNumber === lineItem.offerId
                );
        
                if (matchingProduct.length > 0) {
                  /* const matchingResellerPrice = resellerPrices.filter(
                    (price) =>
                      price.itemNumber === matchingProduct[0].itemNumber
                  ); */

                  //lineItem["price"] = matchingResellerPrice[0].price;
                  lineItem["price"] = 0;
                  lineItem["productName"] = matchingProduct[0].productName;
                  lineItem["imageUrl"] = matchingProduct[0]?.partnerData?.imageUrl || matchingProduct[0].imageUrl;

                  // order["price"] += matchingResellerPrice[0].price * lineItem.quantity;
                } else {
                  lineItem["productName"] = "";
                  lineItem["imageUrl"] = "";
                  lineItem["price"] = 0;
                }
              });

              // Fügt einer Order die Bestellnummer und den Geamtpreis und den Lineitems die Preise hinzu
              const matchingcustomerOrdersFromCollection =
                mergeOrderHistory.filter(
                  (customerOrder) =>
                    customerOrder.order.orderId === order?.orderId
                );

              if (matchingcustomerOrdersFromCollection.length > 0) {
                //order.price = 0;

                order.referenceOrderId = "";

                if (matchingcustomerOrdersFromCollection[0].order.referenceOrderId !== undefined) {
                  order.referenceOrderId = matchingcustomerOrdersFromCollection[0].order.referenceOrderId;
                }

                order.reference =
                  matchingcustomerOrdersFromCollection[0].deluxeOrder[
                    "order_reference"
                  ];

                order.lineItems.forEach((lineItem) => {
                  const matchingOrderLines = matchingcustomerOrdersFromCollection[0]?.deluxeOrder?.["order_lines"] && 
                    matchingcustomerOrdersFromCollection[0].deluxeOrder[
                      "order_lines"
                    ].filter(
                      (orderLine) =>
                        orderLine["vipmp_offer_id"] === lineItem?.offerId
                    );

                  if(matchingOrderLines?.[0]) {
                    lineItem.price =
                    matchingOrderLines[0]["unit_cost"] *
                    matchingOrderLines[0]["quantity"]
                  } else {
                    lineItem.price = 0;
                  }

                  order.price += lineItem.price;
                });
              }

              if (
                (order?.orderId && order?.orderId.toLowerCase().includes(ctx.filter.toLowerCase())) ||
                (order?.reference && order?.reference.toLowerCase().includes(ctx.filter.toLowerCase()))
              ) {
                orderHistory.push(order);
              }
            });
          }

          return orderBy(
            orderHistory,
            (item) => {
              return moment(item.creationDate).unix();
            },
            "desc"
          );
        })
        .catch((error) => {
          console.error(error);
          return [];
        });
    },
    selectOrder(data) {
      this.selectedOrder = data;

      this.$refs["order-modal"].show();
    },
    showCancelConfirm(data) {
      this.orderToCancel = data;

      this.$refs["cancel-modal"].show();
    },
    showLineItemCancelConfirm(lineItemIndex, selectedOrder) {
      this.lineItemToCancel = lineItemIndex;
      this.orderToCancel = selectedOrder;

      this.$refs["line-item-cancel-modal"].show();
    },
    clearCancelData() {
      this.orderToCancel = null;
      this.lineItemToCancel = null;
    },
    cancelOrder() {
      let lineItemsToRemove = [];

      this.orderToCancel.lineItems.forEach((lineItem) => {
        lineItemsToRemove.push(lineItem);
      });

      let completeCancel = true;

      if (this.lineItemToCancel !== null) {
        lineItemsToRemove = lineItemsToRemove.splice(this.lineItemToCancel, 1);
        completeCancel = false;
      }

      this.isCancelling = true;
      const cancelOrder = httpsCallable(functions, "cancelOrder");
      cancelOrder({
        customerNumber: this.$store.state.resellerData.customernumber,
        resellerId: this.$store.state.resellerData.resellerId,
        customerId: this.orderToCancel.customerId,
        complete: completeCancel,
        body: {
          referenceOrderId: this.orderToCancel.orderId,
          orderType: "RETURN",
          externalReferenceId: this.orderToCancel.externalReferenceId,
          currencyCode: this.orderToCancel.currencyCode,
          lineItems: lineItemsToRemove,
        },
      })
        .then(() => {
          this.isCancelling = false;
          this.$refs["cancel-modal"].hide();
          this.$refs["line-item-cancel-modal"].hide();
          this.$store.dispatch("alertSuccess", {
            message: "Der Auftrag wurde storniert.",
            show: true,
            noFooter: false,
          });
        })
        .catch((error) => {
          this.isCancelling = false;
          console.error(error);

          if (error.additionalDetails?.length > 0) {
            error.message += "<br />" + JSON.stringify(error.additionalDetails)
          }

          this.$store.dispatch("alertError", {
            message: error.message,
            show: true,
            noFooter: false,
          });
        });
    },
statusClass(status) {
      if (status === "1000") {
        return "text-success";
      } else if (status === "1002") {
        return "text-warning";
      } else if (status === "404") {
        return "text-muted";
      } else {
        return "text-danger";
      }
    },
    statusIcon(status) {
      if (status === "1000") {
        return "fa-circle-check";
      } else if (status === "1002") {
        return "fa-circle";
      } else {
        return "fa-circle-xmark";
      }
    },
    isOlderThanFourteenDays(creationDate) {
      const difference = moment.tz(creationDate, "America/Los_Angeles").diff(moment.tz("America/Los_Angeles").format("YYYY-MM-DD"), "days");

      if (difference < -14) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
.table img {
  max-height: 60px;
}
</style>
