<template>
  <b-form-select v-model="selected">
    <b-form-select-option v-for="(element, index) in options" :key="`option-${index}`" :value="element.value">{{ element.text[activeTranslation] }}</b-form-select-option>
  </b-form-select>
</template>

<script>
export default {
  name: "CountryDropdownComponent",
  props: {
    country: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selected: 'DE',
      activeTranslation: this.$i18n.locale,
      options: [
        {
          value: "AD",
          text: {
            "de": "Andorra",
            "en": "Andorra",
          },
        },
        {
          value: "AT",
          text: {
            "de": "Österreich",
            "en": "Austria",
          },
        },
        {
          value: "BE",
          text: {
            "de": "Belgien",
            "en": "Belgium",
          },
        },
        {
          value: "BG",
          text: {
            "de": "Bulgarien",
            "en": "Bulgaria",
          },
        },
        {
          value: "CH",
          text: {
            "de": "Schweiz",
            "en": "Switzerland",
          },
        },
        {
          value: "CY",
          text: {
            "de": "Zypern",
            "en": "Cyprus",
          },
        },
        {
          value: "CZ",
          text: {
            "de": "Tschechische Republik",
            "en": "Czech Republic",
          },
        },
        {
          value: "DE",
          text: {
            "de": "Deutschland",
            "en": "Germany",
          },
        },
        {
          value: "DK",
          text: {
            "de": "Dänemark",
            "en": "Denmark",
          },
        },
        {
          value: "EE",
          text: {
            "de": "Estland",
            "en": "Estonia",
          },
        },
        {
          value: "ES",
          text: {
            "de": "Spanien",
            "en": "Spain",
          },
        },
        {
          value: "FI",
          text: {
            "de": "Finnland",
            "en": "Finland",
          },
        },
        {
          value: "FO",
          text: {
            "de": "Färöer Inseln",
            "en": "Faroe Islands",
          },
        },
        {
          value: "FR",
          text: {
            "de": "Frankreich",
            "en": "France",
          },
        },
        {
          value: "GB",
          text: {
            "de": "Großbritannien",
            "en": "Great Britain",
          },
        },
        {
          value: "GI",
          text: {
            "de": "Gibraltar",
            "en": "Gibraltar",
          },
        },
        {
          value: "GL",
          text: {
            "de": "Grönland",
            "en": "Greenland",
          },
        },
        {
          value: "GR",
          text: {
            "de": "Griechenland",
            "en": "Greece",
          },
        },
        {
          value: "HR",
          text: {
            "de": "Kroatien",
            "en": "Croatia",
          },
        },
        {
          value: "HU",
          text: {
            "de": "Ungarn",
            "en": "Hungary",
          },
        },
        {
          value: "IE",
          text: {
            "de": "Irland",
            "en": "Ireland",
          },
        },
        {
          value: "IS",
          text: {
            "de": "Island",
            "en": "Iceland",
          },
        },
        {
          value: "IT",
          text: {
            "de": "Italien",
            "en": "Italy",
          },
        },
        {
          value: "JE",
          text: {
            "de": "Jersey",
            "en": "Jersey",
          },
        },
        {
          value: "LI",
          text: {
            "de": "Liechtenstein",
            "en": "Liechtenstein",
          },
        },
        {
          value: "LT",
          text: {
            "de": "Litauen",
            "en": "Lithuania",
          },
        },
        {
          value: "LU",
          text: {
            "de": "Luxemburg",
            "en": "Luxembourg",
          },
        },
        {
          value: "LV",
          text: {
            "de": "Lettland",
            "en": "Latvia",
          },
        },
        {
          value: "MC",
          text: {
            "de": "Monaco",
            "en": "Monaco",
          },
        },
        {
          value: "MT",
          text: {
            "de": "Malta",
            "en": "Malta",
          },
        },
        {
          value: "NL",
          text: {
            "de": "Niederlande",
            "en": "Netherlands",
          },
        },
        {
          value: "NO",
          text: {
            "de": "Norwegen",
            "en": "Norway",
          },
        },
        {
          value: "PL",
          text: {
            "de": "Polen",
            "en": "Poland",
          },
        },
        {
          value: "PT",
          text: {
            "de": "Portugal",
            "en": "Portugal",
          },
        },
        {
          value: "RO",
          text: {
            "de": "Rumänien",
            "en": "Romania",
          },
        },
        {
          value: "SE",
          text: {
            "de": "Schweden",
            "en": "Sweden",
          },
        },
        {
          value: "SI",
          text: {
            "de": "Slowenien",
            "en": "Slovenia",
          },
        },
        {
          value: "SK",
          text: {
            "de": "Slowakei",
            "en": "Slovakia",
          },
        },
        {
          value: "SM",
          text: {
            "de": "San Marino",
            "en": "San Marino",
          },
        },
        {
          value: "VA",
          text: {
            "de": "Vatikanstadt",
            "en": "Vatican City St",
          },
        },
      ],
    };
  },
  watch: {
    selected(selected) {
      this.$emit("countrySelected", selected);
    },
    country(test){
      this.selected = test
    },
  },
  mounted() {
    this.selected = this.country;
  },
};
</script>
