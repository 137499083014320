<template>
  <div>
    <div class="bg-light py-4 header-box">
      <b-container class="d-flex align-items-center justify-content-between">
        <h3 class="font-weight-bold mb-0">{{ $t("transferCustomerFromResellertoReseller") }}</h3>
      </b-container>
    </div>
    <b-container class="pt-4">
      <div class="text-muted mb-4" v-html="$t('transferCustomerFromResellertoResellerInfo')"></div>

      <b-form v-on:submit.prevent="submit">
        <b-card footer-class="border-0 bg-light">
          <b-row>
            <b-col class="d-flex align-items-center">
              <label class="mb-0">{{ $t("approvalCode") }}</label>
            </b-col>
            <b-col>
              <b-form-input
                v-model="approvalCode"
                required
              ></b-form-input>
            </b-col>
          </b-row>

          <hr />

          <b-row>
            <b-col>
              <label class="mb-0">{{ $t("requestedBy") }}</label><br />
              <small class="text-muted">{{ $t("requestedByInfo") }}</small>
            </b-col>
            <b-col class="d-flex align-items-center">
              <b-form-input
                v-model="requestedBy"
                required
              ></b-form-input>
            </b-col>
          </b-row>
          
          <template #footer>
            <div class="d-flex justify-content-end">
              <submit-button-component
                :state="submitButtonState"
                :label="$t('previewTransfer')"
                :block="false"
                class="my-2"
              ></submit-button-component>
            </div>
          </template>
        </b-card>
      </b-form>
    </b-container>
  </div>
</template>

<script>
import SubmitButtonComponent from "../components/SubmitButtonComponent.vue";
import { functions } from "../plugins/firebase"
import { httpsCallable } from "firebase/functions";

export default {
  name: "ChangeOfResellerPreviewView",
  components: {
    SubmitButtonComponent,
  },
  data() {
    return {
      isWorking: false,
      approvalCode: "",
      requestedBy: "",
    };
  },
  methods: {
    async submit() {
      this.isWorking = true;

      const changeOfReseller = httpsCallable(functions, "changeOfReseller");
      await changeOfReseller({
        type: "RESELLER_CHANGE",
        action: "PREVIEW",
        approvalCode: this.approvalCode,
        resellerId: this.resellerId,
        requestedBy: this.requestedBy,
      })
        .then((response) => {
          this.$store.commit("storeTemporaryData", {
            changeOfReseller: {
              requestedBy: this.requestedBy,
              previewData: response.data,
            },
          });

          this.$router.push({
            name: "ChangeOfResellerCommitView",
          });
        })
        .catch((error) => {

          console.error(error);
          this.$store.dispatch("alertError", {
            message: error.message,
            show: true,
            noFooter: false,
          });
        });

      this.isWorking = false;
    },
  },
  computed: {
    submitButtonState() {
      if (this.isWorking === true) {
        return "working";
      }

      if (this.requestedBy === "" || this.approvalCode === "") {
        return "missingFields";
      }

      return "";
    },
    resellerId() {
      return this.$store.state.resellerData.resellerId;
    },
  },
};
</script>

<style></style>
