<template>
  <div class="py-4">
    <b-container class="h-100">
      <b-row class="h-100 justify-content-center align-items-center">
        <b-col xl="8">
          <b-card class="shadow-lg border-top border-bottom-0 border-left-0 border-right-0 border-primary" body-class="p-5">
            <div class="text-center d-flex align-items-center justify-content-center h2 mb-5">
              <logo-component
                :fill="'#212529'"
                :height="'40px'"
                :width="'40px'"
                class="h2"
                :lineBreak="true"
              >
                Adobe Cloud Store
              </logo-component>
            </div>
            <b-form v-on:submit.prevent="submit">
              <h3>{{ $t("createAccount") }}</h3>
              <p class="text-muted mb-5">
                {{ $t("createAccountText") }}
              </p>
              <b-row>
                <b-col xl="5" class="d-flex align-items-center">
                  <label class="mb-0">{{ $t("emailLabel") }}</label>
                </b-col>
                <b-col>
                  <b-form-input type="email" v-model="email" readonly />
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col xl="5" class="d-flex align-items-center">
                  <label class="mb-0">{{ $t("passwordLabel") }}</label>
                </b-col>
                <b-col>
                  <b-popover target="input-password" triggers="hover" placement="top">
                    <template #title>{{ $t("requirements") }}</template>
                    <span v-html="$t('requirementsText')"></span>
                  </b-popover>
                  <b-form-input
                    id="input-password"
                    type="password"
                    v-model="password"
                    :state="states.password"
                    aria-describedby="input-password-feedback"
                    required
                    trim
                  />
                  <b-form-invalid-feedback id="input-password-feedback">
                    {{ $t("passwordInsecure") }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
              <hr />
              <b-row class="mb-5">
                <b-col xl="5" class="d-flex align-items-center">
                  <label class="mb-0">{{ $t("confirmPassword") }}</label>
                </b-col>
                <b-col>
                  <b-form-input
                    id="input-password-confirm"
                    type="password"
                    v-model="confirmPassword"
                    :state="states.confirmPassword"
                    aria-describedby="input-password-confirm-feedback"
                    trim
                    required
                  />
                  <b-form-invalid-feedback id="input-password-confirm-feedback">
                    {{ $t("passwordsNotIdentical") }}
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>

              <submit-button-component :state="submitButtonState" :label="$t('createAccount')" :block="true"></submit-button-component>
            </b-form>
          </b-card>
          <div class="d-flex flex-column align-items-center mt-4">
            {{ $t("youAlreadyHaveAnAccount") }}
            <router-link :to="{ name: 'LogInView' }">{{ $t("loginText") }}</router-link>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import LogoComponent from "../components/LogoComponent.vue";
import isStrongPassword from "validator/es/lib/isStrongPassword";
import equals from "validator/es/lib/equals";
import SubmitButtonComponent from "../components/SubmitButtonComponent.vue";
import { auth, functions } from "../plugins/firebase"
import { signInWithEmailAndPassword } from "firebase/auth";
import { httpsCallable } from "firebase/functions";

export default {
  name: "ResellerCreateUserAccountView",
  components: {
    SubmitButtonComponent,
    LogoComponent,
  },
  data() {
    return {
      isWorking: false,
      docId: "",
      parentDocId: "",
      companyName: "",
      customernumber: "",
      email: "",
      password: "",
      confirmPassword: "",
      states: {
        email: null,
        password: null,
        confirmPassword: null,
      },

    };
  },
  computed: {
    submitButtonState() {
      if (
        !this.states.password ||
        !this.states.confirmPassword
      ) {
        return "missingFields";
      }

      if (this.isWorking === true) {
        return "working";
      }

      return "";
    },
  },
  mounted() {
    if ( this.$route.query.q !== undefined || this.$route.query.q !== "" ) {
      this.fetch();
    } else {
      this.$store.dispatch("alertError", {
        message: "Die Daten konnten nicht geladen werden.",
        show: true,
        noFooter: false,
      })
    }
  },
  methods: {
    async fetch() {
      this.isWorking = true;

      const fetchUserDoc = httpsCallable(functions, "fetchUserDoc");
      await fetchUserDoc({
        token: this.$route.query.q,
      })
        .then((result) => {
          const data = result.data;
          this.email = data.email
          this.customernumber = data.customernumber
          this.companyName = data.companyName
          this.docId = data.docId
          this.parentDocId = data.parentDocId
        })
        .catch((error) => {
          this.$store.dispatch("alertError", {
            message: JSON.parse(error.message).message,
            show: true,
            noFooter: false,
          })
        });

        this.isWorking = false;
    },
    async submit() {
      this.isWorking = true;

      const createFirebaseAccount = httpsCallable(functions, "createFirebaseAccount");
      await createFirebaseAccount({
          email: this.email,
          password: this.password,
          uid: this.parentDocId + "-" + this.docId,
          displayName: this.companyName,
        })
          .catch((error) => {
            console.error(error);
            this.$store.dispatch("alertError", {
              message: error.message,
              show: true,
              noFooter: false,
            })
          });

      const updateUserDoc = httpsCallable(functions, "updateUserDoc");
      await updateUserDoc({
          parentDocId: this.parentDocId,
          docId: this.docId,
        })
          .catch((error) => {
            console.error(error);
            this.$store.dispatch("alertError", {
              message: error.message,
              show: true,
              noFooter: false,
            })
          });

      await signInWithEmailAndPassword(auth, this.email, this.password)
        .catch((error) => console.error(error));

      this.isWorking = false;

      this.$router.replace({ name: "HomepageView" })
    },
  },
  watch: {
    password(password) {
      if (password === "") {
        this.states.password = null;
      } else {
        this.states.password = isStrongPassword(password, {
          minLength: 8,
          minLowercase: 2,
          minUppercase: 2,
          minNumbers: 2,
          minSymbols: 0,
        });
      }
    },
    confirmPassword(password) {
      if (password === "") {
        this.states.confirmPassword = null;
      } else {
        this.states.confirmPassword = equals(password, this.password);
      }
    },
  },
};
</script>

<style scoped>
.border-top {
  border-width: 5px !important;
}
</style>
