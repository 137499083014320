<template>
  <div>
    <div class="bg-light py-4 header-box">
      <b-container class="d-flex align-items-center justify-content-between">
        <h3 class="font-weight-bold mb-0">{{ $t("transferVipSubscriptions") }}</h3>
        <b-button variant="dark" :to="{ name: 'TransfersView' }" ><fa-icon :icon="['fa-regular', 'fa-box-archive']" class="mr-2"></fa-icon>{{ $t("transferArchive") }}</b-button>
      </b-container>
    </div>
    <b-container class="pt-4">
      <div class="text-muted mb-4" v-html="$t('transfersText2')"></div>

      <b-form v-on:submit.prevent="submit">
        <b-card footer-class="border-0 bg-light">
          <b-row>
            <b-col class="d-flex align-items-center">
              <label class="mb-0">{{ $t("membershipId") }}</label>
            </b-col>
            <b-col>
              <b-form-input
                v-model="membershipId"
                required
              ></b-form-input>
            </b-col>
          </b-row>

          <hr />

          <b-row>
            <b-col>
              <label class="mb-0">{{ $t("ignoreOrderReturn") }}</label><br />
              <small class="text-muted">{{ $t("optional") }}</small>
            </b-col>
            <b-col>
              <b-form-checkbox v-model="ignoreOrderReturn">{{ $t("ignoreOrderReturnText") }}</b-form-checkbox>
            </b-col>
          </b-row>

          <hr />

          <b-row>
            <b-col>
              <label class="mb-0">{{ $t("expireOpenPas") }}</label><br />
              <small class="text-muted">{{ $t("optional") }}</small>
            </b-col>
            <b-col>
              <b-form-checkbox v-model="expireOpenPas">{{ $t("expireOpenPasText") }}</b-form-checkbox>
            </b-col>
          </b-row>

          <hr />

          <b-row>
            <b-col>
            </b-col>
            <b-col>
              <label class="mb-0">
                <b-form-checkbox v-model="checkBox">
                  <span v-html="$t('transfersCheckBoxText')"></span>
                </b-form-checkbox>
              </label>
            </b-col>
          </b-row>
          
          <template #footer>
            <div class="d-flex justify-content-end">
              <submit-button-component
                :state="submitButtonState"
                :label="$t('showEligibleSubscriptions')"
                :block="false"
                class="my-2"
              ></submit-button-component>
            </div>
          </template>
        </b-card>
      </b-form>
    </b-container>
  </div>
</template>

<script>
import SubmitButtonComponent from "../components/SubmitButtonComponent.vue";
import { functions } from "../plugins/firebase"
import { httpsCallable } from "firebase/functions";

export default {
  name: "TransferSubscriptionsView",
  components: {
    SubmitButtonComponent,
  },
  data() {
    return {
      isWorking: false,
      membershipId: "",
      checkBox: false,
      ignoreOrderReturn: false,
      expireOpenPas: false,
    };
  },
  methods: {
    async submit() {
      this.isWorking = true;
      if (this.checkBox === false) {
        this.$store.dispatch("alertError", {
          message: "Sie müssen bestätigen, dass sie berechtigt sind diesen Tranfer durchzuführen.",
          show: true,
          noFooter: false,
        });
      } else {
        const previewOffers = httpsCallable(functions, "previewOffers");
        await previewOffers({
          membershipId: this.membershipId,
          resellerId: this.resellerId,
          query: {
            "ignore-order-return": this.ignoreOrderReturn,
            "expire-open-pas": this.expireOpenPas,
          },
        })
          .then((response) => {
            this.$store.commit("storeTemporaryData", {
              vipToVipmp: {
                ignoreOrderReturn: this.ignoreOrderReturn, 
                expireOpenPas: this.expireOpenPas 
              },
            });

            this.$router.push({
              name: "TransferSubscriptionsPrevieOffersView",
              params: { 
                docId: response.data, 
                membershipId: this.membershipId,
              },
            });
          })
          .catch((error) => {
            console.error(error);
            this.$store.dispatch("alertError", {
              message: error.message,
              show: true,
              noFooter: false,
            });
          });
      }
      this.isWorking = false;
    },
  },
  computed: {
    submitButtonState() {
      if (this.isWorking === true) {
        return "working";
      }

      if (this.checkBox === false) {
        return "missingFields";
      }

      return "";
    },
    resellerId() {
      return this.$store.state.resellerData.resellerId;
    },
  },
};
</script>

<style></style>
