<template>
  <div class="py-4">
    <b-container class="h-100">
      <b-row class="h-100 justify-content-center align-items-center">
        <b-col xl="6">
          <b-card class="shadow-lg border-top border-bottom-0 border-left-0 border-right-0 border-primary" body-class="p-5">
            <div class="text-center d-flex align-items-center justify-content-center h2 mb-5">
              <logo-component
                :fill="'#212529'"
                :height="'40px'"
                :width="'40px'"
                class="h2"
                :lineBreak="true"
              >
                Adobe Cloud Store
              </logo-component>
            </div>
            
            <div v-if="message" class="d-flex flex-column align-items-center">
              <div class="icon rounded-circle d-flex justify-content-center align-items-center alert-success text-success mb-3 flex-shrink-0 p-3">
                <fa-icon :icon="['fa-regular', 'fa-check']" size="2x"></fa-icon>
              </div>
              <div v-html="message" class="text-center"></div>
            </div>
            <div class="d-flex justify-content-center" v-else>
              <b-spinner variant="secondary"></b-spinner>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import LogoComponent from "../components/LogoComponent.vue";
import { functions } from "../plugins/firebase"
import { httpsCallable } from "firebase/functions";

export default {
  name: "ConfirmAccountEmailChangeView",
  components: {
    LogoComponent,
  },
  data() {
    return {
      message: "",
    };
  },
  async mounted() {
      const updateAccountEmail = httpsCallable(functions, "updateAccountEmail");
      updateAccountEmail({
        token: this.$route.params.token,
      })
        .then(() => {
          this.message = "Sie können sich nun mit Ihrer neuen E-Mail-Adresse anmelden.";
        })
        .catch(error => {
          this.$store.dispatch("alertError", {
            message: error.message,
            show: true,
            noFooter: false,
          })
        });
  },
};
</script>

<style scoped>
.border-top {
  border-width: 5px !important;
}
</style>
