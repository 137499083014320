<template>
  <div>
    <div class="bg-light py-4 header-box">
      <b-container class="d-flex align-items-center justify-content-between">
        <div>
          <h3 class="font-weight-bold mb-0">
            {{ $t("companyData") }}
          </h3>
        </div>
      </b-container>
    </div>
    <b-container class="pt-4">
      <b-form v-on:submit.prevent="updateResellerAccount">
        <b-card footer-class="border-0 bg-light">
          <b-row>
            <b-col class="d-flex flex-column justify-content-center">
              <label class="mb-0">{{
                $t("comlineCustomernumberLabel", {
                  companyName: settings.companyName,
                })
              }}</label>
              <small class="text-muted">{{ $t("notChangeableInfo") }}</small>
            </b-col>
            <b-col>
              <b-form-input readonly :value="$store.state.resellerData.customernumber" v-if="isLoading === false"></b-form-input>
              <b-skeleton type="input" v-else></b-skeleton>
            </b-col>
          </b-row>
          <hr />
          
          <b-row>
            <b-col class="d-flex flex-column justify-content-center">
              <label class="mb-0">{{ $t("companyLabel") }}</label>
              <small class="text-muted">{{ $t("notChangeableInfo") }}</small>
            </b-col>
            <b-col>
              <b-form-input readonly :value="profile.companyProfile.companyName" v-if="isLoading === false"></b-form-input>
              <b-skeleton type="input" v-else></b-skeleton>
            </b-col>
          </b-row>
          <hr />

          <b-row>
            <b-col class="d-flex align-items-center">
              <label class="mb-0">{{ $t("addressLine1Label") }}</label>
            </b-col>
            <b-col>
              <b-form-input v-model="profile.companyProfile.address.addressLine1" v-if="isLoading === false"></b-form-input>
              <b-skeleton type="input" v-else></b-skeleton>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col class="d-flex align-items-center">
              <label class="mb-0">{{ $t("addressLine2Label") }}</label>
            </b-col>
            <b-col>
              <b-form-input v-model="profile.companyProfile.address.addressLine2" v-if="isLoading === false"></b-form-input>
              <b-skeleton type="input" v-else></b-skeleton>
            </b-col>
          </b-row>
          <hr />

          <b-row>
            <b-col class="d-flex align-items-center">
              <label class="mb-0">{{ $t("postalCodeLabel") }}</label>
            </b-col>
            <b-col>
              <b-form-input
                id="input-postalcode"
                type="text"
                v-model="profile.companyProfile.address.postalCode"
                :state="states.postalCode"
                v-if="isLoading === false"
               
                aria-describedby="input-postalcode-feedback"
                trim
                required
              />
              <b-skeleton type="input" v-else></b-skeleton>
              <b-form-invalid-feedback id="input-postalcode-feedback">
                {{ $t("postalCodeInputErrorText") }}
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <hr />

          <b-row>
            <b-col class="d-flex align-items-center">
              <label class="mb-0">{{ $t("cityLabel") }}</label>
            </b-col>
            <b-col>
              <b-form-input v-model="profile.companyProfile.address.city" v-if="isLoading === false"></b-form-input>
              <b-skeleton type="input" v-else></b-skeleton>
            </b-col>
          </b-row>
          <hr />

          <b-row>
            <b-col class="d-flex flex-column justify-content-center">
              <label class="mb-0">{{ $t("countryLabel") }}</label>
              <small class="text-muted">{{ $t("notChangeableInfo") }}</small>
            </b-col>
            <b-col>
              <country-dropdown-component
                :country="profile.companyProfile.address.country"
                v-if="isLoading === false"
               
                disabled
              ></country-dropdown-component>
              <b-skeleton type="input" v-else></b-skeleton>
            </b-col>
          </b-row>
          <hr />

          <b-row>
            <b-col class="d-flex align-items-center">
              <label class="mb-0">{{ $t("phoneNumberLabel") }}</label>
            </b-col>
            <b-col>
              <b-form-input v-model="profile.companyProfile.address.phoneNumber" v-if="isLoading === false"></b-form-input>
              <b-skeleton type="input" v-else></b-skeleton>
            </b-col>
          </b-row>
          <hr />

          <b-row>
            <b-col class="d-flex align-items-center">
              <label class="mb-0">{{ $t("prefferredLanguageLabel") }}</label>
            </b-col>
            <b-col>
              <preferred-language-dropdown-component
                :preferredLanguage="profile.companyProfile.preferredLanguage"
                v-on:languageSelected="languageSelected"
                v-if="isLoading === false"
               
              ></preferred-language-dropdown-component>
              <b-skeleton type="input" v-else></b-skeleton>
            </b-col>
          </b-row>
          <hr />

          <b-row>
            <b-col class="d-flex flex-column">
              <label class="mb-0">{{ $t("companyLogo") }}</label>
              <small class="text-muted">{{ $t("companyLogoUploadInfo") }}</small>
            </b-col>
            <b-col>
              <b-form v-on:submit.prevent="updateResellerAccount">
                <div class="mb-3">
                  <div class="form-group">
                    <div class="input-group" :id="imageErrorSelectId">
                      <span class="input-group-btn ">
                        <span :class="`btn btn-default btn-file`">
                          {{ $t("selectImage") }} <input type="file" accept="image/*" id="imgInp" @change="uploadImage($event)">
                        </span>
                      </span>
                      <input type="text" class="form-control" id="imgNameInpt" :value="imageName" readonly>
                    </div>
                    <div class="invalidUpload" v-show="imageError !== ''"> {{imageError}} </div>
                    <small class="text-muted">{{ $t("companyLogoUploadInformation") }}</small>
                    <ImageBlob v-if="imagePath != ''" :data="{image: imagePath}"/>
                  </div>
                </div>
              </b-form>
            </b-col>
          </b-row>
                  
          <template #footer>
            <div class="d-flex justify-content-end">
              <submit-button-component :state="submitButtonState" :label="$t('save')" :block="false" class="my-2"></submit-button-component>
            </div>
          </template>
        </b-card>
      </b-form>
    </b-container>

  </div>
</template>

<script>
import { mapState } from "vuex";
import PreferredLanguageDropdownComponent from "../components/PreferredLanguageDropdownComponent.vue";
import SubmitButtonComponent from "../components/SubmitButtonComponent.vue";
import CountryDropdownComponent from "../components/CountryDropdownComponent.vue";
import ImageBlob from "../components/ImageBlob.vue";
import { functions, firestore, storage } from "../plugins/firebase"
import { httpsCallable } from "firebase/functions";
import { doc, getDoc } from "firebase/firestore";
import { ref as storageRef, uploadBytesResumable } from "firebase/storage";

export default {
  name: "ResellerProfileView",
  components: { 
    ImageBlob,
    SubmitButtonComponent, 
    PreferredLanguageDropdownComponent,
    CountryDropdownComponent,
 },
  data() {
    return {
      isLoading: true,
      isWorking: false,
      imagePath: "",
      imageName: "", 
      imageError: "",
      imageErrorSelectId: "",
      profile: null,
      states: {
        postalCode: null
      }
    };
  },
  created() {
    this.getResellerDataFromCollection();
  },
  computed: {
    ...mapState(["settings"]),
    submitButtonState() {
      if (
        !this.states.postalCode ||
        this.profile.companyProfile.address.addressLine1 === "" ||
        this.profile.companyProfile.address.city === ""
      ) {
        return "missingFields";
      }

      if (this.isWorking === true) {
        return "working";
      }

      return "";
    },
  },
  methods: {
    languageSelected(preferredLanguage) {
      this.profile.companyProfile.preferredLanguage = preferredLanguage;
    },
    async getResellerDataFromCollection() {
      const docRef = doc(firestore, "resellers", this.$store.state.resellerData.customernumber);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        this.profile = docSnapshot.data();
        this.imagePath = this.profile.logo?.path || "";
        this.imageName = this.profile.logo?.name || "";
        this.isLoading = false;
      } else {
        this.$store.dispatch("alertError", {
          message: "Die Daten konnten nicht abgerufen werden.",
          show: true,
          noFooter: false,
        });
      }
    },
    async uploadImage(data) {
      this.imageError = "";
      this.imageErrorSelectId = '';

      const image = data.target.files[0];
      const imageObject = new Image()
      imageObject.src = window.URL.createObjectURL(image)
      imageObject.onload = () => {
        // Dateigröße muss kleine als 5mb sein
        if(image.size > 5000000){
          this.imageErrorSelectId = 'imageErrorSelect';
          this.imageError = this.$t("companyLogoUploadErrorSize");
          return
        }

        // Datei muss einen der erlaubten Typen haben.
        const allowedTypes = ["image/png", "image/jpg", "image/jpeg", "image/gif"]
        if(!allowedTypes.includes(image.type)){
          this.imageErrorSelectId = 'imageErrorSelect';
          this.imageError = this.$t("companyLogoUploadErrorType");
          return
        }

        // Bilddimensionen müssen 512px x 512px sein. 
        if( imageObject.width !== 512 && imageObject.height !== 512){
          this.imageErrorSelectId = 'imageErrorSelect';
          this.imageError = this.$t("companyLogoUploadErrorDimensions");
          return
        }


        const path = `reseller/${this.$store.state.resellerData.customernumber}/${image.name}`
        const useRef = storageRef(
          storage,
          path
        );
        const uploadTask = uploadBytesResumable(useRef, image);

        uploadTask.on(
          "state_changed",
          () => {
            this.imageName = "Uploading...";
          },
          (err) => {
            this.console.error('error', err)
          },
          () => {
            this.imageName = image.name;
            this.imagePath = path;
          }
        );
      }
    },
    imageSize(url) {
        const img = document.createElement("img");

        const promise = new Promise((resolve, reject) => {
          img.onload = () => {
            // Natural size is the actual image size regardless of rendering.
            // The 'normal' `width`/`height` are for the **rendered** size.
            const width  = img.naturalWidth;
            const height = img.naturalHeight; 

            // Resolve promise with the width and height
            resolve({width, height});
          };

          // Reject promise on error
          img.onerror = reject;
        });

        // Setting the source makes it start downloading and eventually call `onload`
        img.src = url;

        return promise;
    },
    async updateResellerAccount() {
      this.isWorking = true;

      const updateResellerAccount = httpsCallable(functions, "updateResellerAccount");
      await updateResellerAccount({
        resellerId: this.$store.state.resellerData.resellerId,
        customerNumber: this.$store.state.resellerData.customernumber,
        logo: {
          path: this.imagePath || "",
          name: this.imageName || "",
          },
        profile: {
          companyProfile: {
            companyName: this.profile.companyProfile.companyName,
            preferredLanguage: this.profile.companyProfile.preferredLanguage,
            address: this.profile.companyProfile.address,
            contacts: this.profile.companyProfile.contacts,
          },
        },
      })
        .then((result) => {
          this.profile = result.data;
        })
        .catch(() => {
          this.$store.dispatch("alertError", {
            message: "Die Daten konnten nicht gespeichert werden.",
            show: true,
            noFooter: false,
          })
        });

      this.isWorking = false;
    },
  },
  watch: {
    "profile.companyProfile.address.postalCode": function(postalCode) {
      const postalCodeRegex = this.$store.state.postalCodeRegex;

      if (postalCode === "") {
        this.states.postalCode = null;
      } else {
        if ( postalCode.match(postalCodeRegex[this.profile.companyProfile.address.country]) ) {
          this.states.postalCode = true;
        } else {
          this.states.postalCode = false;
        }
      }
    },
  }
};
</script>

<style scoped>
.btn-file {
    position: relative;
    overflow: hidden;
}
.btn-file:has(#imgInp) {
  border: 1px solid #ced4da;
  border-right: 0;
  border-radius: 4px 0 0 4px;
}
.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block;
}

#img-upload{
    width: 100%;
}
.invalidUpload {
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dd4050;
}
#imageErrorSelect .btn-file, #imageErrorSelect #imgNameInpt{
  border-color: #dd4050 !important;
}

</style>