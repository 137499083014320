<template>
  <div class="w-100 flex-grow-1" id="app">
    <b-container class="py-4">
      <b-row class="justify-content-center">
        <b-col md="8" lg="6" xl="5">
          <b-card class="shadow-lg border-top border-bottom-0 border-left-0 border-right-0 border-primary" body-class="p-5">
            <div class="text-center d-flex align-items-center justify-content-center h2 mb-3">
              <logo-component
                :fill="'#212529'"
                :height="'40px'"
                :width="'40px'"
                class="h2"
                :lineBreak="true"
              >
                Adobe Cloud Store
              </logo-component>
            </div>
            <div class="text-center mb-3">
            <fa-icon :icon="['fa-regular', 'fa-triangle-person-digging']" size="6x"></fa-icon>
            </div>
            <div class="text-center" v-html="$t('maintenance')">
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import LogoComponent from "./components/LogoComponent.vue";

export default {
  name: "Maintenance",
  components: {
    LogoComponent,
  },
};
</script>

<style scoped>
.border-top {
  border-width: 5px !important;
}
</style>
