import Vue from "vue";
import App from "./App.vue";
import Maintenance from "./Maintenance.vue";

import router from "./plugins/router";

import i18n from "./plugins/i18n";

import store from "./plugins/store";

import "./plugins/fontawesome";

import { auth, firestore } from "./plugins/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";

import {
  accountStatus,
  subscriptionStatus,
  orderStatus,
  currency,
  dateTime,
  date,
  productName,
  productNameWithoutLevel,
  itemNumberObject,
  marketSegment,
  marketSubSegment,
  volumeLevel,
} from "./plugins/filters";

import { BootstrapVue } from "bootstrap-vue";
/* import "@comlinedev/bootstrap4/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css"; */
import "./styles.scss";
Vue.use(BootstrapVue);

import "flag-icons/css/flag-icons.min.css";

Vue.config.productionTip = false;

const getLocaleFromLocalStorage = () => {
  if (window.localStorage.getItem("comline-acs-lang")) {
    return window.localStorage.getItem("comline-acs-lang");
  }
  return "de";
};

let maintenanceMode


let app;
onAuthStateChanged(auth, () => {
  if (!app) {
    onSnapshot(doc(firestore, "generalSettings", "maintenance"), (doc) => {

      maintenanceMode = doc.data()?.maintenanceMode || false
      i18n.locale = getLocaleFromLocalStorage();

      Vue.filter("accountStatus", accountStatus);
      Vue.filter("subscriptionStatus", subscriptionStatus);
      Vue.filter("orderStatus", orderStatus);
      Vue.filter("currency", currency);
      Vue.filter("dateTime", dateTime);
      Vue.filter("date", date);
      Vue.filter("productName", productName);
      Vue.filter("productNameWithoutLevel", productNameWithoutLevel);
      Vue.filter("itemNumberObject", itemNumberObject);
      Vue.filter("marketSegment", marketSegment);
      Vue.filter("marketSubSegment", marketSubSegment);
      Vue.filter("volumeLevel", volumeLevel);
      
      app = new Vue({
        router,
        store,
        i18n,
        render: (h) => h(maintenanceMode ? Maintenance : App),
      }).$mount("#app");
      maintenanceMode && console.log("%c ⚠️Wartungsmodus wurde aktiviert", "background: red; color: white");
    });
  }
});
