export default {
  partnerStore: false,
  companyName: "ComLine",
  firebaseConfig: {
    apiKey: "AIzaSyDylNqfB_hQrI43Vog66-cwWcdVps17LPw",
    authDomain: "clgmbh-marketplace.firebaseapp.com",
    projectId: "clgmbh-marketplace",
    storageBucket: "clgmbh-marketplace.appspot.com",
    messagingSenderId: "990039872990",
    appId: "1:990039872990:web:a7aa87bcd703c90d01f94f",
  },
};