<template>
  <b-container class="pt-4 d-flex flex-column align-items-center justify-content-center">
    <div class="icon rounded-circle d-flex justify-content-center align-items-center alert-success text-success mb-3 flex-shrink-0">
      <fa-icon :icon="['fa-regular', 'fa-check']" size="2x"></fa-icon>
    </div>
    {{ $t("createOrderSuccessInfo") }}
  </b-container>
</template>

<script>
export default {
  name: "CreateOrderCompletedView",
}
</script>

<style scoped>
.icon {
  width: 64px;
  height: 64px;
}
</style>
