<template>
  <div>
    <div class="bg-light py-4 header-box">
      <b-container class="d-flex align-items-center justify-content-between">
        <h3 class="font-weight-bold mb-0">
          {{ $t("faq") }}
        </h3>
      </b-container>
    </div>
    <b-container class="pt-4">
      <div class="text-muted mb-4" v-html="$t('faqText')"></div>
      <div class="accordion" role="tablist">
        <b-card no-body v-for="(question, index) in $t('faqQuestions')" :key="`question-${index}`">
          <b-card-header header-tag="header" class="p-0 bg-light" role="tab">
            <div class="p-3" v-b-toggle="`question-${index}`">
              {{index+1}}. {{ question.question }}
            </div>
          </b-card-header>
          <b-collapse :id="`question-${index}`" accordion="my-accordion" role="tabpanel">
            <b-card-body>
              <b-card-text><span v-html="question.answer"></span></b-card-text>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>

      <h5 class="font-weight-bold mt-4">{{ $t("furtherQuestions") }}</h5>
      <p class="text-muted mb-0">{{ $t("contactUs") }}</p>
      <b-row>
        <b-col sm="12" md="6" class="mt-4" v-for="(contact, index) in $t('contactPersons')" :key="`contact-${index}`">
          <b-card body-class="d-lg-flex">
            <div class="mr-3">
              <img :src="`/${ contact.image }`" alt="Mitarbeiter Foto" class="rounded-circle" style="width:80px;">
            </div>
            <div class="d-flex flex-column">
              <strong>{{ contact.name }}</strong>
              <small>{{ contact.title }}</small>
              <hr />
              <div>{{ contact.phoneNumber }}</div>
              {{ contact.email }}
            </div>
          </b-card>
        </b-col>
      </b-row>

    </b-container>
  </div>
</template>

<script>
export default {
  name: "FaqView",
}
</script>
