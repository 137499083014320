<template>
  <b-navbar toggleable="md" type="light" variant="faded" v-if="display">
    <b-container fluid class="my-1">
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown right>
            <template #button-content>
              <span :class="`fi fi-${selectedIcon}`"></span>
            </template>
            <b-dropdown-item v-on:click="switchLanguage('de')">
              <span class="fi fi-de"></span> Deutsch
            </b-dropdown-item>
            <b-dropdown-item v-on:click="switchLanguage('en')">
              <span class="fi fi-eu"></span> English (EU)
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-container>
  </b-navbar>
</template>

<script>
export default {
  name: "SignedOutNavigationComponent",
  created() {
    if (this.$i18n.locale === "en") {
      this.selectedIcon = "eu";
    }
  },
  methods: {
    switchLanguage(language) {
      window.localStorage.setItem("comline-acs-lang", language);
      this.$i18n.locale = language;
      this.selectedLangauge = language;
      if (language === "en") {
        this.selectedIcon = "eu";
      } else {
        this.selectedIcon = language;
      }
    },
  },
  data() {
    return {
      selectedLangauge: "de",
      selectedIcon: "de",
      display: true,
    };
  },
};
</script>