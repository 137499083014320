<template>
  <div>
    <signed-out-navigation-component></signed-out-navigation-component>
    <b-container class="py-4">
      <b-row class="h-100 justify-content-center align-items-center">
        <b-col xl="8">
          <b-card
            class="shadow-lg border-top border-bottom-0 border-left-0 border-right-0 border-primary"
            body-class="p-5"
          >
            <div class="text-center d-flex align-items-center justify-content-center h2 mb-5">
              <logo-component
                :fill="'#212529'"
                :height="'40px'"
                :width="'40px'"
                class="h2"
                :lineBreak="true"
              >
                Adobe Cloud Store
              </logo-component>
            </div>

            <div v-html="$t('privacyProtection')"></div>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import LogoComponent from "../components/LogoComponent.vue";
import SignedOutNavigationComponent from '../components/SignedOutNavigationComponent.vue';

export default {
  name: "PrivacyProtectionView",
  components: {
    LogoComponent,
    SignedOutNavigationComponent,
  },
};
</script>

<style scoped>
.border-top {
  border-width: 5px !important;
}
</style>
