<template>
  <div id="newspanel" class="shadow-lg bg-light">
    <div id="newspanel-header" class="d-flex justify-content-between p-3 bg-dark align-items-center">
      <strong class="text-light m-0">{{ $t("news") }}</strong>
      <b-button size="sm" variant="dark" v-on:click="$store.commit('showNewsPanel', false)">
        <fa-icon :icon="['fa-regular', 'fa-angle-right']" fixed-width></fa-icon>
      </b-button>
    </div>
    <div id="newspanel-content" class="p-3">
      <b-card
        class="mb-3"
        v-for="(notification, index) in $store.state.notifications"
        :key="`notification-${index}`"
        v-show="!$store.state.resellerData.hiddenNotifications.includes(notification.docId)"
      >
        <template #header>
          <div class="d-flex justify-content-between align-items-center">
            {{ notification.docData[$i18n.locale].title }}
            <b-button size="sm" variant="light" v-on:click="hideNotification(notification.docId)">
              <fa-icon :icon="['fa-regular', 'fa-xmark']" fixed-width></fa-icon>
            </b-button>
          </div>
        </template>
        <div v-html="notification.docData[$i18n.locale].body"></div>
      </b-card>
    </div>
  </div>
</template>

<script>
import { firestore, auth } from "../plugins/firebase";
import { doc, updateDoc, arrayUnion } from "firebase/firestore";

export default {
  name: "NewsPanelComponent",
  methods: {
    hideNotification(notificationId) {
      const docRef = doc(firestore, "resellers", auth.currentUser.uid);
      updateDoc(docRef, {
        hiddenNotifications: arrayUnion(notificationId),
      });
    },
  },
};
</script>

<style scoped>
#newspanel {
  background: #fff;
  top: 0;
  bottom: 0;
  right: 0;
  width: 320px;
  position: absolute;
  overflow-y: scroll;
}
#newspanel-header {
  height: 64px;
}
</style>
