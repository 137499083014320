<template>
  <div>
    <div class="bg-light py-4 header-box">
      <b-container class="d-flex align-items-center justify-content-between">
        <h3 class="font-weight-bold mb-0">
          {{ $t("userManagement") }}
        </h3>
        <b-button variant="dark" v-b-modal="'add-user-modal'">
          <fa-icon :icon="['fa-regular', 'fa-user-plus']" class="mr-2"></fa-icon>{{ $t("createUser") }}
        </b-button>
      </b-container>
    </div>
    <b-container class="pt-4">
      <div class="text-muted mb-4" v-html="$t('usersText')"></div>
      <b-card no-body>
        <b-card-header class="p-3">
          <b-input-group>
            <template #prepend>
              <b-input-group-text class="bg-white pr-0"
                ><fa-icon :icon="['fa-regular', 'fa-magnifying-glass']"></fa-icon
              ></b-input-group-text>
            </template>
            <b-input :placeholder="$t('searchByUserName')" class="border-left-0" v-model="filter"></b-input>
          </b-input-group>
        </b-card-header>
        <b-table
          :items="provideItems"
          :fields="fields"
          :filter="filter"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          class="mb-0"
          show-empty
          :empty-filtered-text="$t('emptyFilteredText')"
          :empty-text="$t('noUsers')"
          ref="table"
          sort-icon-left
        >
          <template #empty="scope">
            <div class="text-center font-italic">{{ scope.emptyText }}</div>
          </template>

          <template #emptyfiltered="scope">
            <div class="text-center font-italic">{{ scope.emptyFilteredText }}</div>
          </template>

          <template #cell(firstName)="data">
            {{ data.item.docData.firstName }}
          </template>

          <template #cell(lastName)="data">
            {{ data.item.docData.lastName }}
          </template>

          <template #cell(allowedToOrder)="data">
            <span v-if="data.item.docData.allowedToOrder === undefined || data.item.docData.allowedToOrder"> {{ $t("yes") }}</span>
            <span v-else> {{ $t("no") }}</span>
          </template>

          <template #cell(status)="data">
            {{ data.item.docData.status | accountStatus }}
          </template>

          <template #cell(actions)="data">
            <div class="d-flex justify-content-end">
              <b-button size="sm" variant="outline-primary" v-on:click="showEditUserModal(data.item)" class="mr-2">
                {{ $t("edit") }}
              </b-button>
              <b-button size="sm" variant="outline-danger" v-on:click="showDeleteUserConfirmModal(data.item)">
                <fa-icon :icon="['fa-regular', 'fa-trash']" class="mr-2"></fa-icon>{{ $t("delete") }}
              </b-button>
            </div>
          </template>
        </b-table>
      </b-card>
    </b-container>

    <b-modal
      id="add-user-modal"
      :content-class="contentClass"
      :footer-class="footerClass"
      :header-class="headerClass"
      :body-class="bodyClass"
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      :title="$t('createUser')"
      v-on:hidden="clear()"
    >
      <b-form-group
        :label="$t('firstNameLabel')"
        label-for="input-firstname"
        :invalid-feedback="$t('firstNameInputErrorText')"
        :state="states.firstName"
      >
        <b-form-input
          id="input-firstname"
          type="text"
          v-model="firstName"
          :state="states.firstName"
          trim
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        :label="$t('lastNameLabel')"
        label-for="input-lastname"
        :invalid-feedback="$t('lastNameInputErrorText')"
        :state="states.lastName"
      >
        <b-form-input
          id="input-lastname"
          type="text"
          v-model="lastName"
          :state="states.lastName"
          trim
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        :label="$t('emailLabel')"
        label-for="input-email"
        :invalid-feedback="$t('emailInputErrorText')"
        :state="states.email"
        :description="$t('createUserEmailHint')"
      >
        <b-form-input id="input-email" type="text" v-model="email" :state="states.email" trim required></b-form-input>
      </b-form-group>

      <b-form-group 
        :label="$t('userAllowedToOrder')"
        label-for="allowed-to-order-check"
        class="mb-0"
      >
        <b-form-checkbox v-model="allowedToOrder" name="check-button" switch>
          <span v-if="allowedToOrder">{{ $t("yes") }}</span>
          <span v-else>{{ $t("no") }}</span>
        </b-form-checkbox>
      </b-form-group>

      <template #modal-footer="{ cancel }">
        <b-button variant="outline-secondary" v-on:click="cancel()">
          {{ $t("abort") }}
        </b-button>

        <submit-button-component
          v-on:click="createUser()"
          :variant="'primary'"
          :state="submitButtonState"
          :label="$t('create')"
          :block="false"
        ></submit-button-component>
      </template>
    </b-modal>

    <b-modal
      ref="edit-user-modal"
      id="edit-user-modal"
      :content-class="contentClass"
      :footer-class="footerClass"
      :header-class="headerClass"
      :body-class="bodyClass"
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
      :title="$t('editUser')"
      v-on:hidden="clear()"
    >
      <b-form-group 
        :label="$t('userAllowedToOrder')"
        label-for="allowed-to-order-check"
        class="mb-0"
      >
        <b-form-checkbox v-model="userToBeEdited.docData.allowedToOrder" name="check-button" switch>
          <span v-if="userToBeEdited.docData.allowedToOrder">{{ $t("yes") }}</span>
          <span v-else>{{ $t("no") }}</span>
        </b-form-checkbox>
      </b-form-group>

      <template #modal-footer="{ cancel }">
        <b-button variant="outline-secondary" v-on:click="cancel()">
          {{ $t("abort") }}
        </b-button>

        <submit-button-component
          v-on:click="editUser()"
          :variant="'primary'"
          :state="editSubmitButtonState"
          :label="$t('save')"
          :block="false"
        ></submit-button-component>
      </template>
    </b-modal>

    <b-modal
      ref="delete-user-confirm-modal"
      hide-header
      no-close-on-esc
      no-close-on-backdrop
      :content-class="contentClass"
      :footer-class="footerClass"
      :body-class="bodyClass"
      v-on:hidden="userToBeDeleted = null"
    >
      <div
        v-if="userToBeDeleted !== null"
        v-html="
          $t('deleteUserText', {
            firstName: userToBeDeleted.docData.firstName,
            lastName: userToBeDeleted.docData.lastName,
          })
        "
      ></div>
      
      <template #modal-footer="{ cancel }">
        <b-button variant="outline-secondary" v-on:click="cancel()">
          {{ $t("abort") }}
        </b-button>

        <b-button v-on:click="deleteUser(userToBeDeleted.docId)" variant="danger">
          {{ $t("delete") }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import SubmitButtonComponent from "../components/SubmitButtonComponent.vue";
import isEmail from "validator/es/lib/isEmail";
import { orderBy } from "lodash-es";
import { firestore } from "../plugins/firebase";
import { collection, onSnapshot, addDoc, deleteDoc, doc, updateDoc } from "firebase/firestore";

export default {
  name: "ResellerUserManagementView",
  components: {
    SubmitButtonComponent,
  },
  computed: {
    submitButtonState() {
      if (!this.states.firstName || !this.states.lastName || !this.states.email) {
        return "missingFields";
      }

      if (this.isWorking === true) {
        return "working";
      }

      return "";
    },
    editSubmitButtonState() {
      if (this.isWorking === true) {
        return "working";
      }

      return "";
    },
  },
  data() {
    return {
      items: [],
      filter: "",
      sortBy: "lastName",
      sortDesc: false,
      fields: [
        {
          key: "firstName",
          label: this.$t("firstNameLabel"),
          sortable: true,
        },
        {
          key: "lastName",
          label: this.$t("lastNameLabel"),
          sortable: true,
        },
        {
          key: "status",
          label: this.$t("status"),
          sortable: true,
        },
        {
          key: "allowedToOrder",
          label: "Darf bestellen",
          sortable: false,
        },
        {
          key: "actions",
          label: "",
          sortable: false,
        },
      ],
      contentClass: ["border-0", "shadow-lg"],
      footerClass: ["border-0 bg-light p-4"],
      bodyClass: ["p-4"],
      headerClass: ["border-0 bg-light px-4"],
      isWorking: false,
      firstName: "",
      lastName: "",
      allowedToOrder: true,
      email: "",
      states: {
        firstName: null,
        lastName: null,
        email: null,
      },
      userToBeDeleted: null,
      userToBeEdited: {
        docId: "",
        docData:  {
          allowedToOrder: "",
        },
      },
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      const colRef = collection(firestore, "resellers", this.$store.state.resellerData.uid, "users");
      onSnapshot(colRef, (querySnapshot) => {
        this.items = [];

        querySnapshot.forEach((doc) => {
          this.items.push({
            docId: doc.id,
            docData: doc.data()
          });
        });

        this.$refs.table.refresh();
      });
    },
    provideItems(ctx) {
      const users = [];

      this.items.forEach((item) => {
        if (
          item.docData.firstName.toLowerCase().includes(ctx.filter.toLowerCase()) ||
          item.docData.lastName.toLowerCase().includes(ctx.filter.toLowerCase()) ||
          item.docData.email.toLowerCase().includes(ctx.filter.toLowerCase())
        ) {
          users.push(item);
        }
      });

      let direction = "asc";
      if (ctx.sortDesc) {
        direction = "desc";
      }

      return orderBy(
        users,
        (user) => {
          return user.docData[ctx.sortBy].toLowerCase();
        },
        direction
      );
    },
    clear() {
      this.isWorking = false;
      this.allowedToOrder = true;
      this.firstName = "";
      this.lastName = "";
      this.email = "";
      this.userToBeEdited = {
        docId: "",
        docData:  {
          allowedToOrder: "",
        },
      };
    },
    async createUser() {
      this.isWorking = true;

      try {
        const colRef = collection(firestore, "resellers", this.$store.state.resellerData.uid, "users");
        await addDoc(colRef, {
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          allowedToOrder: this.allowedToOrder,
          status: "1002",
          customernumber: this.$store.state.resellerData.customernumber,
          language: this.$store.state.resellerData.companyProfile.preferredLanguage,
        });

        this.$store.dispatch("alertSuccess", {
          message: this.$t("userReceivesEmail"),
          show: true,
          noFooter: false,
        });

        this.$bvModal.hide("add-user-modal");
      } catch(error) {
        this.$store.dispatch("alertError", {
          message: error.message,
          show: true,
          noFooter: false,
        });
      }
    },
    showDeleteUserConfirmModal(user) {
      this.userToBeDeleted = user;
      this.$refs["delete-user-confirm-modal"].show();
    },
    async deleteUser(docId) {
      try {
        const docRef = doc(firestore, "resellers", this.$store.state.resellerData.uid, "users", docId);
        await deleteDoc(docRef);
      } catch (error) {
        this.$store.dispatch("alertError", {
          message: error.message,
          show: true,
          noFooter: false,
        });
      }

      this.$refs["delete-user-confirm-modal"].hide();
    },
    showEditUserModal(user) {
      this.userToBeEdited = user;
      this.$refs["edit-user-modal"].show();
    },
    async editUser() {
      this.isWorking = true;

      try {
        const docRef = doc(firestore, "resellers", this.$store.state.resellerData.uid, "users", this.userToBeEdited.docId);
        await updateDoc(docRef, {
          allowedToOrder: this. userToBeEdited.docData.allowedToOrder,
        });

        this.$bvModal.hide("edit-user-modal");
      } catch(error) {
        this.$store.dispatch("alertError", {
          message: error.message,
          show: true,
          noFooter: false,
        });
      }
    },
  },
  watch: {
    userToBeEdited(user) {
      if (user.docData.allowedToOrder === undefined) {
        user.docData["allowedToOrder"] = true;
      }
    },
    email(email) {
      if (email === "") {
        this.states.email = null;
      } else if (this.$store.state.resellerData.email === email) {
        this.states.email = false;
      } else {
        this.states.email = isEmail(email);
      }
    },
    firstName(firstName) {
      if (firstName === "") {
        this.states.firstName = null;
      } else {
        if (firstName.match(this.$store.state.nameRegex)) {
          this.states.firstName = true;
        } else {
          this.states.firstName = false;
        }
      }
    },
    lastName(lastName) {
      if (lastName === "") {
        this.states.lastName = null;
      } else {
        if (lastName.match(this.$store.state.nameRegex)) {
          this.states.lastName = true;
        } else {
          this.states.lastName = false;
        }
      }
    },
  },
};
</script>
