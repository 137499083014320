<template><div>
  <div class="fixedOverlay" v-if="version < newVersion">
    <div class="w-100 flex-grow-1" id="versioncontrol">
      <b-container class="py-4">
        <b-row class="justify-content-center">
          <b-col md="8" lg="6" xl="5">
            <b-card class="shadow-lg border-top border-bottom-0 border-left-0 border-right-0 border-primary" body-class="p-5">
              <div class="text-center d-flex align-items-center justify-content-center h2 mb-3">
                <logo-component
                  :fill="'#212529'"
                  :height="'40px'"
                  :width="'40px'"
                  class="h2"
                  :lineBreak="true"
                >
                  Adobe Cloud Store
                </logo-component>
              </div>
              <div class="text-center" style="margin-top: 50px; margin-bottom: 50px;">
              <fa-icon :icon="['fa-regular', 'fa-arrow-up-big-small']" size="6x"></fa-icon>
              </div>
              <div class="text-center mt-3 mb-3">
                {{$t('newVersion')}}
              </div>
              <div class="text-center mt-3">
                <b-button variant="success" @click="reloadPage">{{$t('update')}}</b-button>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div></div>
</template>

<script>
import LogoComponent from "./LogoComponent.vue";
import { mapState } from "vuex";
import { doc, onSnapshot } from "firebase/firestore";
import { firestore } from "../plugins/firebase";

export default {
  name: "Maintenance",
  data(){
    return {
      unsubscribe: false
    }
  },
  components: {
    LogoComponent,
  },
  computed: mapState(["version", "newVersion"]),
  methods: {
    reloadPage() {
      window.location.reload();
    }
  },
  created() {
    this.unsubscribe = onSnapshot(doc(firestore, "generalSettings", "currentVersion"), (doc) => {
      const recivedData = doc.data()?.store.replaceAll(".", "") || "0"
      this.$store.dispatch('newVersion', recivedData);
    })
  },
  unmounted() {
   this.unsubscribe()
  }
};
</script>

<style scoped>
  .border-top {
    border-width: 5px !important;
  }
  .fixedOverlay {
    position: fixed; 
    top: 0px; 
    left: 0px; 
    height: 100vh;
    width: 100vw;
    z-index: 999999;
    background-color: white;
  }
</style>
