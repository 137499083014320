import { functions } from "../firebase";
import { httpsCallable } from "firebase/functions";
import moment from "moment-timezone";
import PouchDB from 'pouchdb';
import md5 from 'md5';

const db = new PouchDB('priceData', {revs_limit: 2});


/**
 * Checks if a given date is the same day as today.
 *
 * @param {Date} date - The date to check (optional).
 * @returns {boolean} True if the date is today, false otherwise.
 */
function isToday(date) {
  const timeNow = moment.tz("America/Los_Angeles")
  if(!date) return false;
  return moment(timeNow).isSame(date, 'day')
}

/**
 * Loads data from a server by calling the "getPrices" Firebase Function and saves it to a PouchDB database.
 *
 * @param {string} customerId - The ID of the customer to associate with this data.
 * @param {object} data - The data to be loaded from the server.
 * @param {object} [doc] - An optional existing document in the PouchDB database to update instead of creating a new one.
 *
 * @returns {Promise<object>} A promise that resolves to the retrieved data products with prices.
 */
async function loadDataFromServer(customerId, data, doc) {
  const getPricesFromServer = httpsCallable(functions, "getPrices");
  const productsWithPrices = await getPricesFromServer(data);
  const checkSum = md5(JSON.stringify(data));
  const dataToSave = doc || {}

  if (!dataToSave._id){
    dataToSave._id = String(customerId)
  }
  dataToSave.checkDate = moment.tz("America/Los_Angeles").toString()
  dataToSave.data = JSON.stringify(productsWithPrices)
  dataToSave.checkSum = checkSum
  
  await db.put(dataToSave)
  .catch(function (err) {
    console.log(err);
  });

  return productsWithPrices
}


/**
 * Function to retrieve the prices of the products from the database or localStorage.
 * @param {String} customerId - The input data for the request.
 * @param {Object} data - The input data for the request.
 * @param {boolean} [forceRefresh=false] - A flag to force a refresh of the data, even if it's not today.
 * @returns {Promise} A promise resolving with the products and their corresponding prices.
 */
async function getPrices(customerId, data = {}, forceRefresh=false ) {
  const dbData = await db.get(String(customerId))
  .catch(()=>{return loadDataFromServer(customerId, data)})

  const checkSum = md5(JSON.stringify(data));

  // datum prüfen LA Zeit
  if (checkSum === dbData.checkSum && isToday(new Date(dbData.checkDate)) && !forceRefresh) {
    console.log("pricesFromDB")
    return await JSON.parse(dbData.data)
  } else {
    return loadDataFromServer(customerId, data, dbData)
  }
}


export { getPrices }