export default {
  companyName: "ComLine",
  imprint: `
    <h3>Imprint</h3>
    <p>
      <strong>ComLine GmbH Value Added Distribution</strong><br />
      Lise-Meitner-Strasse 16<br />
      24941 Flensburg<br />
      Germany
    </p>
    <p>
      Phone: +49 461 77 303 300<br />
      Fax: +49 461 77 303 390<br />
      Email: info@comline-shop.de
    </p>
    <p>
    Managing Directors: Richard Gäbel, Anja Sobottka, Oliver Bartsch
    </p>
    <p>
      Register court: Local court Flensburg<br />
      Register number: HRB 9025 FL<br />
      Sales tax ID: DE 815306628
    </p>
    <p>
      Responsible according to § 18 2 MStV: Richard Gäbel, Anja Sobottka, Oliver Bartsch
    </p>

    <h3>
      Legal notice
    </h3>
    <p>
      ComLine GmbH assumes no liability or guarantee for the topicality, completeness and correctness of the information provided on this website.
    </p>
    <p>
      ComLine GmbH reserves the right to make changes or additions to the information provided without prior notice.
    </p>
    <p>
      The content of this website is protected by copyright. You are not allowed to save or reproduce the texts and graphics provided here in whole or in part without the written permission of the owner. Please note that the copyright for images and texts of the products offered in our online shop lies with the respective manufacturer. Approval for the use of this content must therefore be requested directly from the manufacturer.
    </p>
    <p>
      ComLine GmbH is not liable for direct or indirect damage, including lost profits, which arise from or in any other connection with information provided on this website.
    </p>
  `,
  adobeTc: `
  <h3>ADOBE VALUE INCENTIVE PLAN TERMS AND CONDITIONS</h3>
  <p><br>
    This Value Incentive Plan (“VIP” or “Program”) Commercial Membership Agreement (“Agreement”) sets forth the terms of your participation as a Member in the Program. The Agreement is effective as of the date of online enrollment. This Agreement is entered into by and between Adobe and the customer identified in on-line enrollment to become a Member. “Adobe” shall mean either ADOBE INC., a Delaware corporation having a place of business at 345 Park Avenue, San Jose, CA 95110-2704, if the Agreement is entered into with a Member’s organization that is located in the United States, Canada, or Mexico, ADOBE SYSTEMS SOFTWARE IRELAND LIMITED, a company incorporated in Ireland and having a place of business at 4‑6&nbsp;Riverwalk, Citywest Business Campus, Dublin 24, Ireland as authorized agent of Adobe Systems Pty Ltd (ABN 72 054 247 835), if the Agreement is entered into with a Member’s organization that is located in Australia, or otherwise with ADOBE SYSTEMS SOFTWARE IRELAND LIMITED, a company incorporated in Ireland and having a place of business at 4-6 Riverwalk, Citywest Business Campus, Dublin 24, Ireland. Member shall license all software, services, and offerings available under the Program (collectively “Product”) to be installed and used within the United States (including United States territories and military bases wherever located), Canada, or Mexico from Adobe U.S. Member shall license all Products to be installed and used in Australia from Adobe Ireland, as authorized agent of Adobe Systems Pty Ltd (ABN 72 054 247 835). Member shall license all Products to be installed and used in all other countries and territories from Adobe Ireland.</p>
  <h4><br>
    1.&nbsp;Program Description.</h4>
  <p><br>
    <b>1.1&nbsp;<i>General and Program Term</i></b><i>.</i> The VIP Program is a flexible licensing program designed to allow qualified and eligible customers to manage and deploy Product licenses purchased through the Program. The VIP Program is a membership program with license Subscription Periods as further defined in Section&nbsp;3.4 of this Agreement. Once the organization enrolls in a membership through the VIP administration user interface and is accepted by Adobe into the Program, the organization will be a member of the Program (“Member”) until the earliest of when (a)&nbsp;Adobe terminates the Program, (b)&nbsp;this Agreement terminates. Member’s participation is further subject to the terms stated in the program guide for the Program, which may be updated from time to time and is available at <a href="https://www.adobe.com/go/vip_program_guide_en" target="_blank">www.adobe.com/go/vip_program_guide_en</a> (“Program Guide”). The Program Guide is hereby incorporated into the Agreement by reference. Adobe may change the terms of the Program at its sole discretion. If Program terms change Member may be required to reaccept the Program terms in the Console.
  </p>
  <p><br>
    <b>1.2&nbsp;<i>Termination.</i></b> Notwithstanding the foregoing, either party may terminate the Agreement with or without cause on thirty&nbsp;(30)&nbsp;days prior written notice. Termination of this Agreement shall not affect Member’s obligations with regard to any Products ordered prior to the termination date, including without limitation any subscription terms or payment obligations. In addition, Adobe may immediately terminate this Agreement, upon written notice, for a material breach (including but not limited to any misappropriation or infringement of Adobe’s intellectual property rights). If this Agreement terminates, then Member’s organization shall immediately cease use of the Products, delete the Products from all computer systems and IT equipment on which it resides, and return to Member’s Account Manager any media containing the Products as well as any related materials. Account Manager means either (i)&nbsp;Reseller, if a transaction is through a reseller, or (ii)&nbsp;Adobe representative, if a transaction is with Adobe.
  </p>
  <p>&nbsp;</p>
  <p><b>1.3&nbsp;<i>TOU.</i></b> The access and use of the Products is governed by the applicable Adobe terms of use (“TOU”) available at <a href="https://www.adobe.com/legal/terms.html">https://www.adobe.com/legal/terms.html</a>. The terms of the applicable TOUs are hereby incorporated by reference (including without limitations terms related to governing law and venue). In the event of inconsistency between the terms of this Agreement and the terms of the TOU, the terms of this Agreement shall control.</p>
  <p><br>
    <b>1.4&nbsp;<i>Program Products.</i> </b>For a complete list of Products available through the Program, Member shall contact its&nbsp;Account Manager or go to the Program web pages located on Adobe.com <a href="http://www.adobe.com/howtobuy/buying-programs/vip.html">http://www.adobe.com/howtobuy/buying-programs/vip.html</a>. All Products purchased through the Program are solely for use within Member’s own organization and all re-sale, sublicensing, and other distribution is prohibited except as set forth in Section 4.1 and/or the Program Guide, if applicable. Certain offerings may be available for license by purchasing Consumables, as described in the Program Guide.
  </p>
  <p>&nbsp;</p>
  <p><b>1.5&nbsp;<i>Affiliates.</i></b> Adobe agrees that customer's Affiliates may place orders under this Agreement for the Products provided hereunder. Customer retains ultimate liability for any acts or omissions of such Affiliate. Affiliate means, for Member, any other entity that controls, is controlled by, or under common control with, Member. For the purposes of this Section 1.5, the term “control” means the direct or indirect power to direct the affairs of the other entity through at least 50% of the shares, voting rights, participation, or economic interest in this entity.</p>
  <h4><br>
    2.&nbsp;Participation.</h4>
  <p><br>
    <b>2.1&nbsp;<i>Adobe ID and VIP ID.</i></b> An Adobe ID will be required in order to enroll in the Program. Each Member will be assigned a VIP ID which must be referenced on all orders. Member is responsible for acts or omissions of anyone who obtains access to and use of the Products through Member.
  </p>
  <p><br>
    <b>2.2&nbsp;<i>Console.</i></b> The Program administration user interface is the “Admin Console.” Once the terms of this agreement are accepted, the individual accepting on behalf of the organization will be assigned as the Contract Owner. The Contract Owner can add system administrator(s) (each an “Administrator”). The Contract Owner and any Administrator will be provided access to the Admin Console where they will have the ability to access the Product, manage their subscriptions, and view their account information. The Admin Console will allow the Administrator to invite additional users within their organization to gain access to the Admin Console. Member authorizes any Administrator or Contract Owner to act on Member’s behalf.
  </p>
  <p><br>
    <b>2.3&nbsp;<i>Confidentiality</i>.</b> Member shall treat VIP ID as confidential and not share or disclose such information.
  </p>
  <p><br>
    <b>2.4&nbsp;<i>Memberships</i></b>. The terms of Exhibit B apply to Education, Government, and Non-Profit Memberships. Member must use a separate VIP Agreement for any Products made available and ordered for use in the People’s Republic of China.
  </p>
  <h4><br>
    3.&nbsp;Ordering, Pricing, and Fulfillment.</h4>
  <p><br>
    <b>3.1<i>&nbsp;Ordering and Pricing</i>.</b> Member shall place Product orders with their Account Manager. All fees are determined by Member’s Account Manager. Matters such as price, delivery, and payment terms must be agreed between Member and Member’s Account Manager. Adobe cannot guarantee any particular discount, unless Adobe is Member’s Account Manager.
  </p>
  <p><br>
    <b>3.2&nbsp;<i>Access, Admin Console Deployment, and Fulfillment</i></b><i>.</i> Upon becoming a Member, Member’s Administrator will be provided access to the available Products, through the Admin Console. Member may add many Products from the Admin Console and obtain immediate access to such Products. Adobe must receive an order for any such Products within the Grace Period.&nbsp;
  </p>
  <p><br>
    <b>3.3 <i>Grace Period for Products Added in Admin Console</i></b><i>.</i> The Grace Period is 14 days after adding such Products. If Adobe does not receive an order for such Products within the Grace Period, then Member will no longer be allowed to add additional Products until payment is made for all added Products. Member may manage the number of Products deployed in the Console.
  </p>
  <p><br>
    <b>3.4&nbsp;<i>Agreement Anniversary Date, Subscription Period, and Renewals.</i></b>
  </p>
  <p>&nbsp;</p>
  <p><b>3.4.1&nbsp;<i>Anniversary Date</i>.</b>&nbsp;Unless otherwise communicated by Adobe, Member’s Anniversary Date is the day twelve months after Adobe accepts Member’s initial order (“Anniversary Date”).</p>
  <p>&nbsp;</p>
  <p><b>3.4.2&nbsp;<i>Subscription Period.</i> </b>The Subscription Period means the period that a Member may use Products and includes the initial Subscription Period and any renewal Subscription Period. The initial Subscription Period means the period that begins on Member’s initial order date and ends the day prior to the Anniversary Date. With Product subscription renewal, a renewal Subscription Period will begin on the Anniversary Date and continue until the day prior to the next Anniversary Date. Use of subscription Products and any related services co-terminates on the last day of the Subscription Period. Most Consumables must be used within a single Subscription Period, and any unused Consumables will expire on the last day of the Subscription Period. Additional information may be found in the Program Guide.</p>
  <p>&nbsp;</p>
  <p><b>3.4.3&nbsp;<i>Subscription Renewals</i>.</b>&nbsp;Adobe or Account Manager will use reasonable efforts to notify Member prior to any Subscription Period end date. Subscriptions must be renewed prior to the Anniversary Date in order to ensure uninterrupted use of Product.&nbsp;</p>
  <p><br>
    <b>3.5&nbsp;<i>Upgrade Protection</i>.</b> Purchase of the subscription Product(s) includes upgrade entitlement, meaning Member will be entitled to receive the latest generally available version of a subscription Product purchased under the program as long as the Product subscription is paid and active at the time Adobe makes the new version of the Product commercially available.
  </p>
  <p><br>
    <b>3.6&nbsp;<i>Returns</i>.</b> Without prejudice to any rights that a Member may have under any consumer laws in the jurisdiction that Member is located, once a Product is installed or accessed, Member cannot return it. If Member requests a return prior to installation, Member must return the entire order. Member must request the return of Products purchased under the Agreement through Member’s Account Manager. Subject to any applicable warranty rights, return requests must be made to Member’s Account Manager within fourteen (14) days after Member’s original Product order date. Adobe must approve all return requests before any return is valid, as further described in the Program Guide.
  </p>
  <p><br>
    <b>3.7&nbsp;<i>Orders Direct From Adobe</i>. </b>If Member orders direct from Adobe, then this section will apply. Members can add Products at any time via the Admin Console or via an Adobe representative, but you must place an order for all Products with an Adobe representative. Products are priced at the rates current at the time ordered and prorated based on the days remaining in your Subscription Period. Notwithstanding the language in 3.2, 3.3, and 4.2, if you add Products via the Admin Console, then Adobe my invoice you directly for those products at the then current price. Your order may be subject to credit approval. Member will be charged the Product price, plus applicable taxes. For any ordered Products, Member must pay in full within 30 days of the invoice date in accordance with the invoice. Any amounts not paid when due will bear interest at a rate which is the lesser of 1.0% per month or the maximum rate permitted by applicable law on any overdue fees, from the date due until the date the full amount plus interest is paid in-full.
  </p>
  <p>&nbsp;</p>
  <h4>4. VIP Marketplace.</h4>
  <p>&nbsp;</p>
  <p><b>4.1</b>.&nbsp; Section 4 “VIP Marketplace” will apply to Products licensed through VIP Marketplaces.&nbsp; Unless otherwise communicated by Adobe, Subscriptions offered through VIP Marketplaces auto-renew on Member’s Anniversary Date despite language in 3.4.3 above.&nbsp; Member may work with their Account Manager to adjust Product subscriptions.</p>
  <p>&nbsp;</p>
  <p><b>4.2 <i>VIP Marketplace Grace Period</i>.</b>&nbsp; Notwithstanding Section 3.2 and 3.3, the Grace Period for VIP Marketplace Products is seven days after adding Products, not 14 days.&nbsp; If Adobe does not receive an order for Products added through VIP Marketplace within the Grace Period, then such Products will be removed.&nbsp;&nbsp;</p>
  <h4>&nbsp;</h4>
  <h4>5.&nbsp;Miscellaneous.</h4>
  <p>&nbsp;</p>
  <p><b>5.1&nbsp;<i>Transfer of License</i>.</b> Product TOUs do not govern restrictions regarding the transfer of Products licensed under or in connection with this Agreement. In limited circumstances, Adobe may permit the transfer of Product licenses under this Agreement in its sole and exclusive discretion. Such requests should be directed to Adobe Customer Service including a description of the reason for the proposed transfer and the contact information of the transferee. Additional information may be found in the Program Guide. For clarity, all offerings are licensed, not sold.</p>
  <p><br>
    <b>5.2&nbsp;<i>License Compliance</i>.</b> Member must maintain systems and/or procedures sufficient to ensure an accurate record of the number of copies of the Products that have been installed and/or deployed and retain records of Product installation and/or deployment for two&nbsp;(2) years after the termination of the Agreement. Adobe and/or its representatives may conduct an audit of Member’s Product installation/deployment not more than once per year on thirty&nbsp;(30) days written notice. Such audit will require Member to provide an unedited, accurate report of all Products installed/deployed and accessed by Member and all valid purchase documentation for all Products within thirty (30) days after request. If the audit findings demonstrate non-conformity with the Product licenses, Member shall purchase the necessary licenses within thirty&nbsp;(30) days after being so notified. Notwithstanding the foregoing, Adobe reserves the right to conduct an onsite audit of Member license installation and deployment after ten&nbsp;(10) business days’ prior written notice during regular business hours. This Section&nbsp;4.2 shall survive termination of the Agreement for a period of two&nbsp;(2)&nbsp;years.
  </p>
  <p><br>
    <b>5.3&nbsp;<i>Use of Information</i>.</b> Adobe may use information about Member or Affiliate, including name and contact information, for fulfilling obligations under the Agreement. For more information, please see the Adobe Privacy Center (<a href="https://www.adobe.com/privacy.html">adobe.com/privacy</a>).
  </p>
  <p><br>
    <b>5.4&nbsp;<i>Standing</i>.</b> The parties agree that the United Nations Convention on Contracts for the International Sale of Goods is specifically excluded from application to this Agreement. If Member is an entity of the United States Federal Government, Member agrees that Adobe shall have standing and the right to assert any breach of contract claim arising out of this Agreement under the Contracts Disputes Act of 1978 (“Disputes Act”).
  </p>
  <p><br>
    <b>5.5&nbsp;<i>General</i>. </b>The parties are independent contractors, and this Agreement will not be construed to imply that either party is the agent or venturer of the other. Member may not assign this Agreement (by operation of law or otherwise) without the prior written consent of Adobe and any prohibited assignment is null and void. Adobe may, at its sole discretion, assign or novate this Agreement, without the prior written consent of Member. This Agreement will be binding upon and will inure to the benefit of any permitted successors or assignees. No modification will be valid or binding unless in writing. This Agreement (including the Program Guide, applicable TOUs, and any Exhibits or on-line enrollment information, if applicable) represents the entire agreement between the parties on the subject matter of this Agreement. If any provision of this Agreement is held unenforceable, the remainder of the Agreement will continue in full force and effect.
  </p>
  <p><br>
    This Agreement is prepared and is executed in the English language only. The English language version shall be controlling in all respects, and any version of this Agreement in any other language, shall not be binding and shall have no effect. Further, each party agrees that signature by Member or Adobe on any non-English language version, even if there is no signature(s) on the English language version shall be deemed execution of the English language version and binding on the parties. Without limiting the foregoing, if there is any conflict or inconsistency between the English language version of this Agreement and any other translated version of this Agreement, the English language version and interpretation shall prevail. All communications or notices to be made or given pursuant to this Agreement and any disputes arising under this Agreement shall be conducted or resolved in the English language.</p>
  <p><br>
    <b>5.6&nbsp;<i>Services.</i></b> The Product may integrate with a variety of services, operated either by Adobe or by third&nbsp;parties, which may contain user-generated content that may be (a)&nbsp;inappropriate for minors, (b)&nbsp;illegal in some countries; or (c)&nbsp;inappropriate for viewing at work. A complete list of integrated services is available here: <a href="https://www.adobe.com/go/integratedservices">www.adobe.com/go/integratedservices</a>. If Member wants to prevent viewing of or access to user-generated content services it may (i)&nbsp;disable service access in the Creative Cloud Packager, where that functionality is made available, or (ii)&nbsp;block access to the services via its network firewall. Services are not, under any circumstances, available to users under the age of thirteen (13). Adobe disclaims liability for all user-generated content available via the services. Member is responsible for determining whether use of the services complies with applicable laws in Member’s jurisdiction. Member is not entitled to a refund and Adobe is not liable (i)&nbsp;if access to the services is slowed or blocked as a result of government or service provider action, or (ii)&nbsp;if Adobe reasonably deems it necessary to block access to some or all of the services.
  </p>
  <p>&nbsp;</p>
  <h3>EXHIBIT B<br>
    EDUCATION, GOVERNMENT, AND NON-PROFIT MEMBERSHIPS</h3>
  <p>&nbsp;</p>
  <h4><b>A. EDUCATION MEMBERS</b></h4>
  <p><br>
    For the Member who is an Education Entity (defined below), the following additional terms apply. Adobe reserves the right to terminate Education memberships if Member is not an Educational Entity.</p>
  <h4><br>
    1. Definitions Applicable to Education Members.</h4>
  <p><br>
    <b>1.1&nbsp;<i>Education Entity.</i></b> The following is a non-exhaustive list of qualified educational institutions: (a)&nbsp;Accredited (by official accrediting entities) public or private primary or secondary school providing full-time instruction; (b)&nbsp;Accredited public or private university or college (including community, junior, or vocational college) that grants degrees requiring not less than the equivalent of two years of full-time study; (c)&nbsp;Named educational institutions approved by Adobe, only if individual named entities are approved by Adobe in writing; (d)&nbsp;Hospitals that are wholly owned and operated by an otherwise qualified educational institution, where “wholly owned and operated” means the educational institution is sole owner of the hospital and the only entity exercising control over day to day operations; and (e)&nbsp;Higher education research laboratories that are a public institution and recognized by a national or state educational authority.
  </p>
  <p><br>
    The following is a non-exhaustive list of entities that are not qualified educational institutions: (a)&nbsp;Non-accredited schools; (b)&nbsp;Museums or libraries; (c)&nbsp;Hospitals not wholly owned and operated by an otherwise qualified educational institution; (d)&nbsp;Churches or religious organizations that are not accredited schools; (e)&nbsp;Vocational training centers or schools granting certificates for courses such as computer software training or job training that are not accredited schools or which grant degrees requiring less than the equivalent of two years of full-time study; (f)&nbsp;Military schools that do not grant academic degrees; and (g)&nbsp;Research laboratories not recognized by a national or state ministry overseeing education. For example, institutions recognized by other government branches are not eligible.</p>
  <p><br>
    The above lists do not apply to the countries as listed in Section 1.2 (Regional-Specific Definition) below.</p>
  <p><br>
    <b>1.2&nbsp;<i>Regional-Specific Definition of Education Entity.</i></b>
  </p>
  <p>(a)&nbsp;<i>Asia Pacific Countries excluding Southeast Asia Countries</i> as defined in sub-paragraph (b) below. If Education Member is resident in Australia, New Zealand, India, Sri Lanka, mainland China, Hong Kong SAR of China., Taiwan region, the Republic of Korea, the People’s Republic of Bangladesh, the Federal Democratic of Nepal, the Republic of the Union of Myanmar, Pakistan or Mongolia or any country designated by Adobe from time to time, “Education Entity” shall mean the entities that satisfy the meaning of “Qualified Educational Users” (except for the sections entitled “Full and Part Time Faculty and Staff” and “Students”) designated by Adobe on <a href="http://www.adobe.com/ap/education/purchasing/qualify.html">http://www.adobe.com/ap/education/purchasing/qualify.html</a> (or its successor web site thereto), as updated by Adobe from time to time.</p>
  <p><br>
    (b)&nbsp;<i>Southeast Asia Countries.</i> If Education Member is resident in Indonesia, Malaysia, Philippines, Singapore, Thailand and Vietnam, “Education Entity” or “Education Institution” shall have the respective meanings designated by Adobe on <a disablelinktracking="false" href="https://www.adobe.com/go/edu_entity_sea" target="_blank">https://www.adobe.com/go/edu_entity_sea</a>&nbsp;(or its successor web site thereto), as updated by Adobe from time to time.</p>
  <p><br>
    (c)&nbsp;<i>Japan</i>. If Education Member is resident in Japan, “Education Entity” or “Education Institution” shall have the respective meanings designated by Adobe on <a href="https://helpx.adobe.com/jp/x-productkb/policy-pricing/cq081918191.html">https://helpx.adobe.com/jp/x-productkb/policy-pricing/cq081918191.html</a> (or its successor web site thereto), as updated by Adobe from time to time.</p>
  <p>&nbsp;</p>
  <p><b>1.3&nbsp;<i>Primary and Secondary Schools.</i></b> Primary and Secondary Schools are defined in the Program Guide. Adobe may have offers available to members that qualify as Primary and Secondary Schools. Adobe reserves the right to terminate Primary and Secondary School licenses and memberships if Member is not a Primary and Secondary School as defined in the Program Guide. See VIP Education Program Guide for additional details.</p>
  <h4><br>
    <b>B.&nbsp;GOVERNMENT MEMBERS</b>
  </h4>
  <p><br>
    For the Member who is a Government Entity (defined below), the following additional terms apply. Adobe reserves the right to terminate Government memberships if Member is not a Government Entity.</p>
  <h4><br>
    1.&nbsp;Definitions Applicable to Government Members.</h4>
  <p><b><i>Government Entity</i>.</b>&nbsp;Participation is contingent upon Member (and each Affiliate) being a “government entity”, which means: (a)&nbsp;a federal, central, or national agency, department, commission, board, office, council, or authority (executive, legislative, or judicial); (b)&nbsp;a municipality, special district, city, county, or state governmental agency, department, commission, board, office, council, entity, or authority, or any other agency in the executive, legislative, or judicial branch of state or local government that is created by the constitution or a statute of the governing state, including the district, regional, and state administrative offices; or (c)&nbsp;a public agency or organization created and/or funded by federal, state, or local governments and authorized to conduct the business of governing or supporting citizens, businesses, or other governmental entities. For the avoidance of doubt, the following entities are not Government Entities: private “for profit” companies, non-profit organizations, trade or industry associations, higher education institutions, and labor unions, even those conducting work on behalf of or with government agencies, unless such entity has a specific letter of authorization from a U.S Government Entity pursuant to FAR Part 51. Member represents to Adobe that it and its Affiliates are government entities. A&nbsp;list of qualified “Government Entities” for&nbsp;Japan is available at:&nbsp;<a href="http://www.adobe.com/jp/aboutadobe/volumelicensing/pdfs/cl5_government_license_table.pdf" target="_blank">http://www.adobe.com/jp/aboutadobe/volumelicensing/pdfs/cl5_government_license_table.pdf</a>.</p>
  <p><br>
    <b>1.1 For France: </b>a Government Entity is either an agency; a minister; a commission, board, office, or council (national, regional, or local); a city; a region; or any entity subject to the French Public Law and under the administration of a government entity.
  </p>
  <h4><br>
    2.&nbsp;Terms Applicable to Government Members.</h4>
  <p><br>
    <b>2.1&nbsp;<i>Additional Restrictions</i>.</b> For United States Federal Government Members, it is understood that any orders are subject to FAR&nbsp;52.232-18 (Availability of Funds) and FAR 52.232-19 (Availability of Funds for the Next Fiscal Year) and therefore United States Federal Government Members shall not deploy any Product unless funds are available to pay for such orders. To the extent any state or local government entity is subject to similar requirements, such entities shall not deploy any Product unless funds are available to pay for such orders.
  </p>
  <p><br>
    <b>2.2&nbsp;<i>Termination</i>.</b> This Agreement may be terminated by a Member who is a United States federal government customer pursuant to FAR 52.249-1 (Termination for Convenience of the Government). Adobe may change the Terms at its sole discretion.
  </p>
  <p><br>
    <b>2.3&nbsp;<i>Federal Government Members</i>.</b> Notice to U.S. Federal Government End Users (Commercial Items): The Products, provided under this Agreement are “Commercial Item(s),” as that term is defined at 48 C.F.R. §2.101, consisting of “Commercial Computer Software” and “Commercial Computer Software Documentation,” and services related thereto, as such terms are used in 48&nbsp;C.F.R. §12.212 or 48 C.F.R. §227.7202, as applicable. Consistent with 48 C.F.R. §12.212 or 48&nbsp;C.F.R. §227.7202-1 through §227.7202-4, as applicable, the Commercial Computer Software and Commercial Computer Software Documentation are being licensed to U.S. Federal Government End Users (a) only as Commercial Items and (b)&nbsp;with only those rights as are granted to all other end users pursuant to the terms and conditions of this Agreement and the TOUs. Unpublished rights are reserved under the laws of the United States- Adobe Inc., 345 Park Avenue, San Jose, CA 95110-2704, USA.
  </p>
  <h4><br>
    C.&nbsp;NON-PROFIT MEMBERS</h4>
  <p><br>
    Additional terms applicable to Non-Profit Members are contained in the Program Guide. Adobe reserves the right to terminate memberships, if Member is not an eligible non-profit organization, as described on <a href="https://helpx.adobe.com/buying-programs/non-profit.html">https://helpx.adobe.com/buying-programs/non-profit.html</a>.<br>
  </p>
  `,
  comlineTc:`
  <p><strong>1. General</strong></p>
  <ol>
    <li>The following General Terms and Conditions are a component of every contract we conclude.</li>
    <li>None of the customers purchasing conditions apply, unless we have expressly accepted them in writing.</li>
    <li>The only law that shall apply for all contractual relationships with the purchaser shall be German law. The UN
      convention on contracts for the international sale of goods (CISG) is not valid for the relationship between the
      purchaser and us.</li>
    <li>These conditions apply to legal communication with commercial and industrial enterprises. We only supply
      customers, who when entering into a contract with us are exercising their commercial or self-employed business
      capacity and legal entities under public law and separate estates subject to public law (mutual trading activities).
    </li>
    <li>The agreed place of fulfilment for supply and payment, and the legal venue, is Flensburg, with the proviso that we
      are also entitled to take proceedings at the location of the purchaser′s headquarters or branch.</li>
  </ol>
  <p"><br></p>
    <p><strong>2. Offers, conclusion of contract, prices</strong></p>
    <ol>
      <li>Our offers are non-binding in terms of quantity, price and delivery time. On placing an order the purchaser
        bindingly declares that they wish to purchase the goods they have ordered. The acceptance that leads to the
        conclusion of a contract can be expressed by the delivery of the wares, the performance of a service, or that we
        in some other manner confirm the acceptance of the order to the customer. The contract comes into force with such
        acceptance by us.</li>
      <li>Entrepreneurs and consequently also the purchaser have no right of revocation.</li>
      <li>Technical and design deviations from the descriptions and specifications in documents, and the right to make
        model, design and material modifications in the light of technical progress are reserved, without any rights
        against us being able to be derived from this.</li>
      <li>Our prices are net prices. Unless indicated otherwise in our order confirmation, they are valid ex warehouse
        Flensburg or in the case of direct mail orders from the German border or German port of import plus the value
        added tax valid on the day of delivery, excluding packaging, insurance, freight charges and any applicable
        low-quantity surcharge. These items will be invoiced separately. The customer is responsible for disposing of the
        packaging at his or her own cost.</li>
    </ol>
    <p"><br></p>
      <p><strong>3. Shipping</strong></p>
      <ol>
        <li>The collection of the delivery item is the responsibility of the purchaser as a matter of principle, and must
          take place upon notification of availability at the factory or warehouse without delay.</li>
        <li>In the event that delivery by us is desired, this will take place ex warehouse as a matter of principle, and –
          unless agreed otherwise in individual cases – at the purchaser′s cost. If the purchaser wishes the delivery item
          sent to them, the risk of accidental gloss and accidental impairment of the delivery item is transferred to the
          purchaser when the delivery item is handed over to the carrier, haulier, shipping agent or collector. This also
          applies if, as a result of an individual agreement, we bear the freight costs or we ourselves execute the
          shipping via transport person.</li>
        <li>If the purchaser is in default of acceptance, we are entitled to claim refund of any expenditure associated
          therewith. On commencement of the default of acceptance the risk of accidental impairment and accidental loss is
          transferred to the purchaser.</li>
        <li>If shipping is delayed for reasons beyond our control, the risk is transferred to the purchaser on
          notification of readiness for shipping.</li>
      </ol>
      <p"><br></p>
        <p><strong>4. Delivery, withdrawal</strong></p>
        <ol>
          <li>Any details relating to delivery dates are not binding for us and only represent an unbinding estimation.
            Any agreement of binding delivery dates must be made in writing.</li>
          <li>The contract is concluded with the reservation of correct and timely self-delivery. We are therefore
            entitled to withdraw if our supplier if the purchase contract we have concluded before concluding the
            respective contract of sale has not been fulfilled for reasons beyond our control.</li>
          <li>We are also entitled to withdraw from concluded contracts if the procurement of the goods at the time the
            contract was concluded is rendered substantially impeded as a result of disasters, acts of war or similar
            circumstances. A substantial impediment exists in any case if the market price of the purchase item has
            increased by 25% or more between conclusion of the respective contract and the scheduled delivery date.</li>
          <li>In the event of disturbances in our operations or warehouse that are beyond our control, or in the event of
            impeding official measures, the delivery period shall be extended by the length of the disruption. We are also
            entitled to withdraw from concluded contracts if the disturbance for which we are not responsible continues
            over a period longer than four weeks. A disturbance in the aforementioned context also includes such business
            interruptions or constraints caused by large-scale personnel shortfall resulting from illness, labour disputes
            or the like.</li>
        </ol>
        <p"><br></p>
          <p><strong>5. Acceptance and handover, examination, notification of defects</strong></p>
          <ol>
            <li>The purchaser is duty bound to also accept part deliveries. Part deliveries can be invoiced immediately.
            </li>
            <li>In the case of deliveries on demand, the demand represents a primary obligation within the agreed time
              limit.</li>
            <li>The customer is obliged to accept delivery items even if they display minor defects, irrespective of the
              existence of any possible warranty claims.</li>
            <li>The purchaser is obliged to examine the delivery item for any defects on handover, and to report any such
              faults or defects in writing and without delay. The relevant provisions and legal consequences of the German
              Commercial Code (HGB) shall apply accordingly.</li>
          </ol>
          <p"><br></p>
            <p><strong>6. Reservation of proprietary rights</strong></p>
            <ol>
              <li>The delivered goods remain our property until full payment of our purchase price claim and any other
                claims we have against the purchaser. The reservation of proprietary rights also remains in force if
                individual receivables are included in a running account and the balance has been determined and
                acknowledged.</li>
              <li>If the goods subject to reserved ownership delivered by us are treated or processed by the purchaser,
                the treatment or processing is done for us as manufacturer in terms of § 950 BGB.</li>
              <li>If our reserved goods are combined, mixed or processed together with the purchaser′s own goods, we
                acquire co-ownership of the new item or in the case of a mixed product in proportion to the value of our
                reserved goods compared to the value of the other goods at the time of the combination, mixing or
                processing. We make no claim regarding the increased value created through the combination, mixing or
                processing.</li>
              <li">The purchaser herewith assigns to us his claims and all ancillary rights resulting from the resale of
                our reserved goods and all goods that are our property in accordance with section six two or in our
                co-ownership in accordance with section six three as security for all our claims against the purchaser at
                the time of the resale.</li>
                <li">In the case of resale of the goods of which we are co-owners in accordance with section six three,
                  only that part of the claim is assigned to us that corresponds to the value of our co-ownership share.
                  </li>
                  <li">If the purchaser has sold the claim from the resale within the scope of real factoring, the
                    purchaser shall assign to us the claim against the factor that replaces it.</li>
                    <li">We herewith expressly accept the above assignments.</li>
                      <li>If the value of the claims assigned to us as security exceeds our claims against the purchaser
                        by more than 20%, we are obliged, at the purchaser′s request, to release the existing securities
                        above that value.</li>
                      <li>The purchaser is only entitled to resell or reserved goods and the goods that are our property
                        in accordance with section six two or in our co-ownership in accordance with section six three
                        within the scope of his normal business and only under the prerequisite that the purchase price
                        claim from the resale in accordance with section six four is transferred to us.</li>
                      <li>The purchaser is obliged to adequately insure our reserved goods and goods that are our property
                        in accordance with section six two or in our co-ownership in accordance with section six three
                        against loss and damage due to fire, theft, water and other hazards, and are to provide proof of
                        insurance to us on request. The purchaser hereby assigns his claims for compensation against
                        insurance companies or other obligated parties to which he is entitled – proportionately if
                        necessary – to us. This assignment is also hereby accepted.</li>
                      <li>We must be notified of any impairment of our reserved goods and the goods that are our property
                        in accordance with section six two or in our co-ownership in accordance with section six three,
                        such as third party seizures, without delay.</li>
                      <li>If we take back any purchase items because of reserved proprietary rights, this does not count
                        as withdrawal from the contract. We have the right to satisfy our claims from the free sale of the
                        reserved goods we have taken back.</li>
            </ol>
            <p><br></p>
            <p><strong>7. Payment</strong></p>
            <ol>
              <li>Claims arising from our invoices shall, at our option and unless otherwise agreed, be paid in advance,
                by cash on delivery or by SEPA direct debit. Irrespective of individual contract for goods, payment shall
                be due immediately on collection or delivery of goods.</li>
              <li>We are entitled, despite any different terms of payment of the purchaser, to credit payments against the
                purchaser′s oldest debt first. If any costs and interest have arisen, we are entitled to first credit
                payments against the costs, then the interest, and lastly against the main claim.</li>
              <li>The purchaser is in default even without a reminder or dunning, if he has not paid within 30 days of
                receipt of the invoice. If a reminder, demand or dunning is received, the purchaser is also in default
                before a period of 30 days after receipt of invoice has expired. Default interest is set at 8 per cent
                above the base interest rate at the time. We reserve the right to claim higher interest damages.</li>
              <li>If the purchaser does not comply with his contractual payment obligations, or ceases payment, or if we
                become aware of other circumstances that put the purchaser′s creditworthiness in doubt, we are entitled to
                declare the complete rest amount owing as due. § 321 BGB shall be applied, with the proviso that we are
                still entitled despite the objection foreseen therein, if the purchaser′s financial situation was already
                bad when the contract was concluded, but we were not aware of it.</li>
              <li>The purchaser cannot assert any rights of retention against our claims. The offsetting against any of
                our claims is also ruled out, unless the counterclaim is uncontested or has been established in law.</li>
            </ol>
            <p"><br></p>
              <p><strong>8. Warranty</strong></p>
              <ol>
                <li>Details provided about our goods are generally purely quality descriptions, unless they are explicitly
                  described as assured properties.</li>
                <li>The purchaser must examine the accepted or delivered goods for any faults without delay. Should any
                  such faults be discovered, we must be notified in writing without delay. Otherwise § 377 ff. HGB shall
                  apply. Apart from this, any warranty claims are generally precluded if asa result of further shipping,
                  treatment or processing of the goods delivered by us or other circumstances make it no longer possible
                  for us to properly examine and determine that the goods are in fact faulty.</li>
                <li>Warranty claims will expire by limitation within 12 months, beginning on the date the item was handed
                  over.</li>
                <li>Warranty for any used goods sold is precluded.</li>
                <li>If the purchaser makes a claim against us under warranty and it turns out that there are no grounds
                  for a claim under warranty (e.g. user error, improper handling of the item sold, nonexistence</li>
                <li>of a defect), the purchaser must refund all the costs arising from the examination of the item sold.
                </li>
                <li>In so far as the manufacturer grants the purchaser a voluntary warranty for the goods delivered, the
                  type and scope of the warranty shall exclusively depend on the content of the</li>
                <li>manufacturer′s warranty. Claims under this warranty – in so far as this warranty extends beyond the
                  statutory warranty rights can only be made against the manufacturer.</li>
              </ol>
              <p"><br></p>
                <p><strong>9. Liability</strong></p>
                <ol>
                  <li>In all cases in which we are liable in business transactions based on contractual or statutory basis
                    for claims for damages or expenses, we are only liable in so far as we, our institutions, legal
                    representatives or vicarious agents have acted with deliberate or gross negligence.</li>
                  <li>This does not affect liability for the culpable violation of fundamental contractual obligations.
                    Fundamental contractual obligations are such as those which cede the contract parties rights that the
                    contract must guarantee in view of its content and purpose, especially the obligations whose
                    fulfilment makes the proper execution of the contract possible in the first place and the observance
                    of which the contract parties regularly rely on and place their trust in.</li>
                  <li>As far as an ascribable violation of an obligation is down to simple negligence and there has been
                    no culpable violation of a contractual obligation, our liability for compensation is however limited
                    to the foreseeable damage, which typically occurs in similar cases.</li>
                  <li>The aforementioned exclusions of liability do not apply to cases of homicide, injury to health or
                    body, for guaranteed properties and in the case of any liability in accordance with the German Product
                    Liability Act. In these cases we shall be liable according to the statutory provisions.</li>
                  <li>Any other liability on our part is precluded.</li>
                  <li>The aforementioned disclaimers and limitations of liability apply to the same extent to our
                    institutions, legal representatives, employees and other vicarious agents.</li>
                </ol>
                <p><br></p>
                <p><strong>10. Industrial property rights and copyrights</strong></p>
                <ol>
                  <li>The purchaser will notify us without delay and in writing if he is made aware of any violation of
                    industrial property rights or copyrights by a product supplied by us. We are solely entitled and
                    obliged to defend the purchaser against any claims by the owner of such rights and to deal with such
                    claims at our own expense in so far as they are based on the direct violation by a product supplied by
                    us. We will, as a matter of principle, make every effort to acquire the right of use for the
                    purchaser. If this is not possible on commercially reasonable terms, we shall, atour own discretion,
                    modify or replace this product so that the protective right is not violated or we shall take back the
                    product and refund the purchase price less any amount for the usage granted.</li>
                  <li>On the other hand, the purchaser shall defend us or release us from all claims made by the owners of
                    such rights which are levelled against us, in that we have followed the purchaser′s instructions or
                    because the purchaser altered the product or integrated it into the systems.</li>
                  <li>Any programmes and associated documentation supplied by us are only for the purchaser′s own use
                    within the scope of a simple, non-transferrable license, and exclusively for use on products supplied
                    by us. The purchaser may not make these programmes and documentation available to any third party
                    without our prior written approval, even in the event of resale of our hardware. Copies may – without
                    us accepting any costs or liability – only be made for archiving, replacement, or fault finding
                    purposes. Where originals carry a copyright protection notice, the customer must attach the same to
                    any copy.</li>
                </ol>
                <p><br></p>
                <p><strong>11. Export</strong></p>
                <ol>
                  <li>The export of our goods to non-EU countries requires our written approval, regardless of the fact
                    that the purchaser is solely responsible for obtaining all the necessary official import and export
                    licenses.</li>
                </ol>
                <p>&nbsp;</p>
                <p>ComLine GmbH Flensburg, Germany, August 2020</p>
                <p>&nbsp;</p>
  `,
  privacyProtection: `
  <h3>Privacy policy</h3>
  <h4>I. Preamble</h4>
  <p>
    For us, trustworthy collaboration starts with the little things. Of course, for us, that also includes protecting your data from the very beginning. We would like to inform you about the type, scope and purpose of how your personal data is processed in this privacy policy with respect to data that arises from visits to our website and, among other things, is used to make our website as pleasant as possible for our customers.<br>
    In doing so, we follow the requirements of the European Union’s General Data Protection Regulation (hereinafter: GDPR), the German Federal Data Protection Act (Bundesdatenschutzgesetz, hereinafter: BDSG), and the German Telemedia Act (Telemediengesetz, hereinafter: TMG).
  </p>
  <h4>II. General</h4>
  <h5>a. Controller</h5>
  <p>
    The controller within the meaning of the GDPR is:<br><br>
    ComLine GmbH Value Added Distribution<br>
    Managing Directors: Richard Gäbel, Anja Sobottka, Oliver Bartsch<br>
    Lise-Meitner-Straße 16<br>
    24941 Flensburg<br>
    Tel.: +49 461 77 303 300<br>
    Fax: +49 461 77 303 390<br>
    E-mail: info@comline-shop.de<br>
  </p>
  <h5>b. Data Protection Officer</h5>
  <p>
    If you have any questions about data protection, we will advise you through Datros GmbH. You can contact our external Data Protection Officer at Lise-Meitner-Straße 15, 24941 Flensburg or via the e-mail address info@datros.de.
  </p>
  <h5>c. Terminology</h5>
  <p>
    The terminology used in this privacy policy corresponds with that of the GDPR or the BDSG. The main terminology includes the following:
  </p>
  <ul>
    <li>
      ‘personal data’: any information relating to an identified or identifiable natural person (‘data subject’); an identifiable natural person is a person who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person (Article 4 No. 1 of the GDPR);
    </li>
    <li>
      ‘processing’: any operation or set of operations carried out on personal data or on sets of personal data, whether or not by automated means, such as collection, recording, organisation, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction (Article 4 No. 2 of the GDPR);</li>
    <li>
      ‘third party’ means a natural or legal person, public authority, agency or body other than the data subject, controller, processor and persons who, under the direct authority of the controller or processor, are authorised to process personal data (Article 4 No. 10 of the GDPR); and
    </li>
    <li>
      ‘consent’: with respect to the data subject, any freely given, specific, informed and unambiguous indication of the data subject’s wishes by which he or she, by means of a statement or by a clear affirmative action, signifies agreement to the processing of personal data relating to him or her (Article 4 No. 11 of the GDPR).
    </li>
  </ul>
  <h5>d. Types of data</h5>
  <p>
    We process the following types of data in particular on our website:
  </p>
  <ul>
    <li>
      Inventory data (data required for justification, content design, changes or termination to a contractual relationship; e.g. names and address)
    </li>
    <li>
      liContact details (data via which you or your company is contactable; e.g. e-mail address or telephone number)
    </li>
    <li>
      Content data (content from messages sent; e.g. text entered by you, images, videos)
    </li>
    <li>
      Use data (technical information, e.g. about access times and websites visited)
    </li>
    <li>
      Communication data (data that provides us with information about the communication channel, e.g. the browser you use, device information, and also IP addresses)
    </li>
  </ul>
  <p>The following types of data also arise within the context of business-related processing:</p>
  <ul>
    <li>
      Contract data (contract text and object, term, etc.)
    </li>
    <li>
      Payment details (e.g. bank details and accounting history)
    </li>
  </ul>
  <h5>e. Data subject groups &amp; general purpose of processing</h5>
  <p>
    Personal data is collected from users of this website. Data collected from you is processed to make this website available and to respond to requests. We also collect some data for the purposes of reach measurement and to design our website in a way that is even more attractive for our customers. You can find out what data is specifically collected, on what legal basis this happens, and how we process it, in the specific explanations given in this privacy policy.
  </p>
  <h5>f. Right of withdrawal</h5>
  <p>
    Of course, you have the right to withdraw your consent at any time with future effect, without stating reasons for this, pursuant to Article 7 Paragraph 3 of the GDPR. It is not necessary for withdrawal to be made in a specific form and it will be effective upon receipt, and observed by us, regardless of the medium used. However, we kindly ask that you send the withdrawal to the following e-mail address to make it easier for both you and for us: info@comline-shop.de.
  </p>
  <h5>g. Cookies</h5>
  <p>
    We also use ‘cookies’ on our website. We refer you to the corresponding notice when you first access our website. These are small text files, stored locally on your end device, which can also essentially record data about your visit to the respective website. The general purpose of ‘cookies’ is to recognise a user on a website when they visit again and, by way of example, to save such a user from having to enter information or change the settings again. However, ‘session cookies’ can also be placed on your machine exclusively for the duration of a website visit. As an example, these may be used to ensure that a web shop’s shopping cart function works properly. Such a ‘session cookie’ is automatically removed when your browser session ends. You can find out what cookies are used, what data is recorded and for what purposes the data is used in the explanations below.<br>
    If you generally want to reject the use of cookies, common browsers also offer this as an option. Please note that this may affect the functionality of this website and other websites.<br>
    You also have the option of objecting to cookies being stored by some larger providers for the purposes of online marketing and tracking as a preventative measure. The European website <a href="http://www.youronlinechoices.com" target="_blank" rel="noopener noreferrer">http://www.youronlinechoices.com</a> or the American website <a href="http://www.aboutads.info/choices/" target="_blank" rel="noopener noreferrer">http://www.aboutads.info/choices/</a> provides this as an option.
  </p>
  <h5>h. General objection (to advertising)</h5>
  <p>
    Please note that you do, of course, always have the right to object to the processing of your personal data in accordance with Article 21 of the GDPR, which is unconnected with the visit to our website.<br>
    You also have the right to object to your personal data being processed for the purposes of direct marketing pursuant to Article 21 Paragraph 2 of the GDPR. It is also not necessary for an objection to be made in a specific form and it will be effective upon receipt, and observed by us, regardless of the medium. However, we kindly ask that you send the withdrawal to the following e-mail address to make it easier for both you and for us: info@comline-shop.de.
  </p>
  <h5>i. General erasure and restriction provisions</h5>
  <p>
    We erase or restrict your personal data pursuant to Article 17 and 18 of the GDPR. Data is in particularly erased by us as soon as the purpose for which the data was originally collected no longer applies, provided that there are no statutory retention periods. As an example, we are legally obligated to store trading books, inventories, opening balances, annual financial statements and individual accounts pursuant to Section 257 Paragraph 1 of the German Commercial Code (Handelsgesetzbuch, HGB), and management reports, group financial statements, group management reports, as well as other organisational documentation, commercial letters and accounting vouchers pursuant to Section 325 Paragraph 2a, for a period of 6 years. If there are statutory retention obligations, we restrict the data so that we can only process it for the legally prescribed purpose.
  </p>
  <h5>j. Data security</h5>
  <p>
    To guarantee that your data is secure, we use the widespread Secure Socket Layer process (SSL process) to transmit website content, as well as data entered by you. Data sent between your hosting provider and our hosting provider is encoded using 256 bit encryption. If your browser does not support 256 bit encryption, we use 128 bit v3 technology instead.
  </p>
  <h4>III. Your rights</h4>
  <p>
    The meaning and purpose of our efforts to protect your data is for you to always have full control over the information you provide. As such, in addition to the above-mentioned rights, you also have rights with respect to the personal data recorded by us, which we are pleased to outline below in detail.
  </p>
  <h5>a. Right to obtain confirmation of processing</h5>
  <p>
    Pursuant to Article 15 of the GDPR, you have the right to obtain confirmation from us about the processing of your personal data.
  </p>
  <h5>b. Right of access</h5>
  <p>
    If we process your personal data, you have the right to access the data we have pursuant to Article 15 of the GDPR. We will provide information pursuant to the provisions of Article 15 of the GDPR.
  </p>
  <h5>c. Right to completion &amp; rectification</h5>
  <p>
    Pursuant to Article 16 of the GDPR, you have the right to request that we rectify incorrect personal data relating to you without undue delay. You also have the right to complete incomplete data stored by us.
  </p>
  <h5>d. Right to erasure</h5>
  <p>
    Pursuant to Article 17 of the GDPR, you have the right to request that your personal data is erased without undue delay if one of the following points applies and if no exceptions apply pursuant to Article 17 Paragraphs 2 and 3 of the GDPR:
  </p>
  <ul>
    <li>
      The personal data is no longer required for the purposes for which they were collected or otherwise processed.
    </li>
    <li>
      The data subject withdraws consent on which the processing is based pursuant to Article 6 Paragraph 1 Letter a, or Article 9 Paragraph 2 Letter a, and where there is no other legal basis for processing.
    </li>
    <li>
      The data subject objects to processing pursuant to Article 21 Paragraph 1 and there are no overriding legitimate grounds for processing, or the data subject objects to processing pursuant to Article 21 Paragraph 2.
    </li>
    <li>
      The personal data has been unlawfully processed.
    </li>
    <li>
      The personal data has to be erased to comply with a legal obligation under Union law or Member State law, which the controller is subject to.
    </li>
    <li>
      The personal data has been collected in relation to the offer of information society services referred to in Article 8 Paragraph 1.
    </li>
  </ul>
  <h5>e. Right to restriction of processing</h5>
  <p>
    Pursuant to Article 18 of the GDPR, you have the right to request that the processing of your personal data is restricted without undue delay if one of the following conditions is met:
  </p>
  <ul>
    <li>
      the accuracy of the personal data is contested by the data subject, for a period of time enabling the controller to verify the accuracy of the personal data;
    </li>
    <li>
      processing is unlawful and the data subject opposes the erasure of the personal data and requests that its use is restricted instead;
    </li>
    <li>
      the controller no longer needs the personal data for the purposes of processing, but it is required by the data subject to assert, exercise or defend legal claims; or
    </li>
    <li>
      the data subject has objected to processing pursuant to Article 21 Paragraph 1, pending the verification of whether the legitimate grounds of the controller override those of the data subject.
    </li>
  </ul>
  <p>
    If processing has been restricted under Paragraph 1, such personal data shall, with the exception of storage, only be processed with the data subject’s consent, or to assert, exercise or defend legal claims or to protect the rights of another natural or legal person or for reasons of important public interest of the Union or of a Member State. We will inform you before the restriction is lifted.
  </p>
  <h5>f. Right to data portability</h5>
  <p>
    Pursuant to Article 20 of the GDPR, you have the right to receive personal data concerning you in a structured, commonly used and machine-readable format and to request that it is transmitted to a third party without hindrance. There may be restrictions to this right pursuant to Article 20 of the GDPR.
  </p>
  <h5>g. Right to lodge a complaint</h5>
  <p>
    Pursuant to Article 77 of the GDPR, you also have the right to lodge a complaint to the relevant supervisory authorities. You can contact the relevant authorities at the following address: ULD – Unabhängiges Landeszentrum für Datenschutz Schleswig-Holstein (Independent Centre for Privacy Protection); Holstenstraße 98, 24103 Kiel; Tel.: 0431-9881200: Fax.: 0431-9881223; E-mail: mail@datenschutzzentrum.de.
  </p>
  <h4>IV. Processing for technical reasons</h4>
  <h5>a. Hosting the website</h5>
  <p>
    To be able to make this website available to you, we work with a hosting provider. This provider supplies the infrastructure for the smooth operation of this website and carries out regular maintenance to ensure that your data is not lost. To ensure that your data is protected and that data protection standards are complied with, we have also imposed an obligation on our hosting provider to observe our high data protection standards, by subjecting it to a data processing agreement pursuant to Article 28 of the GDPR. This provider processes inventory, contact, content, contract, use and communication data for users of this website on the basis of our legitimate interests in maintenance and operation, and website security, pursuant to Article 6 Paragraph 1 Letter f of the GDPR.<br>
    Usage data is created every time our website is visited. This particularly includes the name of the website accessed, downloaded files, date and time of access, the respective volume of data transferred, messages about successful website access, the browser type &amp; operating system used, the site previously visited, the IP address and the provider used. This is processed by us or by our hosting provider on behalf of us, and is based on our legitimate interests in protecting against misuse and for security reasons, pursuant to Article 6 Paragraph 1 Letter f of the GDPR. With the exception of personal data that must be further stored for evidence purposes, personal data is stored for a period of 7 days and is then automatically deleted.
  </p>
  <h5>b. Web shop cookies</h5>
  <p>
    To allow you to register in our web shop and for the shopping cart function to work, we place cookies on your end device that assign you a unique identifier. We use the personal data stored by this cookie on the basis of our legitimate interests in a functioning web shop system, pursuant to Article 6 Paragraph 1 Letter f of the GDPR. The cookie is a ‘session cookie’, which automatically becomes invalid and in general is automatically deleted from your browser when you leave our website and close your browser.
  </p>
  <h5>c. Web shop processing</h5>
  <p>
    You can register in our web shop system as a guest or with a user profile account. The data requested by you as part of the entry form is processed for the purpose of processing the ordering of items from our web shop, on the basis of Article 6 Paragraph 1 Letter b of the GDPR. We also send your address details to our mailing service provider for the purposes of mailing.
  </p>
  <h5>d. Contacting us</h5>
  <p>
    If you want to contact us, you can do so via our contact form. We process the data collected there for the purposes of processing your request pursuant to Article 6 Paragraph 1 Letter b of the GDPR. If we stay in touch for business purposes, your personal data may also be processed within the scope of customer relationship management, in a corresponding system. If the data is no longer required to respond to your request, we will delete it, provided that no statutory archiving obligations apply.
  </p>
  <h5>e. Newsletters</h5>
  <p>
    If you also want to stay informed of our current products and information in future, we give you the option of registering for our e-mail newsletter.<br>
    To ensure that third parties do not register using your e-mail address, the registration process is set up as a ‘double opt-in’ process. After you have provided your e-mail address in the corresponding field, and have confirmed registration via the corresponding button, you will receive an e-mail from us containing a link to confirm your registration. By clicking on the link, you are providing your consent to the further use of your personal data required for mailing. For purposes of traceability, we log this registration and the time the registration and confirmation was processed, the IP address and e-mail address given, on the basis of our legitimate interest in providing evidence of registration, pursuant to Article 6 Paragraph 1 Letter f of the GDPR. We use the e-mail address and name given by you to send the newsletter and to personally address it to you. We process this on the basis of your consent pursuant to Article6 Paragraph 1 Letter a, and Article 7 of the GDPR, together with Section 7 Paragraph 2 No. 3 of the German Act Against Unfair Competition (Unlauterer Wettbewerbs-Gesetz, UWG), as well as on the basis of legal permission pursuant to Section 7 Paragraph 3 of the UWG.<br>
    If you no longer want us to send you newsletters in the future, you can unsubscribe at any time. You can find a link to unsubscribe from the newsletter at the end of each newsletter. Of course, you can also simply contact us by e-mail at the following address: Click or type here to enter text.<br>
    After you unsubscribe, we may store your name and e-mail address for up to 3 years, on the basis of our legitimate interests in providing evidence of previous consent given, pursuant to Article 6 Paragraph 1 Letter f of the GDPR. Data is only provided for these purposes and is restricted for use for other purposes.
  </p>
  <h4>V. Using third-party services – technical functionality and information</h4>
  <h5>a. Google Analytics</h5>
  <p>
    We use the service ‘Google Analytics’ from Google on our website, on the basis of our legitimate interests pursuant to Article 6 Paragraph 1 Letter f of the GDPR. This allows us to determine what is particularly relevant for visitors to our website, gives us an overview of visitor numbers, and allows us to design our website in an even more attractive way. This is operated by Google Inc. (1600 Amphitheatre Parkway Mountain View, CA 94043 USA; hereinafter: Google). This service also works without cookies being used. The regional origin of the visitor, their operating system and the type of end device is processed. The cookie also stores information about how you found us – the ‘referring websites’ – as well as landing pages, click paths, bounce rates and the length of stay on individual subpages.<br>
    The information collected by the cookie is sent to a Google server in the USA and processed there. Our website is configured in such a way that your personal data is truncated before it is sent to an area outside the regulatory area of the European Union or another signatory to the Agreement on the European Economic Area, and is therefore anonymised (IP masking). The IP address sent from your browser as part of Google Analytics will not be merged with other Google data.<br>
    Google is also certified under the Privacy Shield agreement and is obligated to comply with European data protection law. Corresponding proof of this status is available at <a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active" target="_blank" rel="noopener noreferrer">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&amp;status=Active</a>. A data processing agreement has also been concluded with Google, which corresponds with the template available at the following link: <a href="http://www.google.com/analytics/terms/de.pdf" target="_blank" rel="noopener noreferrer">http://www.google.com/analytics/terms/de.pdf</a>.<br>
    You can also prevent the information from being collected by downloading and installing the browser plug-in available at the following link <a href="http://tools.google.com/dlpage/gaoptout?hl=de" target="_blank" rel="noopener noreferrer">http://tools.google.com/dlpage/gaoptout?hl=de</a>. In this case, a browser cookie is stored that prevents Google from collecting information on this website and other websites.<br>
    You can find more information about terms of use and data protection by Google at <a href="http://www.google.com/analytics/terms/de.html" target="_blank" rel="noopener noreferrer">http://www.google.com/analytics/terms/de.html</a> and <a href="https://www.google.de/intl/de/policies/" target="blank" rel="noopener noreferrer">https://www.google.de/intl/de/policies/</a>.
  </p>
  <br>
  <a href="javascript:gaOptOut();" onclick="alert('Google Analytics is disabled');"> Disable Google Analytics</a>
  <h5>b. Google reCAPTCHA</h5>
  <p>
    We use the service ‘Google reCAPTCHA’ from Google on our website, on the basis of our legitimate interests in protecting against spam and protecting our form fields from being misused, pursuant to Article 6 Paragraph 1 Letter f of the GDPR. The use of this tool allows us to recognise if people are inputting information into our website or if this is being done via an automated process. For this purpose, content generated by Google is automatically downloaded from Google servers in the form of a small picture puzzle, which you are asked to solve. The original URL, the IP address, the website visitor’s behaviour, information about the operating system used, the browser, length of stay, installed cookies, information about how the website is displayed, and the user’s input and mouse movements in the reCAPTCHA check box is sent to Google when doing so.<br>
    Google uses the data collected to digitalise text, to support image recognition and to improve machine learning. You can find more information about data protection by Google at: <a href="https://policies.google.com/privacy?hl=de" target="_blank" rel="noopener noreferrer">https://policies.google.com/privacy?hl=de</a>.
  </p>
  <h4>VI. Using third-party services – social plug-ins</h4>
  <p>
    Of course, our company is also represented on Facebook, Twitter, YouTube, Vimeo and Instagram. We have included links to social media platforms on our website so that you are able to network with us. We have deliberately avoided using plug-ins for this purpose. Personal data is only sent to the respective website when you click onto that website.
  </p>
  <h5>a. Vimeo plug-in</h5>
  <p>
    We also display videos on our website using a Vimeo plug-in. Vimeo is a service from Vimeo Inc., 555 West 18th Street, NY, New York 10011 (hereinafter: Vimeo). We use Vimeo on the basis of our legitimate interests in our website functioning in an attractive, customer-friendly way, pursuant to Article 6 Paragraph 1 Letter f of the GDPR. If you visit a website that contains a plug-in, your IP address, the website visited by you, and your interaction with this plug-in, is sent to a Vimeo server.<br>
    You can find more information about data protection by Vimeo at <a href="http://vimeo.com/privacy" target="_blank" rel="noopener noreferrer">http://vimeo.com/privacy</a>.
  </p>
  <h4>VII. Validity and changes to this privacy policy</h4>
  <p>
    This privacy policy is valid as at May 2018 and this is the current version at present. However, if we further develop our website and offerings, or if there are any changes to legal guidelines, it may be necessary to change this privacy policy. You can access the respectively applicable privacy policy at <a href="https://www.comline-shop.de/datenschutz/" target="blank" rel="noopener noreferrer">https://www.comline-shop.de/datenschutz/</a>.
  </p>
  `,
  faqQuestions: [
    {
      question: "Can all licenses be transferred directly or only as soon as they are due?",
      answer: `A migration can be carried out at any time. <br />
      If the customer is already in the renewal window and has not yet or only partially renewed the licenses, migration is not possible. In this case, the full extension must first be carried out in the traditional way. <br />
      If there are still open purchase authorizations for the customer, these must also first be ordered in the traditional way. <br />
      No transfer can be made within 14 days of an order (whether it is an extension or an add-on).`,
    },
    {
      question:
        "Can I transfer customers with a 3YC or Enterprise license, or Government or Education customers, to the Adobe Cloud Store?",
      answer: `No, in this case the Adobe Cloud Store issues an error that the transfer of the specified VIP account is not possible. <br />
      These license models and segments will be implemented by Adobe in the coming months.`,
    },
    {
      question: "Does the customer see the Customer ID in his admin console?",
      answer:
        "No, the customer keeps his known VIP number in the event of a transfer. A new VIP number is generated for new customers.",
    },
    {
      question: "Is it possible to book licenses on a monthly or daily basis?",
      answer:
        "No, this function is not yet available. Adobe will however introduce the possibility of monthly booking in the medium term.",
    },
    {
      question: "Does the customer's deadline persist after I transfer them to the Adobe Cloud Store?",
      answer: "Yes, the „Anniversary Date“ is adopted and remains.",
    },
    {
      question: "Has auto-renewal already been activated for transferred customers?",
      answer:
        "Yes, auto-renewal is activated by default for new customers and transferred customers and can be deactivated if desired.",
    },
    {
      question: "Does the customer disappear from the reseller console after the transfer?",
      answer: "Yes, after the transfer the customer is no longer available in the reseller console.",
    },
    {
      question: "How can I determine the prices for new customers who have not yet been created in the Cloud Store?",
      answer:
        "It is currently possible to create offers for existing customers via the shopping cart. A general offer function for the cloud store is planned.",
    },
    {
      question: "How can customers be taken over by other resellers? („Change of Reseller“)",
      answer:
        `If you would like to take over an existing VIP Marketplace customer, please send an email with the customer's VIP number or customer ID to sofa@comline-shop.de and we will take care of the switch.<br />
        If the customer is not yet in the VIP Marketplace, you can transfer them directly to the Adobe Cloud Store after their approval. A previous, "classic" change of reseller in the reseller console is not necessary.`
    },
    {
      question: "Can orders be canceled after they have been placed in the cloud store?",
      answer: "Yes, orders can be canceled directly in the cloud store in the order history within 14 days.",
    },
    {
      question: "Can extensions be booked in advance or later?",
      answer:
        `No, the renewal will be done automatically on the Anniversary Date. If the auto-renewal was not activated, you can initiate a subsequent order using the "Forgot renewal?" function in the customer overview. This is not a classic renewal, but technically a new booking of the licenses, which then have to be reassigned in the admin console.<br />
        If possible, make sure that the auto-renewal is active on the day before the anniversary date.`,
    },
    {
      question:
        "Will there be reminder emails in good time for customers in the cloud store who are waiting for renewal so that they can prepare offers and invoices in advance?",
      answer:
        "Yes, as a reseller you will receive a total of four reminder emails before each renewal (45, 30, 15 and one day before the anniversary date) to inform you punctually about upcoming renewals.",
    },
    {
      question:
        "Will the previous process for the VIP licensing program continue or will something change in the course of the new platform?",
      answer: "There are no changes for the „classic VIP“, here everything stays the same for the time being.",
    },
    {
      question: "What happens if my customer adds a new license via their admin console? Will I be notified?",
      Answer:
        "Yes, if a customer creates a purchase authorization via the admin console, you will receive a notification by e-mail, just like in the classic VIP. This license must then be ordered via the Adobe Cloud Store within seven (7) days.",
    },
    {
      question:
        "Can I create an offer for my customer?",
      answer:
        "Yes, you have the option of creating an offer via \"Create order\" before completing an order. The shopping cart is saved when you exit the window so that you can access it again at a later time and order the license(s). In addition, you have the option of creating an \"offer for renewal\" via the detailed view of a \"customer\".",
    },
  ],
  contactPersons: [
    {
      name: "Marcel Thomsen",
      title: "Head of Business Unit Software",
      phoneNumber: "+49 461 77303-209",
      email: "marcel.thomsen@comline-shop.de",
      image: "marcel-thomsen.jpg",
    },
    {
      name: "Andreas Christensen",
      title: "Business Development Manager Software",
      phoneNumber: "+49 461 77303-202",
      email: "andreas.christensen@comline-shop.de",
      image: "andreas-christensen.jpg",
    },
    {
      name: "Maike Andresen",
      title: "License Specialist",
      phoneNumber: "+49 461 77303-214",
      email: "maike.andresen@comline-shop.de",
      image: "maike-andresen.jpg",
    },
    {
      name: "Thore Hansen",
      title: "Adobe Business Development",
      phoneNumber: "+49 461 77303-215",
      email: "thore.hansen@comline-shop.de",
      image: "thore-hansen.jpg",
    },
  ]
};
