<template>
  <div>
    <div class="bg-light py-4 header-box">
      <b-container class="d-flex align-items-center justify-content-between">
        <div>
          <h3 class="font-weight-bold mb-0">
            {{ $t("createOrder") }}
          </h3>
        </div>
      </b-container>
    </div>
    <b-container class="pt-4">
      <limit-check-component class="mb-4"></limit-check-component>

      <h5 class="font-weight-bold">{{ $t("activeCustomers") }}</h5>
      <div class="text-muted mb-4">{{ $t("activeCustomersCreateOrderInfo") }}</div>
      <b-card no-body>
        <b-card-header class="p-3 d-flex">
          <b-input-group>
            <template #prepend>
              <b-input-group-text class="bg-white pr-0"
                ><fa-icon :icon="['fa-regular', 'fa-magnifying-glass']"></fa-icon
              ></b-input-group-text>
            </template>
            <b-input
              :placeholder="$t('searchByCustomerCustomerId')"
              class="border-left-0"
              debounce="500"
              v-model="filter"
            ></b-input>
          </b-input-group>
          <b-pagination
            class="ml-3 mb-0"
            v-show="rows > perPage"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
          ></b-pagination>
        </b-card-header>
        <b-table
          :items="customers"
          :fields="fields"
          class="mb-0"
          show-empty
          :filter="filter"
          :empty-filtered-text="$t('emptyFilteredText')"
          :empty-text="$t('noCustomers')"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          sort-icon-left
          :per-page="perPage"
          :current-page="currentPage"
          :no-provider-filtering="true"
          :no-provider-paging="true"
          :no-provider-sorting="true"
        >
          <template #empty="scope">
            <div class="text-center font-italic">{{ scope.emptyText }}</div>
          </template>

          <template #emptyfiltered="scope">
            <div class="text-center font-italic">
              {{ scope.emptyFilteredText }}
            </div>
          </template>

          <template #cell(companyName)="data">
            <b-button
              variant="link"
              class="p-0 d-inline-flex align-items-center"
              v-on:click="selectCustomer(data.item, data.index)"
            >
              <span class="mr-2">{{ data.item.companyProfile.companyName }}</span>
              <b-spinner
                v-show="showSpinnerForIndex === data.index"
                label="Spinning"
                variant="secondary"
                small
              ></b-spinner>
            </b-button>
            <b-badge class="ml-1" style="display: inline;" variant="dark" v-if="threeYearsCommitStatus(data.item.benefits)">3YC</b-badge>
            <b-badge class="ml-1" style="display: inline;" variant="secondary" v-if="data.item.companyProfile.marketSegment">{{ data.item.companyProfile.marketSegment}}</b-badge>
            <div>
              <small class="text-muted" v-b-tooltip.hover :title="$t('customerId')">
                <fa-icon :icon="['fa-regular', 'fa-id-card']" class="mr-1"></fa-icon>{{ data.item.customerId }}
              </small>
            </div>
          </template>
        </b-table>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import moment from "moment-timezone";
import LimitCheckComponent from "../components/LimitCheckComponent.vue";
import { functions, firestore } from "../plugins/firebase";
import { httpsCallable } from "firebase/functions";
import { collection, where, getDocs, query, addDoc } from "firebase/firestore";

export default {
  components: { LimitCheckComponent },
  name: "CreateOrderSelectCustomerView",
  data() {
    return {
      rows: 0,
      perPage: 25,
      currentPage: 1,
      filter: "",
      sortBy: "companyName",
      sortDesc: false,
      customersFilter: "",
      fields: [
        {
          key: "companyName",
          label: this.$t("customer"),
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
          formatter: (value, key, item) => {
            return item.companyProfile.companyName;
          },
        },
      ],
      customers: [],
      orderToBeDeleted: null,
      showSpinnerForIndex: null,
      contentClass: ["border-0 shadow-lg"],
      footerClass: ["border-0 bg-light p-4"],
      bodyClass: ["p-4"],
    };
  },
  async mounted() {
    this.customers = await this.getCustomers();
    this.rows = this.customers.length;
  },
  methods: {
    async getCustomers() {
      const colRef = query(
        collection(firestore, "customers"),
        where("resellerId", "==", this.$store.state.resellerData.resellerId),
        where("status", "in", ["1000", "1002"])
      );
      const customers = await getDocs(colRef);

      const result = [];

      if (!customers.empty) {
        customers.forEach((customer) => {
          result.push(customer.data());
        });
      }

      return result;
    },
    onFiltered(filteredItems) {
      this.rows = filteredItems.length;
      this.currentPage = 1;
    },
    async selectCustomer(customer, index) {
      try {
        this.showSpinnerForIndex = index;

        const getCustomerAccount = httpsCallable(functions, "customerAccount");
        const response = await getCustomerAccount({ customerId: customer.customerId });
        const { data } = response;

        console.log(data);

        data.cotermDate = this.updateCotermDate(data.cotermDate);

        const docRef = await addDoc(collection(firestore, "orders"), {
          finished: false,
          customer: data,
          reseller: {
            companyProfile: this.$store.state.resellerData.companyProfile,
            resellerId: this.$store.state.resellerData.resellerId,
            customernumber: this.$store.state.resellerData.customernumber,
          },
          deluxeOrder: null,
          creationDate: moment().tz("Europe/Berlin").format(),
          ttl: moment().tz("Europe/Berlin").add(7, "days").toDate(),
        });

        this.$router.replace({
          name: "CreateOrderSelectProductsView",
          params: {
            orderId: docRef.id,
          },
        });
      } catch (error) {
        console.error(error);
        this.$store.dispatch("alertError", {
          message: "Die Daten konnten nicht abgerufen werden.",
          show: true,
          noFooter: false,
        });
      }
    },
    updateCotermDate(cotermDate) {
      const todayMoment = moment.tz("America/Los_Angeles");
      let cotermMoment = cotermDate ? moment(cotermDate) : todayMoment.clone().add(1, "years");

      if (cotermMoment.isSameOrBefore(todayMoment, "day")) {
        const thisYear = todayMoment.format("YYYY");
        const cotermDateDayAndMonth = cotermMoment.format("MM-DD");
        cotermMoment = moment(`${thisYear}-${cotermDateDayAndMonth}`);

        if (cotermMoment.isSameOrBefore(todayMoment, "day")) {
          cotermMoment.add(1, "years");
        }
      }

      return cotermMoment.format("YYYY-MM-DD");
    },
    threeYearsCommitStatus(benefits) {
      let status
      const threeYC = benefits.filter((d) => d.type === "THREE_YEAR_COMMIT") || false;
      status = threeYC[0]?.commitment?.status

      return status === "COMMITTED"
    }
  },
};
</script>
