<template>
  <b-card header-tag="header" id="upcoming-renewals-component" header-bg-variant="primary" header-text-variant="white">
    <template #header>
      <h6 class="mb-0">
        <fa-icon :icon="['fa-regular', 'fa-calendar-circle-exclamation']" class="mr-2 text-white"></fa-icon
        >{{ $t("upcomingRenewals") }}
      </h6>
    </template>

    <b-calendar
      v-model="selectedDate"
      :date-info-fn="dateClass"
      block
      hide-header
      no-key-nav
      label-help=""
      :max="max"
      :min="min"
      :locale="$i18n.locale"
      start-weekday="1"
    ></b-calendar>

    <div class="mt-3" v-if="renewals.length > 0" id="renewal-list">
      <p class="font-weight-bold">{{ selectedDate | date }} </p>
      <div v-for="(item, index) in renewals" :key="`item-${index}`" class="mb-3 d-flex flex-column border-bottom pb-3 renewal-item">
        <b-link :to="{ name: 'CustomerSubscriptionsView', params: { customerId: item.customerId } }">{{ item.companyProfile.companyName }}</b-link>
        <small class="text-muted">{{ item.customerId }}</small>
      </div>
    </div>
    <div class="mt-3 text-center" v-else v-html="$t('noUpcomingRenewals', { date: $options.filters.date(selectedDate)})"></div>
  </b-card>
</template>

<script>
import moment from "moment-timezone";

export default {
  name: "UpcomingRenewalsComponent",
  data() {
    return {
      selectedDate: moment().format("YYYY-MM-DD"),
      renewals: [],
      renewalDates: [],
    };
  },
  props: {
    customers: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    dateClass(ymd) {
      return this.renewalDates.map((renewalDate)  => renewalDate === ymd ? "table-primary" : "");
    },
    filterCustomers(date) {
      this.renewals = [];
      this.customers.forEach((docData) => {
        if (docData.cotermDate === date) {
          this.renewals.push(docData);
        }
        this.renewalDates.push(docData.cotermDate);
      });
    }
  },
  computed: {
    min: function () {
      return moment().format("YYYY-MM-DD");
    },
    max: function () {
      return moment().add(1, "years").format("YYYY-MM-DD");
    },
  },
  mounted() {
    this.filterCustomers(this.selectedDate);
  },
  watch: {
    selectedDate: function(date) {
      this.filterCustomers(date);
    },
  },
};
</script>

<style scoped>
.renewal-item:last-of-type {
  margin-bottom: 0 !important;
  border-bottom: 0 !important;
  padding-bottom: 0 !important;
}
#renewal-list {
  max-height: 240px;
  overflow-y: auto;
}
</style>
