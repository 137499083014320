<template>
  <div class="py-4">
    <b-container class="h-100">
      <b-row class="h-100 justify-content-center align-items-center">
        <b-col xl="6">
          <b-card class="shadow-lg border-top border-bottom-0 border-left-0 border-right-0 border-primary" body-class="p-5">
            <div class="text-center d-flex align-items-center justify-content-center h2 mb-5">
              <logo-component
                :fill="'#212529'"
                :height="'40px'"
                :width="'40px'"
                class="h2"
                :lineBreak="true"
              >
                Adobe Cloud Store
              </logo-component>
            </div>
            <b-form v-on:submit.prevent="checkCode">
              <h3>Reseller genehmigen</h3>
              <p class="text-muted">
                Auf dieser Seite kannst du dem Reseller den Zugang zum Adobe Cloud Store genehmigen.
              </p>
              <p class="text-muted">
                <strong class="text-body">Tipp:</strong> Falls du dem Reseller keine Genehmigung erteilen möchtest, brauchst du nichts zu tun. Die Registrierung wird nach 14 Tagen automatisch gelöscht.
              </p>
              <h4 class="mt-5">Reseller</h4>
              <hr />
              <b-row>
                <b-col xl="5" class="d-flex align-items-center">
                  <label class="mb-0">Firma</label>
                </b-col>
                <b-col>
                  <b-form-input type="text" :value="companyName" disabled />
                </b-col>
              </b-row>
              <hr />
              <b-row class="mb-5">
                <b-col xl="5" class="d-flex align-items-center">
                  <label class="mb-0">Kundennummer</label>
                </b-col>
                <b-col>
                  <b-form-input type="text" :value="customernumber" disabled />
                </b-col>
              </b-row>
              <h4 class="mt-5">Sicherheitsabfrage</h4>
              <hr />
              <b-row class="mb-5">
                <b-col xl="5" class="d-flex flex-column justify-content-center">
                  <label class="mb-0">Genehmigungscode</label>
                  <small class="text-muted">Steht in der E-Mail</small>
                </b-col>
                <b-col>
                  <b-form-input type="text" v-model="confirmationCode" required trim />
                </b-col>
              </b-row>

              <submit-button-component :state="submitButtonState" :label="'Genehmigen'" :block="true"></submit-button-component>
            </b-form>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import SubmitButtonComponent from "../components/SubmitButtonComponent.vue";

import LogoComponent from "../components/LogoComponent.vue";
import moment from "moment-timezone";
import { firestore } from "../plugins/firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";

export default {
  name: "ConfirmResellerView",
  components: {
    SubmitButtonComponent,
    LogoComponent,
  },
  computed: {
    submitButtonState() {
      if (this.isWorking === true) {
        return "working";
      }

      return "";
    },
  },
  data() {
    return {
      isWorking: false,
      confirmationCode: "",
      companyName: "",
      customernumber: "",
    };
  },
  mounted() {
    this.fetchRegistration()
    
  },
  methods: {
    async fetchRegistration() {
      const docRef = doc(firestore, "registrations", this.$route.params.registrationId);
      const registration = await getDoc(docRef);

      if (!registration.exists()) {
        this.$store.dispatch("alertError", {
          message: "Es wurde keine Registrierung gefunden.",
          show: true,
          noFooter: false,
        })
        return; 
      }

      const registrationData = registration.data()

      this.customernumber =  registrationData.account.customernumber
      this.companyName =  registrationData.companyProfile.companyName
    },
    async confirmReseller() {
      this.isWorking = true;
      const docRef = doc(firestore, "registrations", this.$route.params.registrationId);
      await updateDoc(docRef, {
        "account.confirmedByDistributorDate": moment().tz("Europe/Berlin").format(),
      });

      this.$store.dispatch("alertSuccess", {
        message: "Vielen Dank.<br /><br />Der Reseller wird informiert.",
        show: true,
        noFooter: false,
      })
      this.isWorking = false;  
      this.confirmationCode = "";
    },
    async checkCode() {
      const docRef = doc(firestore, "registrations", this.$route.params.registrationId);
      const registration = await getDoc(docRef);

      if (!registration.exists()) {
        this.$store.dispatch("alertError", {
          message: "Bei der Prüfung des Genehmigungscodes ist ein Fehler aufgetreten.",
          show: true,
          noFooter: false,
        })
        return; 
      }
    
      const registrationData = registration.data()

      if (registrationData.confirmationCode === this.confirmationCode) {
        this.confirmReseller()
      } else {
        this.$store.dispatch("alertError", {
          message: "Der Genehmigungscode ist falsch.<br /><br />Du findest ihn in der E-Mail, mit dem Link zu dieser Seite.",
          show: true,
          noFooter: false,
        })
        this.confirmationCode = "";
      }
    }
  },
};
</script>

<style scoped>
.border-top {
  border-width: 5px !important;
}
</style>
