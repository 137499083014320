<template>
  <div>
    <div class="bg-light py-4 header-box">
      <b-container class="d-flex align-items-center justify-content-between">
        <h3 class="font-weight-bold mb-0">
          {{ $t("editLoginCredentials") }}
        </h3>
      </b-container>
    </div>
    <b-container class="pt-4">
      <div class="mb-4 text-muted" v-html="$t('editLoginCredentialsText')"></div>
      <reseller-login-email-component class="mb-4"></reseller-login-email-component>
      <reseller-login-password-component></reseller-login-password-component>
    </b-container>
  </div>
</template>

<script>
import ResellerLoginEmailComponent from "../components/ResellerLoginEmailComponent.vue";
import ResellerLoginPasswordComponent from "../components/ResellerLoginPasswordComponent.vue";

export default {
  components: { ResellerLoginEmailComponent, ResellerLoginPasswordComponent },
  name: "ResellerEditLoginCredentialsView",
};
</script>
