<template>
  <b-form v-on:submit.prevent="updateResellerLoginPassword" class="mb-4">
    <b-card footer-class="border-0 bg-light">
      <b-row>
        <b-col class="d-flex align-items-center">
          <label class="mb-0">{{ $t("newPassword") }}</label>
        </b-col>
        <b-col>
          <b-popover target="input-password" triggers="hover" placement="top">
            <template #title>{{ $t("requirements") }}</template>
            <span v-html="$t('requirementsText')"></span>
          </b-popover>
          <b-form-input
            id="input-password"
            type="password"
            v-model="newPassword"
            :state="states.newPassword"
            aria-describedby="input-password-feedback"
            required
            trim
          />
          <b-form-invalid-feedback id="input-password-feedback">
            {{ $t("passwordInsecure") }}
          </b-form-invalid-feedback>
        </b-col>
      </b-row>

      <hr />
      
      <b-row>
        <b-col class="d-flex align-items-center">
          <label class="mb-0">{{ $t("confirmNewPassword") }}</label>
        </b-col>
        <b-col>
          <b-form-input
            id="input-password-confirm"
            type="password"
            v-model="confirmNewPassword"
            :state="states.confirmNewPassword"
            aria-describedby="input-password-confirm-feedback"
            trim
            required
          />
          <b-form-invalid-feedback id="input-password-confirm-feedback">
            {{ $t("passwordsNotIdentical") }}
          </b-form-invalid-feedback>
        </b-col>
      </b-row>

      <template #footer>
        <div class="d-flex justify-content-end">
          <submit-button-component
            :state="submitButtonState"
            :label="$t('save')"
            :block="false"
            class="my-2"
          ></submit-button-component>
        </div>
      </template>
    </b-card>
  </b-form>
</template>

<script>
import SubmitButtonComponent from "../components/SubmitButtonComponent.vue";
import isStrongPassword from "validator/es/lib/isStrongPassword";
import equals from "validator/es/lib/equals";
import { auth } from "../plugins/firebase"
import { updatePassword } from "firebase/auth";

export default {
  name: "ResellerLoginPasswordComponent",
  components: {
    SubmitButtonComponent,
  },
  data() {
    return {
      isWorking: false,
      missingFields: true,
      newPassword: "",
      confirmNewPassword: "",
      states: {
        newPassword: null,
        confirmNewPassword: null,
      },
    };
  },
  computed: {
    submitButtonState() {
      if (!this.states.newPassword || !this.states.confirmNewPassword) {
        return "missingFields";
      }

      if (this.isWorking === true) {
        return "working";
      }

      return "";
    },
  },
  methods: {
    updateResellerLoginPassword() {
      this.isWorking = true;

      updatePassword(auth.currentUser, this.newPassword)
        .then(() => {
          this.newPassword = "";
          this.confirmNewPassword = "";
          this.isWorking = false
          this.$store.dispatch("alertSuccess", {
            message: "Das Passwort wurde geändert.",
            show: true,
            noFooter: false,
          })
        })
        .catch((error) => {
          this.isWorking = false;
          this.newPassword = "";
          this.confirmNewPassword = "";
          this.$store.dispatch("alertError", {
            message: error.message,
            show: true,
            noFooter: false,
          });
        });
    },
  },
  watch: {
    newPassword(password) {
      if (password === "") {
        this.states.newPassword = null;
      } else {
        this.states.newPassword = isStrongPassword(password, {
          minLength: 8,
          minLowercase: 2,
          minUppercase: 2,
          minNumbers: 2,
          minSymbols: 0,
        });
      }
    },
    confirmNewPassword(password) {
      if (password === "") {
        this.states.confirmNewPassword = null;
      } else {
        this.states.confirmNewPassword = equals(password, this.newPassword);
      }
    },
  },
};
</script>
