<template>
  <b-card header-tag="header" id="adobe-release-timeline-component">
    <template #header>
      <h6 class="mb-0"><fa-icon :icon="['fa-regular','fa-box-check']" class="mr-2 text-primary"></fa-icon>{{ $t("vipmpReleaseTimeline") }}</h6>
    </template>

    <b-card-text>
      <div v-for="(item, index) in releases" :key="`item-${index}`" class="release-item">
        <div>{{ item[$i18n.locale].date }}</div>
        <div class="divider"></div>
        <div>{{ item[$i18n.locale].title }}</div>
      </div>
    </b-card-text>
  </b-card>
</template>

<script>
export default {
  name: "ReleaseTimelineComponent",
  data() {
    return {
      releases: [
        {
          en: {
            date: "FY24 Q4",
            title: "Linked Membership",
          },
          de: {
            date: "FY24 Q4",
            title: "Linked Membership",
          }
        },
        {
          en: {
            date: "FY25 Q1",
            title: "High Volume Discount, End of Sales VIP Commercial",
          },
          de: {
            date: "FY25 Q1",
            title: "High Volume Discount, End of Sales VIP Commercial",
          }
        },
        {
          en: {
            date: "FY25 Q4",
            title: "End of Sales VIP Government & Education",
          },
          de: {
            date: "FY25 Q4",
            title: "End of Sales VIP Government & Education",
          }
        },
        {
          en: {
            date: "FY25 and beyond",
            title: "Offer tool, improvements and more",
          },
          de: {
            date: "FY25 und darüber hinaus",
            title: "Angebotstool, Verbesserungen und mehr",
          }
        },
      ]
    }
  }
}
</script>

<style scoped>
.release-item {
  display: flex;
  align-items: center;
  position: relative;
  padding-bottom: 10px;
}
.release-item:last-of-type {
  padding-bottom: 0;
}
.release-item div:first-of-type {
  margin-right: 25px;
  flex-shrink: 0;
  width: 73px;
}
.release-item div:last-of-type {
  margin-left: 50px;
}
.release-item .divider::after {
  content: "";
  top: 0;
  position: absolute;
  background-color: #d9d9d9;
  width: 5px;
  height: 100%;
}
.release-item .divider::before {
  content: "";
  position: absolute;
  background-color: #d9d9d9;
  width: 25px;
  height: 5px;
  transform: translate(0, -50%);
}
</style>