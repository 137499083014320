<template>
  <b-form v-on:submit.prevent="submit">
    <b-card footer-class="border-0 bg-light">
      <b-row>
        <b-col class="d-flex flex-column justify-content-center">
          <label class="mb-0">{{ $t("emailLabel") }}</label>
        </b-col>
        <b-col>
          <b-form-input v-model="email" required trim></b-form-input>
        </b-col>
      </b-row>

      <template #footer>
        <div class="d-flex justify-content-end">
          <submit-button-component
            :state="submitButtonState"
            :label="$t('save')"
            :block="false"
            class="my-2"
          ></submit-button-component>
        </div>
      </template>
    </b-card>
  </b-form>
</template>

<script>
import SubmitButtonComponent from "../components/SubmitButtonComponent.vue";
import isEmail from "validator/es/lib/isEmail";
import { functions } from "../plugins/firebase"
import { httpsCallable } from "firebase/functions";

export default {
  name: "ResellerLoginEmailComponent",
  components: {
    SubmitButtonComponent,
  },
  data() {
    return {
      isWorking: false,
      missingFields: true,
      email: this.$store.state.resellerData.email,
      states: {
        email: null,
      },
    };
  },
  computed: {
    submitButtonState() {
      if (!this.states.email) {
        return "missingFields";
      }

      if (this.isWorking === true) {
        return "working";
      }

      return "";
    },
  },
  methods: {
    submit() {
      this.isWorking = true;
      const sendEmailChangeConfirmation = httpsCallable(functions, "sendEmailChangeConfirmation");
      sendEmailChangeConfirmation({
        recipientEmail: this.$store.state.resellerData.email,
        preferredLanguage: this.$store.state.resellerData.companyProfile.preferredLanguage,
        newEmail: this.email,
      })
        .then(() => {
          this.isWorking = false;
          this.$store.dispatch("alertSuccess", {
            message: "Überprüfen Sie den Posteingang Ihrer aktuellen E-Mail-Adresse.",
            show: true,
            noFooter: false,
          })
        })
        .catch(error => {
          this.isWorking = false;
          this.$store.dispatch("alertError", {
            message: error,
            show: true,
            noFooter: false,
          })
        });
    },
  },
  watch: {
    email(email) {
      if (email === "") {
        this.states.email = null;
      } else if (this.$store.state.resellerData.email === email) {
        this.states.email = false;
      } else {
        this.states.email = isEmail(email);
      }
    },
  },
};
</script>
