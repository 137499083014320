<template>
  <b-alert show fade variant="warning" v-if="showBanner">
    <span
      v-html="
        $t('limitWarning', {
          availableLimit: limit,
        })
      "
    ></span>
  </b-alert>
</template>

<script>
import { functions } from "../plugins/firebase";
import { httpsCallable } from "firebase/functions";
import i18n from "../plugins/i18n";

export default {
  name: "LimitCheck",
  data() {
    return {
      showBanner: false,
      limit: null,
    };
  },
  methods: {
    checkLimit() {
      const checkLimitFunction = httpsCallable(functions, "checkCustomerLimit");
      checkLimitFunction()
        .then((res) => {
          const data = res.data;
          if (Number(data.limit_offenepostenbrutto - data.offene_rechnungen) <= 500 && !data.partnerStore) {
            this.showBanner = true;
            const limit = Number((data.limit_offenepostenbrutto - data.offene_rechnungen).toFixed(2));

            this.limit = limit.toLocaleString(i18n.locale.toUpperCase(), { style: "currency", currency: "EUR" });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
  mounted() {
    this.checkLimit();
  },
};
</script>
