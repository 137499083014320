<template>
  <b-form-select v-model="selected">
    <b-form-select-option v-for="(element, index) in options" :key="`option-${index}`" :value="element.value">{{ element.text[activeTranslation] }}</b-form-select-option>
  </b-form-select>
</template>

<script>
export default {
  name: "PreferredLanguageDropdownComponent",
  props: {
    preferredLanguage: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      selected: "de-DE",
      activeTranslation: this.$i18n.locale,
      options: [
        {
          "value": "bg-BG",
          "text": {
            "de": "Bulgarisch (Bulgarien)",
            "en": "Bulgarian (Bulgaria)"
          }
        },
        {
          "value": "cs-CZ",
          "text": {
            "de": "Tschechisch (Tschechien)",
            "en": "Czech (Czech Republic)"
          }
        },
        {
          "value": "da-DK",
          "text": {
            "de": "Dänisch (Dänemark)",
            "en": "Danish (Denmark)"
          }
        },
        {
          "value": "de-DE",
          "text": {
            "de": "Deutsch (Deutschland)",
            "en": "German (Germany)"
          }
        },
        {
          "value": "en-AE",
          "text": {
            "de": "Englisch (Vereinigte Arabische Emirate)",
            "en": "English (United Arab Emirates)"
          }
        },
        {
          "value": "en-GB",
          "text": {
            "de": "Englisch (Vereinigtes Königreich)",
            "en": "English (United Kingdom)"
          }
        },
        {
          "value": "en-IL",
          "text": {
            "de": "Englisch (Israel)",
            "en": "English (Israel)"
          }
        },
        {
          "value": "en-US",
          "text": {
            "de": "Englisch (Vereinigte Staaten)",
            "en": "English (United States)"
          }
        },
        {
          "value": "es-ES",
          "text": {
            "de": "Spanisch (Spanien)",
            "en": "Spanish (Spain)"
          }
        },
        {
          "value": "es-LA",
          "text": {
            "de": "Spanisch (Lateinamerika)",
            "en": "Spanish (Latin America)"
          }
        },
        {
          "value": "es-MX",
          "text": {
            "de": "Spanisch (Mexiko)",
            "en": "Spanish (Mexico)"
          }
        },
        {
          "value": "es-NA",
          "text": {
            "de": "Spanisch (Nordamerika)",
            "en": "Spanish (North America)"
          }
        },
        {
          "value": "et-EE",
          "text": {
            "de": "Estnisch (Estland)",
            "en": "Estonian (Estonia)"
          }
        },
        {
          "value": "fi-FI",
          "text": {
            "de": "Finnisch (Finnland)",
            "en": "Finnish (Finland)"
          }
        },
        {
          "value": "fr-CA",
          "text": {
            "de": "Französisch (Kanada)",
            "en": "French (Canada)"
          }
        },
        {
          "value": "fr-FR",
          "text": {
            "de": "Französisch (Frankreich)",
            "en": "French (France)"
          }
        },
        {
          "value": "fr-MA",
          "text": {
            "de": "Französisch (Marokko)",
            "en": "French (Morocco)"
          }
        },
        {
          "value": "hr-HR",
          "text": {
            "de": "Kroatisch (Kroatien)",
            "en": "Croatian (Croatia)"
          }
        },
        {
          "value": "hu-HU",
          "text": {
            "de": "Ungarisch (Ungarn)",
            "en": "Hungarian (Hungary)"
          }
        },
        {
          "value": "it-IT",
          "text": {
            "de": "Italienisch (Italien)",
            "en": "Italian (Italy)"
          }
        },
        {
          "value": "ja-JP",
          "text": {
            "de": "Japanisch (Japan)",
            "en": "Japanese (Japan)"
          }
        },
        {
          "value": "ko-KR",
          "text": {
            "de": "Koreanisch (Südkorea)",
            "en": "Korean (South Korea)"
          }
        },
        {
          "value": "lt-LT",
          "text": {
            "de": "Litauisch (Litauen)",
            "en": "Lithuanian (Lithuania)"
          }
        },
        {
          "value": "lv-LV",
          "text": {
            "de": "Lettisch (Lettland)",
            "en": "Latvian (Latvia)"
          }
        },
        {
          "value": "nb-NO",
          "text": {
            "de": "Norwegisch (Norwegen)",
            "en": "Norwegian (Norway)"
          }
        },
        {
          "value": "nl-NL",
          "text": {
            "de": "Niederländisch (Niederlande)",
            "en": "Dutch (Netherlands)"
          }
        },
        {
          "value": "pl-PL",
          "text": {
            "de": "Polnisch (Polen)",
            "en": "Polish (Poland)"
          }
        },
        {
          "value": "pt-BR",
          "text": {
            "de": "Portugiesisch (Brasilien)",
            "en": "Portuguese (Brazil)"
          }
        },
        {
          "value": "ro-RO",
          "text": {
            "de": "Rumänisch (Rumänien)",
            "en": "Romanian (Romania)"
          }
        },
        {
          "value": "ru-RU",
          "text": {
            "de": "Russisch (Russland)",
            "en": "Russian (Russia)"
          }
        },
        {
          "value": "sk-SK",
          "text": {
            "de": "Slowakisch (Slowakei)",
            "en": "Slovak (Slovakia)"
          }
        },
        {
          "value": "sl-SI",
          "text": {
            "de": "Slowenisch (Slowenien)",
            "en": "Slovenian (Slovenia)"
          }
        },
        {
          "value": "sr-RS",
          "text": {
            "de": "Serbisch (Serbien)",
            "en": "Serbian (Serbia)"
          }
        },
        {
          "value": "sv-SE",
          "text": {
            "de": "Schwedisch (Schweden)",
            "en": "Swedish (Sweden)"
          }
        },
        {
          "value": "tr-TR",
          "text": {
            "de": "Türkisch (Türkei)",
            "en": "Turkish (Turkey)"
          }
        },
        {
          "value": "uk-UA",
          "text": {
            "de": "Ukrainisch (Ukraine)",
            "en": "Ukrainian (Ukraine)"
          }
        },
        {
          "value": "zh-CN",
          "text": {
            "de": "Chinesisch (China)",
            "en": "Chinese (China)"
          }
        },
        {
          "value": "zh-HANS",
          "text": {
            "de": "Chinesisch (Vereinfacht)",
            "en": "Chinese (Simplified)"
          }
        },
        {
          "value": "zh-HANT",
          "text": {
            "de": "Chinesisch (Traditionell)",
            "en": "Chinese (Traditional)"
          }
        },
        {
          "value": "zh-TW",
          "text": {
            "de": "Chinesisch (Taiwan)",
            "en": "Chinese (Taiwan)"
          }
        }
      ]
    };
  },
  watch: {
    selected(selected) {
      this.$emit("languageSelected", selected)
    },
    preferredLanguage(preferredLanguage) {
      this.selected = preferredLanguage
    },
  },
  mounted() {
    this.selected = this.preferredLanguage
  }
};
</script>
