<template>
  <div>
    <p class="text-muted" v-html="$t('extendedTermDescription')"></p>
    <b-form v-on:submit.prevent="updateCustomerAccount">
      <b-form-group 
        :description="$t('anniversaryDateCannotBeChanged')"
        :label="$t('anniversaryDate')">
        <b-form-datepicker v-model="cotermDate" :min="minDate" :max="maxDate" :label-no-date-selected="$t('noAnniversaryDateSelected')" reset-button></b-form-datepicker>
      </b-form-group>
      <submit-button-component :state="submitButtonState" :label="$t('save')" :block="false" :variant="'dark'"></submit-button-component>
    </b-form>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import { functions } from "../plugins/firebase"
import { httpsCallable } from "firebase/functions";
import SubmitButtonComponent from "../components/SubmitButtonComponent.vue";

export default {
  name: "CustomerExtendedTermView",
  components: {
    SubmitButtonComponent,
  },
  props: {
    customer: {
      type: Object,
      default: () => {
        return null;
      },
    },
  },
  computed: {
    minDate() {
      return moment().add(1, 'year').add(1, 'day').format('YYYY-MM-DD');
    },
    maxDate() {
      return moment().add(3, 'years').format('YYYY-MM-DD');
    },
    submitButtonState() {
      if (
        this.cotermDate === ""
      ) {
        return "missingFields";
      }

      if (this.isWorking === true) {
        return "working";
      }

      return "";
    },
  },
  data() {
    return {
      cotermDate: "",
    };
  },
  methods: {
    async updateCustomerAccount() {
      this.isWorking = true;

      const updateCustomerAccount = httpsCallable(functions, "updateCustomerAccount");
      await updateCustomerAccount({
        customerId: this.customer.customerId,
        profile: {
          cotermDate: this.cotermDate,
          companyProfile: this.customer.companyProfile,
        },
      })
        .then(() => {
          this.$store.dispatch("alertSuccess", {
            message: "Der Stichtag wurde gespeichert.",
            show: true,
            noFooter: false,
          })
        })
        .catch((error) => {
          this.$store.dispatch("alertError", {
            message: error.message,
            show: true,
            noFooter: false,
          })
        });

        this.isWorking = false;
    },
  },
};
</script>
