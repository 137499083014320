<template>
  <b-alert class="mt-4 mb-0 d-flex justify-content-between align-items-center" variant="warning" show>
    <div v-html="$t('resendInvitationText')"></div>
    <submit-button-component
      v-on:click="resendInvitation()"
      :state="submitButtonState"
      :label="$t('resendInvitation')"
      :block="false"
      :variant="'warning'"
    ></submit-button-component>
  </b-alert>
</template>

<script>
import { functions } from "../plugins/firebase"
import { httpsCallable } from "firebase/functions";
import SubmitButtonComponent from './SubmitButtonComponent.vue';

export default {
  name: "ResendCustomerInvitationComponent",
  components: { SubmitButtonComponent },
  props: {
    profile: {
      type: Object,
      default: () => {
        return null;
      },
    },
  },
  data() {
    return {
      isWorking: false,
    };
  },
  computed: {
    submitButtonState() {
      if (this.isWorking === true) {
        return "working";
      }

      return "";
    },
  },
  methods: {
    async resendInvitation() {
      this.isWorking = true;

      const updateCustomerAccount = httpsCallable(functions, "updateCustomerAccount");
      await updateCustomerAccount({
        customerId: this.profile.customerId,
        profile: {
          companyProfile: {
            companyName: this.profile.companyProfile.companyName,
            preferredLanguage: this.profile.companyProfile.preferredLanguage,
            address: this.profile.companyProfile.address,
            contacts: this.profile.companyProfile.contacts,
          },
        },
      })
        .then(() => {
          this.$store.dispatch("alertSuccess", {
            message: "Die Einladung wurde erneut versendet.",
            show: true,
            noFooter: false,
          })
        })
        .catch(() => {
          this.$store.dispatch("alertError", {
            message: "Die Daten konnten nicht gespeichert werden.",
            show: true,
            noFooter: false,
          })
        });

        this.isWorking = false;
    }
  },
}
</script>