<template>
  <b-alert variant="info" class="" :show="!isLoading && openAcquisitions.unpaidLicenses.length > 0">
    <div v-if="!isLoading && openAcquisitions.unpaidLicenses.length > 0" class="">
      <div class="">
        <h5>{{$t("purchaseRequested")}} <fa-icon
        :icon="['fa-regular', 'fa-circle-info']"
        v-b-tooltip.hover="$t('customerRequestedPurchaseInfo')"
      /></h5>
        <!-- <p>{{$t("customerRequestedPurchaseInfo")}}</p> -->
      </div>
      <hr>
      <div class="d-flex justify-content-between mb-2" v-for="(item, index) in openAcquisitions.unpaidLicenses" :key="`item-${index}`">
        <p>{{item.quantity}} x {{item.productName}} <fa-icon
        :icon="['fa-regular', 'fa-square-info']"
        v-b-tooltip.hover="`Reference: ` + item.referenceId"
      /></p> <p>{{ $t("requestedAt")}} <b>{{convertDate(item.creationDate)}}</b> - {{ $t("expiry")}} <b>{{convertDate(item.expiryDate)}}</b></p>
      </div>
    </div>
  </b-alert>
</template>

<script>
import { httpsCallable } from "firebase/functions";
import { functions } from "../plugins/firebase";
import moment from 'moment-timezone';

export default {
  name: "OpenAcquisitionsComponent",
  props: {
    customerId: {
      type: String,
      default: () => 0,
    },
  },
  data: function() {
    return {
      isLoading: true,
      openAcquisitions: [],
    }
  },
  methods: {
    convertDate(date){
      return moment(date).locale(this.$i18n.locale).format("LLL")
    },
    async getOpenAcquisitions() {
      const requestOpenAcquisitions = httpsCallable(functions, "getOpenAcquisitions");

      const openAcquisitions = await requestOpenAcquisitions(this.customerId);

      return openAcquisitions;
    },
    async fetchOpenAcquisitions() {
      const OpenAcquisitions = await this.getOpenAcquisitions()
      this.openAcquisitions = OpenAcquisitions.data;

      this.isLoading = false;
    },
  },
  async mounted() {
    await this.fetchOpenAcquisitions()
  },
};
</script>

<style scoped>
  p { 
    margin:0;
  }
  .hidden {
    display: none !important;; 
  }
</style>
