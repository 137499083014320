<template>
  <b-modal
    hide-header
    :content-class="contentClass"
    :footer-class="footerClass"
    :body-class="bodyClass"
    v-model="alertError.show"
    hide-cancel
    no-close-on-esc
    no-close-on-backdrop
    ok-only
    ok-variant="danger"
    :no-footer="alertError.noFooter"
    v-on:hidden="$emit('hidden')"
  >
    <div
      class="
        icon
        rounded-circle
        d-flex
        justify-content-center
        align-items-center
        alert-danger
        text-danger
        mr-3
        flex-shrink-0
      "
    >
      <fa-icon :icon="['fa-regular', 'fa-triangle-exclamation']"></fa-icon>
    </div>
    <div>
      <h5>{{ $t("error") }}</h5>
      <div v-html="alertError.message"></div>
    </div>
  </b-modal>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ErrorAlertModalComponent",
  data() {
    return {
      contentClass: ["border-0 shadow-lg"],
      footerClass: ["border-0 bg-light p-4"],
      bodyClass: ["p-4 d-flex"],
    };
  },
  computed: mapState(["alertError"]),
};
</script>

<style scoped>
.icon {
  height: 40px;
  width: 40px;
}
</style>
