<template>
  <b-button variant="dark" v-on:click="createOrder()">{{ $t("createOrder") }}</b-button>
</template>

<script>
import moment from "moment-timezone";
import { functions, firestore } from "../plugins/firebase"
import { httpsCallable } from "firebase/functions";
import { collection, addDoc } from "firebase/firestore";

export default {
  name: "CreateNewOrderComponent",
  props: {
    customerId: String,
  },
  methods: {
    async createOrder() {
      const getCustomerAccount = httpsCallable(functions, "customerAccount");
      const customer = await getCustomerAccount({
        customerId: this.customerId,
      })
        .then((result) => {
          result.data.cotermDate = this.updateCotermDate(result.data.cotermDate);
          return result.data
        })
        .catch(() => {
          this.$store.dispatch("alertError", {
            message: "Die Daten konnten nicht abgerufen werden.",
            show: true,
            noFooter: false,
          });
          return;
        });

      if (customer === null) return;

      const docRef = await addDoc(collection(firestore, "orders"), {
        finished: false,
        customer: customer,
        reseller: {
          companyProfile: this.$store.state.resellerData.companyProfile,
          resellerId: this.$store.state.resellerData.resellerId,
          customernumber: this.$store.state.resellerData.customernumber,
        },
        deluxeOrder: null,
        creationDate: moment().tz("Europe/Berlin").format(),
        ttl: moment().tz("Europe/Berlin").add(7, "days").toDate(),
      })

      this.$router.push({
        name: "CreateOrderSelectProductsView",
        params: {
          orderId: docRef.id,
        },
      });
    },
    updateCotermDate(cotermDate) {
      const todayMoment = moment.tz("America/Los_Angeles");
      let cotermMoment = cotermDate ? moment(cotermDate) : todayMoment.clone().add(1, "years");

      if (cotermMoment.isSameOrBefore(todayMoment, "day")) {
        const thisYear = todayMoment.format("YYYY");
        const cotermDateDayAndMonth = cotermMoment.format("MM-DD");
        cotermMoment = moment(`${thisYear}-${cotermDateDayAndMonth}`);

        if (cotermMoment.isSameOrBefore(todayMoment, "day")) {
          cotermMoment.add(1, "years");
        }
      }

      return cotermMoment.format("YYYY-MM-DD");
    },
  }
}
</script>