<template>
  <div>
    <b-navbar toggleable="sm" type="light">
      <!-- <b-navbar-toggle target="nav-collapse" class="m-auto"></b-navbar-toggle> -->

      <!-- <b-collapse id="nav-collapse" is-nav> -->
        <b-navbar-nav class="m-auto">
          <b-nav-item :to="{ name: 'ImprintView' }" target="_blank"
            ><small>{{ $t("imprintLink") }}</small></b-nav-item
          >
          <b-nav-item :to="{ name: 'PrivacyProtectionView' }" target="_blank"
            ><small>{{ $t("privacyLink") }}</small></b-nav-item
          >
          <b-nav-item :to="{ name: 'ComlineTcView' }" target="_blank"
            ><small>{{
              $t("termsAndConditionsComlineLink", {
                companyName: settings.companyName,
              })
            }}</small></b-nav-item
          >
          <b-nav-item :to="{ name: 'AdobeTcView' }" target="_blank"
            ><small>{{ $t("termsAndConditionsAdobeLink") }}</small></b-nav-item
          >
        </b-navbar-nav>
      <!-- </b-collapse> -->
    </b-navbar>
    <small class="text-center d-flex justify-content-center align-items-center mb-3" id="powred-by">          
      <span style="color:#969496!important;">powered by &nbsp;</span>
      <LogoComponent fill="#7f7e7e" :height="'20px'" :width="'20px'" class="mr-3" :lineBreak="false" :showClLogo="true"/>
    </small>
  </div>
</template>

<script>
import { mapState } from "vuex";
import LogoComponent from "../components/LogoComponent.vue";

export default {
  name: "FooterComponent",
  computed: mapState(["settings"]),
  components: {
    LogoComponent,
  }
};
</script>
