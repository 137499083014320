<template>
  <b-row>
    <b-col sm="12" md="6" class="mt-4" v-for="(contact, index) in $t('contactPersons')" :key="`contact-${index}`">
      <b-card body-class="d-lg-flex">
        <div class="mr-3">
          <img :src="require(`@/assets/${contact.image}`)" alt="Mitarbeiter Foto" class="rounded-circle" style="width: 80px" />
        </div>

        <div class="d-flex flex-column">
          <strong>{{ contact.name }}</strong>
          <small>{{ contact.title }}</small>
          <div class="mt-3">{{ contact.phoneNumber }}</div>
          <small>{{ contact.email }}</small>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "ContactsComponent",
};
</script>
