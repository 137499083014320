<template>
  <div>
    <BenefitsComponent :data="customer?.benefits" :customerId="customer.customerId" :companyProfile="customer.companyProfile" :discounts="customer.discounts"/>
  </div>
</template>

<script>
import BenefitsComponent from "../components/BenefitsComponent.vue";

export default {
  name: "Customer3YCView",
  components: {
    BenefitsComponent
  },
  props: {
    customer: Object
  },
  data() {
    return {
      benefits: null,
      isWorking: true,
    };
  },
};
</script>
