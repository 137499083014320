<template>
  <div class="py-4">
    <b-container class="h-100">
      <b-row class="h-100 justify-content-center align-items-center">
        <b-col md="5">
          <b-card
            class="shadow-lg border-top border-bottom-0 border-left-0 border-right-0 border-primary"
            body-class="p-5 d-flex flex-column align-items-center"
          >
            <div class="text-center d-flex align-items-center justify-content-center h2 mb-5">
              <logo-component
                :fill="'#212529'"
                :height="'40px'"
                :width="'40px'"
                class="h2"
                :lineBreak="true"
              >
                Adobe Cloud Store
              </logo-component>
            </div>

            <div class="icon rounded-circle d-flex justify-content-center align-items-center alert-success text-success mb-3 flex-shrink-0">
              <fa-icon :icon="['fa-regular', 'fa-check']" size="2x"></fa-icon>
            </div>
            <h5>{{ $t("success") }}</h5>
            <p class="text-muted text-center">
              {{ $t("youCanLoginWithYourNewPassword") }}
            </p>
            <b-button variant="primary" :to="{ name: 'LogInView' }">{{ $t("toTheLoginForm") }}</b-button>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import LogoComponent from "../components/LogoComponent.vue";

export default {
  name: "ResetPasswordSuccessView",
  components: {
    LogoComponent,
  },
}
</script>

<style scoped>
.border-top {
  border-width: 5px !important;
}
.icon {
  height: 70px;
  width: 70px;
}
</style>