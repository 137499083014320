<template>
  <div :class="`grid-container ${gridView}`">
    <div 
      v-for="(item, index) in products" 
      :key="`item-${index}`" 
      v-show="checkFilter(item.filters) && checkSearch(item.productName) && item.prices.length !== 0 && productIsNotBlocked(item)
      ">
      <b-card 
        :img-src="displayImage(item.imageUrl)" 
        :img-alt="item.productName" 
        :img-left="gridView === 'single-col'"
        :image-top="gridView === ''" body-class="d-flex"
      >
        <div>
          {{ item.productName | productNameWithoutLevel }}
        </div>

        <div class="font-weight-bold">
          <span :id="`price-${index}`">{{ $t("priceInfo") }}</span>

          <b-popover 
            :target="`price-${index}`" 
            triggers="click hover" 
            placement="top" 
            custom-class="price-popover"
            >
            <template #title>{{ $t("priceInfo") }}</template>
            
            <div class="alert alert-primary mb-0">
              {{ $t("timeZoneNoticePrice") }}
            </div>

            <template v-if="!item.productType.match(/^[678]0/)">
              <div
                v-for="(price, priceIndex) in item.prices"
                :key="`price-${index}-${priceIndex}`"
                class="mt-2 d-flex flex-grow-1"
              >
                <div class="d-flex flex-column flex-grow-1">
                  <div class="d-flex mr-1 align-items-center text-nowrap">
                    <fa-icon :icon="['fa-regular', 'fa-layer-group']" class="mr-1"></fa-icon>
                    {{ $t("volumeLevel") }} {{ price.itemNumberObject | volumeLevel }}:
                  </div>
                  <small class="text-muted text-nowrap">
                    {{ price.itemNumber }}
                  </small>
                </div>
                <div>
                  <div class="d-flex justify-content-end align-items-center text-nowrap">
                    {{ isNotAnOrder ? price.pricePerYear: price.price | currency }}
                    <fa-icon
                      :icon="['fa-regular', 'fa-circle-info']"
                      class="ml-1 text-muted"
                      v-b-tooltip.hover="
                        `${formatCurrency(price.pricePerYear)} (${$t('pricePerYear')}) / ${daysInYear} (${$t('daysPerYear')}) * ${daysUntilCotermDate} (${$t('daysUntilAnniversaryDate')}) = ${formatCurrency(isNotAnOrder ? price.pricePerYear : price.price, 4)}`
                      "
                    ></fa-icon>
                  </div>
                  <small class="text-muted text-nowrap">
                    ({{ $t("rrp") }} {{ isNotAnOrder ? price.endcustomerSalesPricePerYear : price.endcustomerSalesPrice | currency }})
                  </small>
                </div>
              </div>
            </template>
            <template v-else>
              <div
                v-for="(price, priceIndex) in item.prices"
                :key="`price-${index}-${priceIndex}`"
                class="mt-2 d-flex flex-grow-1"
              >
                <div class="d-flex flex-column flex-grow-1">
                  <div class="d-flex mr-1 align-items-center text-nowrap">
                    <fa-icon :icon="['fa-regular', 'fa-layer-group']" class="mr-1"></fa-icon>
                    <template v-if="price.itemNumberObject.productType.match(/^6/)"
                      >{{ $t("consumableLevel") }}
                    </template>
                    <template v-else>{{ $t("volumeLevel") }} </template>{{ price.itemNumberObject | volumeLevel }}:
                  </div>
                  <small class="text-muted text-nowrap">
                    {{ price.itemNumber }}
                  </small>
                </div>
                <div>
                  <div class="d-flex justify-content-end align-items-center text-nowrap">
                    {{ price.price | currency }}
                  </div>
                  <small class="text-muted text-nowrap">
                    ({{ $t("rrp") }} {{ price.endcustomerSalesPrice | currency }})
                  </small>
                </div>
              </div>
            </template>
          </b-popover>
        </div>

        <b-button :variant="cartButtonColor(index)" v-on:click="addToCart(item, index)"
          :disabled="cartButtonState(index)">
          <fa-icon :icon="['fa-regular', cartButtonIcon(index)]"></fa-icon>
        </b-button>
      </b-card>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { firestore } from "../plugins/firebase";
import { collection, getDocs, where, updateDoc, doc, query, addDoc } from "firebase/firestore";

export default {
  name: "GridComponent",
  props: {
    products: Array,
    filters: Array,
    subscribedOfferIds: Array,
    cotermDate: String,
    gridView: String,
    search: String,
    blockedProducts: Array,
    minimumQuantities: Object,
    isNotAnOrder: Boolean, // TRUE => add subscription
  },
  data() {
    return {
      activeIndex: null,
    };
  },
  computed: {
    daysUntilCotermDate() {
      if (this.cotermDate === "" || this.isNotAnOrder) {
        return this.daysInYear;
      } else {
        const referenceDate = moment.tz("America/Los_Angeles").startOf("day");
        const cotermDate = moment.tz(this.cotermDate, "America/Los_Angeles").endOf("day");
        return cotermDate.diff(referenceDate, "days");
      }
    },
    daysInYear() {
      const referenceDate = moment.tz("America/Los_Angeles");
      return moment(referenceDate).add(1, "year").diff(referenceDate, "days");
    },
  },
  methods: {
    cartButtonState(index) {
      if (this.activeIndex === index) {
        return true;
      } else {
        return false;
      }
    },
    cartButtonColor(index) {
      if (this.activeIndex === index) {
        return "outline-primary";
      } else {
        return "primary";
      }
    },
    cartButtonIcon(index) {
      if (this.activeIndex === index && this.isNotAnOrder) {
        return "fa-plus";
      } else if(this.isNotAnOrder) {
        return "fa-plus";
      } else if (this.activeIndex === index) {
        return "fa-cart-circle-check";
      } else {
        return "fa-cart-circle-plus";
      }
    },
    displayImage(imageUrl) {
      if (imageUrl === "") {
        return require('@/assets/no-image.jpg');
      } else {
        return imageUrl;
      }
    },
    async addToCart(item, index) {

      if(this.isNotAnOrder) {
        this.$emit("productSelected", item);
        return 
      }
      this.activeIndex = index;

      item["quantity"] = 1;

      const productsRef = collection(firestore, "orders", this.$route.params.orderId, "products");
      const colRef = query(productsRef, where("productName", "==", item.productName));
      const orderProducts = await getDocs(colRef);

      if (!orderProducts.empty) {
        const productDoc = {
          id: "",
          data: {},
        };
        orderProducts.forEach((orderProduct) => {
          productDoc.id = orderProduct.id;
          productDoc.data = orderProduct.data();
        });

        const productRef = doc(firestore, "orders", this.$route.params.orderId, "products", productDoc.id);
        updateDoc(productRef, {
          quantity: productDoc.data.quantity + 1,
        });
      } else {
        const {marketSegment, productType, productCode} = item.prices[0].itemNumberObject;

        if (this.minimumQuantities[`${marketSegment}-${productType}-${productCode}`]) {
          item["quantity"] = this.minimumQuantities[`${marketSegment}-${productType}-${productCode}`];
        } else {
          item["quantity"] = 1;
        }

        const productsRef = collection(firestore, "orders", this.$route.params.orderId, "products");
        addDoc(productsRef, item);
      }

      setTimeout(() => {
        this.activeIndex = null;
      }, 300);
    },
    checkFilter: function (itemFilters) {
      if (this.filters.length > 0) {
        const uebereinstimmendeFilter = itemFilters.every((filter) => this.filters.includes(filter));
        if (uebereinstimmendeFilter && itemFilters.length === this.filters.length) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    checkSearch(productName) {
      if (productName.toLowerCase().includes(this.search.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    },
    removeVolumeLevelFromOfferId(offerId) {
      return offerId.replace(/CA\d\d/, "CA00").replace(/CAT\d/, "CAT0");
    },
    productIsNotBlocked(item) {
      const productIsBlocked = item.prices.some((price) => this.blockedProducts.includes(this.removeVolumeLevelFromOfferId(price.offerId)))
      const customerHasBlockedProduct = this.subscribedOfferIds.some((offerId) => this.blockedProducts.includes(offerId));

      if (customerHasBlockedProduct || !productIsBlocked) {
        return true;
      } else {
        return false;
      }
    },
    formatCurrency(val, maximumFractionDigits) {
      return this.$options.filters.currency(val, maximumFractionDigits);
    },
  },
};
</script>

<style scoped>
.grid-container {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
}
.grid-container > div {
  width: 25%;
  padding: 5px;
  display: flex;
}
.grid-container > div .card {
  flex-grow: 1;
}
.grid-container.single-col > div {
  width: 100%;
}
.grid-container.single-col .card img {
  width: 107px;
}
.grid-container .card .card-body {
  flex-direction: column;
}
.grid-container .card .card-body > div:nth-child(2) {
  margin-bottom: 1rem;
  margin-top: auto;
}
.grid-container .card .card-body > div:first-child {
  margin-bottom: 1rem;
  margin-top: 0;
}
.grid-container .input-group {
  align-self: flex-end;
}
.grid-container.single-col .card .card-body {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.grid-container.single-col .card .card-body > div:nth-child(2) {
  margin-left: auto;
  margin-right: 1rem;
  margin-top: 0;
}
.grid-container.single-col .card .card-body > div:last-child {
  margin-right: 0;
}
.grid-container.single-col .card .card-body > div {
  margin-bottom: 0;
}
.grid-container.single-col .input-group {
  width: 130px;
}
.card img {
  max-height: 100% !important;
}
.price-popover {
  width: 360px;
}

</style>

<style>
.price-popover .popover-body {
  display: flex;
  flex-direction: column;
}
</style>
