import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from "@fortawesome/vue-fontawesome";
import {
  faCartCirclePlus,
  faCartCircleCheck,
  faTrash,
  faBars,
  faBuilding,
  faPaperPlane,
  faUserPlus,
  faUser,
  faUsers,
  faEnvelope,
  faBell,
  faCheck,
  faCircle,
  faCircleCheck,
  faCircleXmark,
  faClock,
  faLayerGroup,
  faIdCard,
  faAngleRight,
  faArrowRightFromBracket,
  faTriangleExclamation,
  faCartShopping,
  faTableCellsLarge,
  faTableList,
  faCircleInfo,
  faCalendarDays,
  faMagnifyingGlass,
  faArrowRightArrowLeft,
  faCirclePlus,
  faCircleQuestion,
  faXmark,
  faUserUnlock,
  faEyeSlash,
  faEye,
  faAngleLeft,
  faBoxArchive,
  faTrianglePersonDigging,
  faLayerPlus,
  faNewspaper,
  faBoxCheck,
  faArrowRight,
  faCalendarCircleExclamation,
  faArrowUpBigSmall,
  faSquareInfo,
  faPlus,
  faHourglass
} from "@fortawesome/pro-regular-svg-icons";
import {
  faArrowsRotate,
  faDownToLine
} from "@fortawesome/pro-solid-svg-icons";
library.add(
  faClock,
  faArrowRightFromBracket,
  faCheck,
  faTriangleExclamation,
  faCartShopping,
  faCartCirclePlus,
  faCartCircleCheck,
  faTableCellsLarge,
  faTableList,
  faCircleInfo,
  faTrash,
  faCalendarDays,
  faMagnifyingGlass,
  faCircle,
  faCircleCheck,
  faCircleXmark,
  faBars,
  faBuilding,
  faPaperPlane,
  faArrowRightArrowLeft,
  faUserPlus,
  faCirclePlus,
  faUser,
  faUsers,
  faCircleQuestion,
  faIdCard,
  faLayerGroup,
  faEnvelope,
  faBell,
  faXmark,
  faAngleRight,
  faUserUnlock,
  faArrowsRotate,
  faDownToLine,
  faEyeSlash,
  faEye,
  faAngleLeft,
  faBoxArchive,
  faTrianglePersonDigging,
  faLayerPlus,
  faNewspaper,
  faBoxCheck,
  faArrowRight,
  faCalendarCircleExclamation,
  faArrowUpBigSmall,
  faSquareInfo,
  faPlus,
  faHourglass
);
Vue.component("fa-icon", FontAwesomeIcon);
Vue.component("fa-layers", FontAwesomeLayers);
Vue.component("fa-layers-text", FontAwesomeLayersText);
