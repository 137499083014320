<template>
  <span v-if="loading"><b-spinner small variant="secondary"></b-spinner>&nbsp;</span>
  <span v-else> {{limit.toLocaleString(currencyLocale, { style: "currency", currency: "EUR" })}}</span>
</template>

<script>
import { functions } from "../plugins/firebase"
import { httpsCallable } from "firebase/functions";
import i18n from "../plugins/i18n";

export default {
  name:"CustomerLimitComponent",
  data() {
    return{
      loading: true,
      limit: null,
      showLimit: false
    }
  },
  computed: {
    currencyLocale() {
      return i18n.locale.toUpperCase()
    }
  },
  mounted() {
    const checkLimitFunction = httpsCallable(functions, "checkCustomerLimit");
    checkLimitFunction()
      .then((res) => {
        const data = res.data
        this.loading = false
        this.limit = Number((data.limit_offenepostenbrutto - data.offene_rechnungen).toFixed(2))
        this.showLimit = !data?.partnerStore
      })
      .catch(err => {
        console.error(err)
      })
  }
}
</script>