import Vue from "vue";

import routes from "./routes.js";
import VueRouter from "vue-router";
Vue.use(VueRouter);

import { auth } from "../firebase"

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const currentUser = await auth.currentUser;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  
  if (to.name === "LogInView" && currentUser) next({ name: "HomepageView" });
  else if (requiresAuth && currentUser) next();
  else if (requiresAuth && !currentUser) next({ name: "LogInView" });
  else next();
});

const defaultTitle = "";
router.afterEach((to) => {  
  document.title += to.meta.title || defaultTitle;
});

export default router;
