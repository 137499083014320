<template>
  <div class="w-100 d-flex justify-content-center" v-if="banners && banners.length > 0">
    <div class="card">
      <div class="card-body p-1">

        <b-carousel id="banner-carousel" v-model="banners.length" :interval="6000" fade indicators
          style="max-width: 846px;">
          <b-carousel-slide v-for="(banner, index) in banners" :key="index">
            <template #img>
              <a :href="banner.link.url || '#'" target="_blank">
                <img class="d-block img-fluid w-100" :src="banner.url" :alt="banner.name"></a>
            </template>
          </b-carousel-slide>
        </b-carousel>

      </div>
    </div>
  </div>
</template>

<script>
import settings from "../settings";
import { onValue, ref } from "firebase/database"
import moment from "moment-timezone"
import i18n from "../plugins/i18n";

export default {
  name: "BannerComponent",
  data() {
    return {
      banners: null,
      settings: {
        "accessibility": false,
        "dots": true,
        "infinite": true,
        "slidesToShow": 1,
        "slidesToScroll": 1,
        "adaptiveHeight": true
      }
    }
  },
  async mounted() {
    if (!settings.partnerStore && location.hostname !== 'localhost' && location.hostname !== '127.0.0.1' && !location.hostname.match(/^192\.168\./)) {
      const { db: dbSA } = await import("../plugins/firebase/shopAdmin");
      this.dbModule = dbSA;
      this.banners = await this.fetchData();
    }
  },
  methods: {
    fetchData() {
      const nowTimestamp = moment(moment().format('YYYY-MM-DD')).unix()

      return new Promise((resolve) => {
        onValue(ref(this.dbModule, "homepageSlides"), (snapshot) => {
          if (snapshot.exists()) {
            const usefulSlides = []
            let snapData = snapshot.val()
            Object.values(snapData).forEach(slide => {
              const fromTimestamp = moment(slide.fromDate).unix()
              const toTimestamp = moment(slide.toDate).unix()
              if (
                fromTimestamp <= nowTimestamp &&
                toTimestamp >= nowTimestamp &&
                slide.language === i18n.locale.toUpperCase() &&
                slide.selectedShops.includes('acs')
              ) {
                usefulSlides.push(slide)
              }
            })
            resolve(usefulSlides)
          } else {
            resolve([])
          }
        }
          , (err) => { console.error(err) })
      })
    }
  },
};
</script>
