<template>
  <b-form v-on:submit.prevent="saveRenewalOrderNumber">
    <div class="mb-4 text-muted" v-html="$t('customerRenewalOrderNumberText')"></div>
    <b-form-group class="w-25">
      <b-form-input
        id="input-orderNumber"
        type="text"
        v-model="orderNumber"
        :state="states.orderNumber"
        aria-describedby="input-orderNumber-feedback"
        trim
        required
      />
      <b-form-invalid-feedback id="input-orderNumber-feedback">
        {{ $t("orderNumberInputErrorText") }}
      </b-form-invalid-feedback>
    </b-form-group>
    <submit-button-component :state="submitButtonState" :label="$t('save')" :block="false" class="my-2"></submit-button-component>
  </b-form>
</template>

<script>
import SubmitButtonComponent from "../components/SubmitButtonComponent.vue";
import { firestore } from "../plugins/firebase";
import { doc, updateDoc, query, collection, getDocs, where, onSnapshot } from "firebase/firestore";

export default {
  name: "CustomerRenewalOrderNumber",
  components: {
    SubmitButtonComponent
  },
  async mounted() {
    const colRef = query(collection(firestore, "customers"), where("customerId", "==", this.$route.params.customerId));
    const querySnapshot = await getDocs(colRef);

    if (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        this.docId = doc.id;
      });

      if (this.docId !== null) {
        const docRef = doc(firestore, "customers", this.docId);
        onSnapshot(docRef, (doc) => {
          this.orderNumber = doc.data().renewalOrderNumber;
        });
      }
    }
  },
  data() {
    return {
      orderNumber: "",
      isWorking: false,
      docId: null,
      states: {
        orderNumber: null
      }
    }
  },
  computed: {
    submitButtonState() {
      if (!this.states.orderNumber) {
        return "missingFields";
      }

      if (this.isWorking === true) {
        return "working";
      }

      return "";
    },
  },
  methods: {
    async saveRenewalOrderNumber() {
      this.orderNumber;

      this.isWorking = true;

      const colRef = query(collection(firestore, "customers"), where("customerId", "==", this.$route.params.customerId));
      const querySnapshot = await getDocs(colRef);

      if (!querySnapshot.empty) {
        let docId = null;

        querySnapshot.forEach((doc) => {
          docId = doc.id;
        });

        if (docId !== null) {
          const docRef = doc(firestore, "customers", docId)
          await updateDoc(docRef, {
            renewalOrderNumber: this. orderNumber,
          });
        }
      }

      this.isWorking = false;
    },
  },
  watch: {
    orderNumber: function(orderNumber) {
      if (orderNumber === "") {
        this.states.orderNumber = null;
      } else {
        this.states.orderNumber = true;
      }
    },
  }
}
</script>