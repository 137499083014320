<template>
  <b-card
    class=""
    header-tag="header"
    footer-tag="footer"
    id="purchase-requests-component"
    header-bg-variant="primary"
    header-text-variant="white"
    no-body
  >
    <template #header>
      <h6 class="mb-0">
        <fa-icon :icon="['fa-regular', 'fa-cart-circle-plus']" class="mr-2 text-white"></fa-icon>{{ $t("purchaseRequests") }}
      </h6>
    </template>

    <b-card-header class="p-3" v-if="!isLoading && purchaseRequests.length > 0">
      <b-input-group >
        <template #prepend>
          <b-input-group-text class="bg-white pr-0"
            ><fa-icon :icon="['fa-regular', 'fa-magnifying-glass']"></fa-icon
          ></b-input-group-text>
        </template>
        <b-input :placeholder="$t('search')" class="border-left-0" v-model="purchaseRequestsFilter"></b-input>
      </b-input-group>
    </b-card-header>

    <b-card-body v-if="!isLoading && purchaseRequests.length > 0">
      <div class="d-flex justify-content-between mb-2" v-for="(item, index) in purchaseRequests" :key="`item-${index}`">
        <div>  
          <div class="font-weight-bold">{{ item.customer }}</div>
        </div>
        <b-button variant="dark" :href="`/-/customers/`+item.customerId+`/subscriptions`">
          <fa-icon :icon="['fa-regular', 'fa-arrow-right']" class="text-white"></fa-icon>
        </b-button>
      </div>
    </b-card-body>
    <b-card-body v-else-if="purchaseRequests.length === 0 && !isLoading" class="d-flex flex-column justify-content-center text-center" v-html="$t('noPurchaseRequests')"></b-card-body>
    <b-card-body class="d-flex flex-column justify-content-center align-items-center" v-else >
      <b-spinner label="Spinning" variant="secondary"></b-spinner>
    </b-card-body>

    <!-- <template #footer>
      <div class="d-flex">
        <b-link class="mx-auto">{{ $t("showAll") }}</b-link>
      </div>
    </template> -->
  </b-card>
</template>

<script>
import { httpsCallable } from "firebase/functions";
import { functions } from "../plugins/firebase";

export default {
  name: "PurchaseRequestsComponent",
  props: {
    customers: {
      type: Array,
      default: () => [],
    },
  },
  data: function() {
    return {
      isLoading: true,
      purchaseRequestsFilter: "",
      purchaseRequests: [],
    }
  },
  methods: {
    async getPendingPartnerOrders() {
      const requestPendingPartnerOrders = httpsCallable(functions, "getPendingPartnerOrders");

      const pendingPartnerOrders = await requestPendingPartnerOrders(this.$store.state.resellerData.resellerId);

      return pendingPartnerOrders;
    },
    async fetchPurchaseRequests() {
      const PendingOrders = await this.getPendingPartnerOrders()

      const purchaseRequests = [];

      PendingOrders.data.items.forEach(notification=>{
        const customerData = this.customers.filter(customer => customer.customerId == notification.customerId)
        purchaseRequests.push({
          customer: customerData[0].companyProfile.companyName,
          customerId: notification.customerId
        });
      })

      this.purchaseRequests = purchaseRequests;

      this.isLoading = false;
    },
  },
  async mounted() {
    await this.fetchPurchaseRequests()
  },
};
</script>

<style>
#purchase-requests-component .card-body {
  height: 240px;
  overflow-y: scroll;
}
.hidden {
    display: none !important;; 
  }
</style>
