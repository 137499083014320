<template>
  <div>
    <Transition mode="out-in" name="fade">
      <img v-if="path" :src="path" class="img-fluid" id="preViewImage">
    </Transition>
  </div>
</template>

<script>
import { storage } from "../plugins/firebase"
import { ref as storageRef, getBlob } from "firebase/storage"

export default {
  name: 'imageBlob',
  data(){
    return{
      path: null,
    }
  },
  watch: {
    data(newData) {
      this.getImage(newData.image)
    }
  },
  props:{
    data: Object
  },
  methods: {
    async getImage(path){
      this.path = null
      await getBlob(storageRef(storage, path))
      .then(blob=>{
        this.blobToBase64(blob)
        .then(res=>{
          this.path = res
        })
      })
      return
    },
    blobToBase64(blob){
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise(resolve => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    }
  },
  mounted(){
    this.getImage(this.data.image)
  }
}
</script>

<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  #preViewImage {
    max-height: 50px; 
    max-width: 50px;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>
